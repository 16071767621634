// ** React Imports
import { useState, useEffect } from 'react'
import axios from 'axios'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import React from 'react'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import Button from '@mui/material/Button'
import PeopleAltIcon from '@mui/icons-material/PeopleAlt'
import { Grid, Typography } from '@mui/material'
import Breadcrumbs from "../../components/Common/Breadcrumb";
import DeleteIcon from '@mui/icons-material/Delete'
import { useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import "../Button.css"
import moment from 'moment';

import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { confirmAlert } from 'react-confirm-alert' // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper'

import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Backdrop } from '@mui/material';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import CloseIcon from '@mui/icons-material/Close';
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import { useAlert } from "react-alert";

import Modal from '@mui/material/Modal';

import { Controller, useForm } from 'react-hook-form';
const Notification = (props) => {
  document.title = "Notification | LuxBilling";

  const getRowSpacing = React.useCallback(params => {
    return {
      top: params.isFirstVisible ? 0 : 0.5,
      bottom: params.isLastVisible ? 0 : 0.8
    }
  }, [])
  const [isLoading, setLoading] = useState(false)
  const token = localStorage.getItem('Token')
  const UserType = localStorage.getItem('User_Type')

  const auth = `Bearer ${token}`
  const headers = {
    Authorization: auth
  }


  const fields = [
    'id',
    'patientId',
    'roomName',
    'duration',
    'limit',
    'inactive',
    'practiceId',
    'createdDate',
    'createdBy',
    'updatedDate',
    'updatedBy',
    'clientCreatedDate',
    'clientUpdatedDate',
  ];

  const defValues = {
    id: 0,
    patientId: 0,
    roomName: '',
    duration: '',
    limit: '',
    practiceId: 0,
    createdDate: '',
    createdBy: '',
    updatedDate: '',
    updatedBy: '',
    clientCreatedDate: '',
    clientUpdatedDate: '',
  };

  const {
    register,
    control,
    handleSubmit,
    setValue,
    reset,
    getValues,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    context: undefined,
    criteriaMode: 'firstError',
    shouldFocusError: true,
    shouldUnregister: false,
    shouldUseNativeValidation: false,
    delayError: undefined,
    // resolver: yupResolver(validationSchema),
    defaultValues: defValues,
  });
  const patientid = props.PatientId
  const gridStyle = {
    color: '#8392ab !important',
    textTransform: 'lowercase',
    fontSize: '13.6px',
    fontWeight: '400',
    '&:hover': {
      color: 'rgba(5, 152, 236, 0.637) !important',
      fontSize: '14.6px'
    }
  }

  const gridRowStyle = {
    boxShadow: 2,
    border: 2,
    borderRadius: 2,
    background: 'drak',
    fontSize: '13.4px !important',
    color: 'drak !important',
    borderColor: '#326C1D',
    '& .MuiDataGrid-cell:hover': {
      color: 'rgba(5, 152, 236, 0.637) !important',
      fontSize: '14.6px !important',
    },
    '& .super-app-theme--header': {
      backgroundColor: '#377562',
      borderRadius: 0.1,
      color: 'white',
      fontSize: '16px !important',

    },

  }
  const gridClasses = {
    columnHeader: 'MuiDataGrid-columnHeaders',
  };
  const [value, setvalue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setvalue(newValue);
  };
  const [refresh, setRefresh] = useState(false)
  const navigate = useNavigate();
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [Delbyid, setDelbyid] = useState(false);
  const [Patientiidresolve, setPatientiidresolve] = useState('');
  const [subject, setsubject] = useState('');
  const [assginedto, setassginedto] = useState('');
  const [date, setdate] = useState('');
  const [comments, setcomments] = useState('');

  const alert = useAlert();

  const handleDelete = (cell) => {
    // Perform your delete logic here
    const postData = {
      id: Delbyid,
      resolved: false,
      patientId: Patientiidresolve,
      subject: subject,
      assignedTo: assginedto,
      createdDate: date,
      comments: comments,

    };
    axios
      .post(`${process.env.REACT_APP_API_URL}/Notifications/AddNotifications`, postData, {
        headers,
      })
      .then((response) => {
        setRefresh(!refresh);
        console.log("aaa", response)
      })
      .catch((error) => {
        console.error('There was an error!', error);
      });
    // Close the dialog
    setDialogOpen(false);
  };
  const [patientName, setPatientName] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [patientId, setPatientId] = useState(null);
  const [ImageData, setImageData] = useState('')

  const handleRowClick = (cellValues) => {
    const id = cellValues.row.id;
    setSelectedRow(cellValues.row);  // Set the selected row
    setPatientId(id);                // Update the patientId state
    setOpenModal(true);  
    axios
      .get(`${process.env.REACT_APP_API_URL}/Notifications/getNotifications?patientId=${patientid}&resolved=${false}&Id=${cellValues.row.id}`, { headers })
      .then((response) => {
        setPatientName(response.data);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  };
  const handleRowClick2 = (cellValues) => {
    setSelectedRow(cellValues.row);
    setOpenModal(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/Notifications/getNotifications?patientId=${patientid}&resolved=${true}&Id=${cellValues.row.id}`, { headers })
      .then((response) => {
        setPatientName(response.data);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  };
  useEffect(() => {
    if (patientId) {
      axios
        .get(`${process.env.REACT_APP_API_URL}/PatientDocument/PatientDocumentList?notificationId=${patientId}`, { headers })
        .then((response) => {
          setImageData(response); 
        })
        .catch((error) => {
          console.error("Error fetching patient documents:", error);
        });
    }
  }, [patientId]);
  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      cellClassName: 'gridCell',
      sortable: true,
      headerClassName: 'super-app-theme--header',
      flex: 0.1
    },

    {
      field: 'patientName',
      headerName: 'Patient Name',
      cellClassName: 'gridCell',
      sortable: true,
      headerClassName: 'super-app-theme--header',
      flex: 0.3,
      renderCell: (cellValues) => (
        <Button
          style={{ color: 'rgba(58, 53, 65, 0.87)', display: 'flex', justifyContent: 'flex-start' }}
          size="small"
          variant="text"
          color="info"
          onClick={() => handleRowClick(cellValues)}
        // onClick={() => {
        //   handleRowClick(cellValues);
        //   setPatientId(cellValues.row.id); 
        // }}
        >
          {cellValues.row.patientName}
        </Button>
      ),
    },
    {
      field: 'createdDate',
      headerName: 'Created Date',
      cellClassName: 'gridCell',
      sortable: true,
      headerClassName: 'super-app-theme--header',
      flex: 0.3,
      valueGetter: (params) => {
        return moment(params.row.createdDate).format('MM-DD-YYYY');
      }
    },
    {
      field: 'subject',
      headerName: 'Subject',
      cellClassName: 'gridCell',
      sortable: true,
      headerClassName: 'super-app-theme--header',
      flex: 0.3
    },
    {
      field: 'comments',
      headerName: 'comments',
      cellClassName: 'gridCell',
      sortable: true,
      headerClassName: 'super-app-theme--header',
      flex: 0.3
    },

    {
      field: 'assignedTo',
      headerName: 'Assigned To',
      cellClassName: 'gridCell',
      sortable: true,
      headerClassName: 'super-app-theme--header',
      flex: 0.3
    },

    // {
    //   field: 'inactive',
    //   headerName: 'Delete',
    //   sortable: true,
    //   headerClassName: 'super-app-theme--header',
    //   minWidth: 50,
    //   renderCell: cell => (
    //     <DeleteIcon
    //       style={{
    //         marginRight: '5px',
    //         float: 'right',
    //         marginTop: '5px',
    //         color: 'red',
    //         cursor: 'pointer'
    //       }}
    //       onClick={cellValues => {
    //         // console.log('Cell: ', cell)
    //         confirmAlert({
    //           message: 'Do you want to delete the record.',
    //           closeOnEscape: true,
    //           closeOnClickOutside: true,
    //           overlayClassName: 'overlay-custom-class-name',
    //           buttons: [
    //             {
    //               label: 'Yes',
    //               onClick: () => {
    //                 const postData = {
    //                   id: cell.row.id,
    //                   inactive: true,
    //                 }

    //                 axios
    //                   .post(`${process.env.REACT_APP_API_URL}/Diagnosis/AddDiagnosis`, postData, {
    //                     headers
    //                   })
    //                   .then(response => {
    //                     setRefresh(!refresh)
    //                     // setOpenNote(false)
    //                     // console.log('response.data: ', response.data)
    //                   })
    //                   .catch(error => {
    //                     console.error('There was an error!', error)
    //                   })
    //               }
    //             },
    //             {
    //               label: 'No'
    //             }
    //           ]
    //         })
    //       }}
    //     ></DeleteIcon>
    //   )
    // }
  ]
  const style = {
    position: 'absolute',
    top: '54%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: '0px 0px 24px rgba(0, 0, 0, 0.5)',
    padding: '15px',
    borderRadius: '8px',
    maxHeight: '89vh',
    // overflow: 'auto',

  };
  const columnsresolve = [
    {
      field: 'id',
      headerName: 'ID',
      cellClassName: 'gridCell',
      sortable: true,
      headerClassName: 'super-app-theme--header',
      flex: 0.1
    },

    {
      field: 'patientName',
      headerName: 'Patient Name',
      cellClassName: 'gridCell',
      sortable: true,
      headerClassName: 'super-app-theme--header',
      flex: 0.3,
      renderCell: (cellValues) => (
        <Button
          style={{ color: 'rgba(58, 53, 65, 0.87)', display: 'flex', justifyContent: 'flex-start' }}
          size="small"
          variant="text"
          color="info"
          onClick={() => handleRowClick2(cellValues)}
        >
          {cellValues.row.patientName}
        </Button>
      ),

    },
    {
      field: 'createdDate',
      headerName: 'Date',
      cellClassName: 'gridCell',
      sortable: true,
      headerClassName: 'super-app-theme--header',
      flex: 0.3,
      valueGetter: (params) => {
        return moment(params.row.createdDate).format('MM-DD-YYYY');
      }
    },
    {
      field: 'subject',
      headerName: 'Subject',
      cellClassName: 'gridCell',
      sortable: true,
      headerClassName: 'super-app-theme--header',
      flex: 0.2
    },
    {
      field: 'comments',
      headerName: 'Comments',
      cellClassName: 'gridCell',
      sortable: true,
      headerClassName: 'super-app-theme--header',
      flex: 0.4
    },
    {
      field: 'assignedTo',
      headerName: 'Assigned To',
      cellClassName: 'gridCell',
      sortable: true,
      headerClassName: 'super-app-theme--header',
      flex: 0.3
    },
    // {
    //   field: 'inactive',
    //   headerClassName: 'super-app-theme--header',
    //   headerName: '',
    //   sortable: true,
    //   flex: 0.2,
    //   // hide: true,
    //   // hide: Usertype == 'Admin'?false:true,

    //   renderCell: cell => (

    //     <>
    //       <RestartAltIcon
    //         style={{
    //           marginRight: '5px',
    //           float: 'right',
    //           // marginTop: '5px',
    //           color: 'red',
    //           cursor: 'pointer',
    //         }}
    //         color="black"
    //         onClick={() => {
    //           setDialogOpen(true)
    //           setDelbyid(cell.row.id)
    //           setPatientiidresolve(cell.row.patientId)
    //           setsubject(cell.row.subject)
    //           setassginedto(cell.row.assignedTo)
    //           setdate(cell.row.createdDate)
    //           setcomments(cell.row.comments)

    //         }}
    //       ></RestartAltIcon>

    //       <Dialog
    //         open={isDialogOpen}
    //         onClose={() => setDialogOpen(false)}
    //         aria-labelledby="delete-dialog-title"
    //         BackdropComponent={Backdrop}
    //         BackdropProps={{
    //           invisible: true,
    //         }}
    //         PaperProps={{
    //           style: {
    //             marginRight: '5px',
    //             float: 'right',
    //             marginTop: '5px',
    //             color: 'red',
    //             cursor: 'pointer',
    //           },
    //         }}
    //       >
    //         <div style={{
    //           display: 'flex',
    //           flexDirection: 'column',
    //           alignItems: 'center', // Center horizontally
    //           justifyContent: 'center', // Center vertically
    //           borderRadius: '8px',
    //           width: '500px'
    //         }}>

    //           <ErrorOutlineIcon style={{ fontSize: '100px', color: 'red' }} />
    //           <DialogTitle style={{ fontWeight: 'bold', color: 'black', fontSize: ' 1.25rem' }}>Are you sure?</DialogTitle>
    //           <h5 style={{ textAlign: 'center', fontSize: '16px', fontWeight: 'normal', color: '#444444' }}>
    //             Reopen this Patient!
    //           </h5>
    //           <DialogContent>
    //             <DialogContentText>

    //               <Grid style={{ marginTop: '1rem' }}>
    //                 <Button onClick={() => setDialogOpen(false)} style={{ backgroundColor: '#6c7c84', color: 'white', marginBottom: '1rem' }}>No,cancel!</Button>
    //                 <Button onClick={() => handleDelete(cell)} style={{ backgroundColor: 'red', color: 'white', marginLeft: '1rem', marginBottom: '1rem', border: '1px solid #b4acec' }}>Yes,Reopen it!</Button>
    //               </Grid>
    //             </DialogContentText>
    //           </DialogContent>
    //         </div>

    //       </Dialog>
    //     </>
    //   )
    // }


  ]
  const ArchivedCol = [
    {
      field: 'id',
      headerName: 'ID',
      cellClassName: 'gridCell',
      sortable: true,
      headerClassName: 'super-app-theme--header',
      flex: 0.1
    },

    {
      field: 'patientName',
      headerName: 'Patient Name',
      cellClassName: 'gridCell',
      sortable: true,
      headerClassName: 'super-app-theme--header',
      flex: 0.3,
      renderCell: (cellValues) => (
        <Button
          style={{ color: 'rgba(58, 53, 65, 0.87)', display: 'flex', justifyContent: 'flex-start' }}
          size="small"
          variant="text"
          color="info"
          onClick={() => handleRowClick2(cellValues)}
        >
          {cellValues.row.patientName}
        </Button>
      ),

    },
    {
      field: 'createdDate',
      headerName: 'Date',
      cellClassName: 'gridCell',
      sortable: true,
      headerClassName: 'super-app-theme--header',
      flex: 0.3,
      valueGetter: (params) => {
        return moment(params.row.createdDate).format('MM-DD-YYYY');
      }
    },
    {
      field: 'subject',
      headerName: 'Subject',
      cellClassName: 'gridCell',
      sortable: true,
      headerClassName: 'super-app-theme--header',
      flex: 0.2
    },
    {
      field: 'comments',
      headerName: 'Comments',
      cellClassName: 'gridCell',
      sortable: true,
      headerClassName: 'super-app-theme--header',
      flex: 0.4
    },
    {
      field: 'assignedTo',
      headerName: 'Assigned To',
      cellClassName: 'gridCell',
      sortable: true,
      headerClassName: 'super-app-theme--header',
      flex: 0.3
    },
    

  ]

  const [rows, setRows] = useState(null)
  const [rowsresolve, setRowsresolve] = useState(null)
  const [rowId, setRowID] = React.useState(0);

  useEffect(() => {
    setLoading(true)
    // POST request using axios inside useEffect React hook
    axios
      .get(`${process.env.REACT_APP_API_URL}/Notifications/getNotifications?messageType=${'inbox'}&patientId=${patientid}`, { headers })
      .then(response => {
        setLoading(false)
        setRows(response)
      })

      .catch(error => { })

    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, [refresh])

  useEffect(() => {
    setLoading(true)
    // POST request using axios inside useEffect React hook
    axios
      .get(`${process.env.REACT_APP_API_URL}/Notifications/getNotifications?messageType=${'sent'}&patientId=${patientid}`, { headers })
      .then(response => {
        setLoading(false)
        setRowsresolve(response)
      })
      .catch(error => { })

    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, [refresh])
  const [Archieved, setArchieved] = useState(null)

  useEffect(() => {
    setLoading(true)
    axios
      .get(`${process.env.REACT_APP_API_URL}/Notifications/getNotifications?messageType=${'archive'}&resolved=${true}&patientId=${patientid}`, { headers })
      .then(response => {
        setLoading(false)
        setArchieved(response)
      })
      .catch(error => { })

  }, [refresh])

  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => {
    setOpenModal(false);
    fields.forEach((field) => setValue(field, ''));
  };

  const onSubmit = data => {

    const postData = { ...data };
    postData.id = rowId;
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/Notifications/addNotifications`,
        postData,
        { headers }
      )
      .then(response => {
        if (response.id > 0) {
          // setSubmitting(false);

          alert.success("Record saved successfully.", {
            type: "success",
            onClose: () => {
              setOpenModal(false)
            }
          });
        }
      })
      .catch(error => {
        // setSubmitting(false);
        alert.error(`Error ${error.message}`, {
          type: "error"
        });
      });
  };
  const [modalOpen, setModalOpen] = useState(false);
  const handleCloseImage = () => {
    setModalOpen(false);
  };
  const [DocNameee, setDocNameee] = useState('')
  const [fileUrl, setFileUrl] = useState('');

  const handleViewClick = (fileUrl, documentName) => {
    var arr = documentName.split('.');
    setDocNameee(arr.length > 1 ? arr[1].toLowerCase() : '');

    axios
      .get(`${process.env.REACT_APP_API_URL}/PatientDocument/ViewPatientDocument?path=${fileUrl}`, { headers })
      .then((response) => {
        setFileUrl(response);
        setModalOpen(true);
        var arr = documentName.split('.');
        setDocNameee(arr.length > 1 ? arr[1].toLowerCase() : '');
      })
      .catch(error => {
        console.error('There was an error!', error);
      });
  };
  return (
    <div >
      <Container >
        <Modal
          open={openModal}
          onClose={handleCloseModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
              <Grid
                container xs={12}
                style={{
                  background: '#377562',
                  position: 'sticky',
                  marginBottom: '14px',
                  padding: '8px',
                  borderRadius: '8px',
                  boxShadow: '0 4px 8px rgba(54, 118, 196, 0.5)',
                  display: 'flex'
                }}
              >
                <Grid item xs={11}>
                  <Typography style={{
                    fontSize: '17px',
                    fontWeight: 'bold',
                    marginLeft: '5px',
                    color: '#fff'
                  }}>Notification </Typography>
                </Grid>
                <Grid item xs={1}>
                  <CancelOutlinedIcon
                    onClick={handleCloseModal}
                    fontSize="medium"
                    color="red"
                    style={{
                      color: 'red',
                      cursor: 'pointer',
                      float: 'right',
                    }}
                  ></CancelOutlinedIcon>
                </Grid>
              </Grid>
              <Grid container spacing={2} style={{
                padding: '5px',
                maxHeight: '57vh',
                overflowY: 'auto',
                marginTop: '4px',
                scrollbarWidth: 'thin', // For Firefox
                scrollbarColor: '#3676c4 transparent', // thumb color and track color
                '&::-webkit-scrollbar': {
                  width: 8, /* Width of vertical scrollbar */
                  height: 8, /* Height of horizontal scrollbar */
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: '#3676c4', /* Color of the thumb */
                  borderRadius: 10,
                },
                '&::-webkit-scrollbar-track': {
                  backgroundColor: 'transparent', /* Color of the track */
                },
                /* Adjust the width for the horizontal scrollbar */
                '&::-webkit-scrollbar-thumb:horizontal': {
                  width: 4, /* Width of horizontal scrollbar */
                },
              }}>


                <Grid container spacing={2} style={{ paddingLeft: '40px' }}>
                  {selectedRow && (
                    <Paper
                      variant="outlined"
                      square
                      style={{
                        backgroundColor: '#F0F6F8',
                        borderRadius: '15px',
                        marginTop: '14px',
                        borderColor: '#377562',
                        maxWidth: '100%',
                        padding: '.9rem',
                        marginBottom: '1rem',
                        marginLeft: '0 .2rem',
                      }}
                    >
                      <Grid container spacing={2} item xs={12} sm={12} xl={12}>
                        <Grid container>

                          <Grid item xs={3} style={{ fontWeight: 500 }}>
                            <Typography style={{ fontSize: '14px', fontWeight: 'bold', marginLeft: '20px' }}>
                              Patient Name:
                            </Typography>
                          </Grid>
                          <Grid item xs={9} style={{ fontWeight: 500 }}>
                            <Typography style={{ fontSize: '14px' }}>{selectedRow.patientName}</Typography>
                          </Grid>
                          <Grid item xs={3} style={{ fontWeight: 500 }}>
                            <Typography style={{ fontSize: '14px', fontWeight: 'bold', marginLeft: '20px' }}>
                              Subject:
                            </Typography>
                          </Grid>
                          <Grid item xs={9} style={{ fontWeight: 500 }}>
                            <Typography style={{ fontSize: '14px' }}>{selectedRow.subject}</Typography>
                          </Grid>
                          <Grid item xs={3} style={{ fontWeight: 500 }}>
                            <Typography style={{ fontSize: '14px', fontWeight: 'bold', marginLeft: '20px' }}>
                              Comments:
                            </Typography>
                          </Grid>
                          <Grid item xs={9} style={{ fontWeight: 500 }}>
                            <Typography style={{ fontSize: '14px' }}>{selectedRow.comments}</Typography>
                          </Grid>
                          <Grid item xs={3} style={{ fontWeight: 500 }}>
                            <Typography style={{ fontSize: '14px', fontWeight: 'bold', marginLeft: '20px' }}>
                              Assigned To:
                            </Typography>
                          </Grid>
                          <Grid item xs={9} style={{ fontWeight: 500 }}>
                            <Typography style={{ fontSize: '14px' }}>{selectedRow.assignedTo}</Typography>
                          </Grid>
                          <Grid item xs={3} style={{ fontWeight: 500 }}>
                            <Typography style={{ fontSize: '14px', fontWeight: 'bold', marginLeft: '20px' }}>
                              Date:
                            </Typography>
                          </Grid>
                          <Grid item xs={5} style={{ fontWeight: 500 }}>
                            <Typography style={{ fontSize: '14px' }}>{selectedRow.createdDate}</Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Paper>
                  )}
             
                  </Grid>
                  <Grid container spacing={2} style={{ paddingLeft: '40px' }}>
                  {ImageData != null && ImageData.length > 0 ? (
                ImageData.map((row, index) => (
                  <Paper
                  variant="outlined"
                  square
                  style={{
                    backgroundColor: '#F0F6F8',
                    borderRadius: '15px',
                    marginTop: '14px',
                    borderColor: '#377562',
                    maxWidth: '100%',
                    padding: '.9rem',
                    width:'590px',
                    marginBottom: '1rem',
                    marginLeft: '0 .2rem',
                  }}
                >
                              <Grid container spacing={2}>
                      <Grid item xs={12} style={{ marginTop: '20px', borderRadius: '8px' }}>
                        <Typography
                          variant='h6'
                          component='h2'
                          style={{ fontWeight: 400, backgroundColor: '#377562', marginBottom: 5, color: '#fff', borderRadius: '8px' }}
                        >
                          {row.documentName}
                        </Typography>
                      </Grid>
                      <Grid item xs={2} style={{ fontWeight: 500, marginBottom: '25px' }}>

                        <Button
                          className='Button'
                          variant='gradient'
                          onClick={() => handleViewClick(row.fileUrl, row.documentName)}
                          style={{
                            color: 'white',
                            fontSize: '13px',
                            height: '27px',
                            marginBottom: '3px',
                            float: 'right'
                          }}
                        >

                          View
                        </Button>
                      </Grid>
                 
                      
                      </Grid>

                  </Paper>
                ))
              ) : (
                'No Images Found'
              )}
             
                  </Grid>
                  

              </Grid>
          </Box>
        </Modal>

        <Modal
        open={modalOpen}
        onClose={handleCloseImage}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '52%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 912,
            bgcolor: 'background.paper',
            // border: '2px solid #000',
            borderColor: '#326C1D',
            border: 5,
            padding: '5px',
            borderRadius: '8px',
            boxShadow: 24,
            p: 4,
          }}
        >
          {/* <div>

            <iframe
              // height='500'
              // width='530px'
              border='4px solid #000'
              height='500px'
              width='875px'
              src={`${imageee}`}
            ></iframe>

          </div> */}
          <div>
            {(DocNameee === 'jpg' || DocNameee === 'jpeg' || DocNameee === 'png') ? (
              <>

                <Grid item xs={8}>
                  <img
                    height='400px'
                    width='850px'
                    style={{ border: '4px solid #000' }}
                    src={`${fileUrl}`}
                    alt='Document'
                  />
                </Grid>
                <Grid item xs={1}></Grid>
              </>
            ) : (
              <>
                <Grid item xs={12}>
                  <iframe
                    height='400px'
                    width='850px'
                    style={{ border: '4px solid #000' }}
                    src={`${fileUrl}`}
                  ></iframe>
                </Grid>
              </>
            )}
          </div>
          <Grid item xs={2} style={{ fontWeight: 500, marginLeft: '30px', marginBottom: '25px', marginTop: '10px' }}>

            <Button
              className='Button'
              variant='gradient'
              onClick={handleCloseImage}
              style={{
                color: 'white',
                fontSize: '13px',
                height: '27px',
                marginBottom: '3px',
                float: 'right'
              }}
            >

              Close
            </Button>
          </Grid>

        </Box>
      </Modal>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="lab API tabs example"
            >
              <Tab value={0} label="Inbox" />
              <Tab value={1} label="Sent" />
              {/* {UserType != 'Front Office' ? ( */}

              <Tab value={2} label="Archieved" />
            {/* )  : null} */}




            </TabList>
          </Box>
          <TabPanel value={0}>
            <Box mb={3} marginTop={2}>
              {isLoading ? (
                <div style={{ margin: '5rem', textAlign: 'center' }}>
                  <CircularProgress />
                </div>
              ) : (
                <div style={{ height: 400, width: "100%" }}>
                  {rows === null || rows.length === 0 ? (
                    <Typography>No Record found</Typography>
                  ) : (
                    <DataGrid
                      rows={rows}
                      classes={gridClasses}
                      rowHeight={25}
                      columns={columns}
                      getRowSpacing={getRowSpacing}
                      //checkboxSelection
                      fullWidth
                      pagination
                      autoPageSize
                      sx={gridRowStyle}
                    />
                  )}
                </div>
              )}
            </Box>
          </TabPanel>
          <TabPanel value={1}>
            <Box mb={3} marginTop={2}>
              {isLoading ? (
                <div style={{ margin: '5rem', textAlign: 'center' }}>
                  <CircularProgress />
                </div>
              ) : (
                <div style={{ height: 400, width: "100%" }}>
                  {rowsresolve === null || rowsresolve.length === 0 ? (
                    <Typography>No Record found</Typography>
                  ) : (
                    <DataGrid
                      rows={rowsresolve}
                      classes={gridClasses}
                      rowHeight={20}
                      columns={columnsresolve}
                      getRowSpacing={getRowSpacing}
                      //checkboxSelection
                      fullWidth
                      pagination
                      autoPageSize
                      sx={gridRowStyle}
                    />
                  )}
                </div>
              )}
            </Box>

          </TabPanel>
          <TabPanel value={2}>
            <Box mb={3} marginTop={2}>
              {isLoading ? (
                <div style={{ margin: '5rem', textAlign: 'center' }}>
                  <CircularProgress />
                </div>
              ) : (
                <div style={{ height: 400, width: "100%" }}>
                  {Archieved === null || Archieved.length === 0 ? (
                    <Typography>No Record found</Typography>
                  ) : (
                    <DataGrid
                      rows={Archieved}
                      classes={gridClasses}
                      rowHeight={20}
                      columns={ArchivedCol}
                      getRowSpacing={getRowSpacing}
                      //checkboxSelection
                      fullWidth
                      pagination
                      autoPageSize
                      sx={gridRowStyle}
                    />
                  )}
                </div>
              )}
            </Box>

          </TabPanel>
        </TabContext>



      </Container>
    </div>


  )
}

export default Notification
