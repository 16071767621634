import React, { useState, useEffect, useRef } from "react";
import { Button, Grid, TextField, Typography } from "@mui/material";
import axios from "axios";
import { Container } from "reactstrap";
import { Controller, useForm } from "react-hook-form";
import { useAlert } from "react-alert";
import { useNavigate } from "react-router-dom";
import ClickAwayListener from '@mui/material/ClickAwayListener';
import PrintIcon from '@mui/icons-material/Print';
import { useReactToPrint } from 'react-to-print'
import Headroom from "react-headroom";
import DialogTitle from '@mui/material/DialogTitle';
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import Moment from 'moment'
import Select from "react-select";
import SearchIcon from '@mui/icons-material/Search'
import { ToastContainer, toast } from 'react-toastify';
import ReactSelect from "react-select";
import 'react-toastify/dist/ReactToastify.css';
//Import Breadcrumb
import "./Button.css"
import TextareaAutosize from '@mui/material/TextareaAutosize';
import InputMask from 'react-input-mask';
import CircularProgress from "@mui/material/CircularProgress";

// const history = useHistory(); 
const Prior_auth = (props) => {
  console.log(props.patientName,'props main kiua h')
  const [Formdid, setFormdid] = useState(props.formDid !== undefined ? props.formDid : 0)
  const [columnID, setcolumnID] = useState(props.columnID> 0 ?props.columnID: props.columnIDcpt)
 
  const [Formdid1, setFormdid1] = useState(props.formDid !== undefined ? props.formDid : 0)
  const [columnID1, setcolumnID1] = useState(props.columnID !== undefined ? props.columnID : 0)
  const [columnIDcpt, setcolumnIDcpt] = useState(props.columnID>0?props.columnID:props.columnIDcpt )
  console.log('columnIDcpt',columnIDcpt)
  const [Formdidcpt, setFormdidcpt] = useState(props.columnIDcpt !== undefined ? props.columnIDcpt : 0)

  // const patientId = props.patientid;
  const patientId = (props.callingFrom =="update"?props.patientid:props.patientid )
  const patientNameee = props.patientNameee;
  const PpatientName = props.patientName;

  const [patientName, setpatientName] = useState(props.patientNameee);

  const [patName, setPatName] = useState(
    patientName || props.patientName || props.patientNameee || ""
  );
  console.log('patNameID,,', patName);
  

  const [Markaporoved, setMarkaporoved] = useState(props.Markaporoved);
   const [AutpattientID, setAutpattientID] = useState(props.AutpattientID1 || props.patientID)
  const UserType = localStorage.getItem('User_Type')
  console.log('UserType',UserType)
  console.log('Markaporoved',Markaporoved)
  console.log('checkid', props.patName)
  console.log('patieent id', AutpattientID)
  const [authid, setAuthId] = useState()
  const [patId, setPatId] = useState();
  const [cellPhone, setcellPhone] = React.useState()
 
  const [dob, setDOB] = React.useState(Moment().format('YYYY-MM-DD'),)
  const DOB = props.callingFrom=="Pateint"? props.DOB: dob; 
  const [patQuestion, setPatQuestion] = useState();
  const [insuDate, setInsuDate] = React.useState(Moment().format('YYYY-MM-DD'))
  const [rangeDate, setRangeDate] = React.useState(Moment().format('YYYY-MM-DD'))
  const [rangeDateform, setRangeDateform] = React.useState(Moment().format('YYYY-MM-DD'))
  
  const [induDeduct, setInduDeduct] = useState();
  const [rangecomments, setrangecomments] = useState('');

  const [backofficecomment, setbackofficecomment] = useState('');


  
  const [induAmou, setInduAmou] = useState();
  const [remaDeduct, setRemaDeduct] = useState();
  const [famDeduct, setFamDeduct] = useState();
  const [famAmount, setFamAmount] = useState();
  const [primaryInsurance, setprimaryInsurance] = useState();

  
  const [insuName, setInsuName] = useState();
  const [polName, setPolName] = useState();
  const [phoneNumber, setPhoneNumber] = useState();
  const [repName, setRepName] = useState();
  const [contract, setContract] = useState();
  const [addNotes, setAddNotes] = useState();
  const [inNetwork, setInNetwork] = useState();
  const [outNetwork, setOutNetwork] = useState();
  const [contractYear, setContractYear] = useState();
  const [cellReference, setCellReference] = useState();
  const [approval, setApproval] = useState();
  const [cpap, setcpap] = useState();
  const [appliance, setappliance] = useState();

  const [GAP, setGAP] = useState();

  
  const [pAuthNmbr, setPAuthNmbr] = useState();
  const [pInsurance, setPInsurance] = useState();
  const [estimatedAllowable, setestimatedAllowable] = useState();
  const [totalEstimated, settotalEstimated] = useState();

  
  const [apDentist, setApDentist] = useState();
  const [pauthTreatment, setPauthTreatment] = useState();
  const [coInsu, setCoInsu] = useState();
  const [coInsuRespon, setCoInsuRespon] = useState();
  const [inactive, setInactive] = useState();
  const [ccyear, setCalendarYear] = useState();
  const [practiceId, setPracticeId] = useState();
  const [createdBy, setCreatedBy] = useState();
  const [createdDate, setCreatedDate] = React.useState(Moment().format('YYYY-MM-DD'))
  const [cellDate, setCellDate] = React.useState(Moment().format('YYYY-MM-DD'))
  const [updatedBy, setUpdatedBy] = useState();
  const [updatedDate, setUpdatedDate] = React.useState(Moment().format('YYYY-MM-DD'))
  const [ccDate, setCcDate] = React.useState(Moment().format('YYYY-MM-DD'))
  const [cuDate, setCUDate] = React.useState(Moment().format('YYYY-MM-DD'))
  const [provname, setProvName] = useState("")
  // const [suggestions, setSuggestions] = useState([]);
  // const [selectedProvider, setSelectedProvider] = useState(null);
  const [openInsurances, setOpenInsurances] = useState(false)
  const [insuranceNames, setInsuranceNames] = React.useState([])

  const [fetchedData, setfetchedData] = useState(false)
  const [loadingTime, setloadingTime] = useState(0)
  const [disable, setdisable] = React.useState(false)
  const [patientdob, setpatientdob] = React.useState(Moment().format('YYYY-MM-DD'),)

  console.log(patientdob,'patientdob')
  const [patientinsurancename, setpatientinsurancename] = useState("");
  const [patientdiercttname, setpatientdiercttname] = useState("");

  console.log('patientdiercttname',patientdiercttname)
  const [patientphone, setpatientphone] = useState("");
  const [memberid, setmemeberid] = useState('');
  const [codevob, setCodevob] = useState('')
  const [prodiceridvob, setProdiceridvob] = useState('')
  const [priorAuthRequestFormId, setPriorAuthRequestFormId] = useState(0)
  
  const [degvob, setdegdvob] = useState(null)
  const [submitting, setSubmitting] = useState(false);
  const [isLoading, setLoading] = useState(false)
  const alert = useAlert();
  const practiceID=localStorage.getItem('default_paractice')
  const [providers, setProviders] = useState([]);
  const [providersid, setProvidersid] = useState([]);
 
  const [printpage, setPrintPage] = useState(false);
  const printRef1 = useRef();
  const [procedureCode1ID, setProcedureCode1ID] = useState("");
  const [procedureCode1List, setProcedureCode1List] = useState([]);
  console.log('value',procedureCode1List)
  const [diagnosisID, setDiagnosisID] = useState("");
  const [diagnosisList, setDiagnosisList] = useState([]);

  const fields = [
    "id",
    "patientId",
    "providerId",
    "patientName",
    "dob",
    "providerName",
    "insuranceEffectiveDate",
    "rangeDate",
    "individualDeductible",
    "individualAmountMet",
    "remainingDeductible",
    "familyDeductible",
    "familyAmountMet",
    "insuranceName",
    "policyNumber",
    "phoneNumber",
    "representativeName",
    "contract",
    "additionalNotes",
    "inNetwork",
    "outNetwork",
    "contractYear",
    "calenderYear",
    "cellReference",
    "approval",
    "preAuthorizationNumber",
    "primaryInsurance",
    "approvedDentist",
    "preAuthorizationTreatment",
    "coInsurancePercAllowable",
    "coInsurancePercPatRespon",
    "inactive",
    "practiceId",
    "createdBy",
    "createdDate",
    "cellDate",
    "updatedBy",
    "updatedDate",
    "clientCreatedDate",
    "clientUpdatedDate",
    "rangeDateform",
    "rangecomments",
    "procedureCode",
    "diagnosisCode",
    "estimatedAllowable",
    "totalEstimated",
    "memberId"
  ];
  const defValues = {
    id: 0,
    patientName: "",
    patientId:"" ,
    providerId:"",
    memberId:0,
    dob: "",
    providerName: "",
    insuranceEffectiveDate: "",
    rangeDate: "",
    individualDeductible: "",
    individualAmountMet: "",
    remainingDeductible: "",
    familyDeductible: "",
    familyAmountMet: "",
    insuranceName: "",
    policyNumber: "",
    phoneNumber: "",
    representativeName: "",
    contract: "",
    additionalNotes: "",
    inNetwork: "",
    outNetwork: "",
    contractYear: "",
    calenderYear: "",
    cellReference: "",
    approval: null,
    preAuthorizationNumber: "",
    primaryInsurance: "",
    approvedDentist: "",
    preAuthorizationTreatment: "",
    coInsurancePercAllowable: null,
    coInsurancePercPatRespon: null,
    inactive: "",
    practiceId: 0,
    createdBy: "",
    createdDate: "",
    cellDate: "",
    updatedBy: "",
    updatedDate: "",
    clientCreatedDate: "",
    clientUpdatedDate: "",
    rangeDateform:"",
    rangecomments:"",
    procedureCode:"",
    diagnosisCode:"",
    estimatedAllowable:"",
    totalEstimated:"",
  };

  document.title = "Prior Auth & VOB | LuxBillingEHR";
   // API Authanticatiion
   const token = localStorage.getItem('Token')
   const auth = `Bearer ${token}`
   const headers = {
     Authorization: auth
   }
  const navigate = useNavigate();
  const [refresh, setRefresh] = useState(false);
 

  const [zipCodeList, setZipCodeList] = React.useState([])
//   const validationSchema = Yup.object({
//     // individualDeductible: Yup.string()
//     // .matches(/^\d*\.?\d*$/, 'Only digits are allowed')
//     // .required('Deductible is required'),
//     // individualAmountMet: Yup.string()
//     // .matches(/^\d*\.?\d*$/, 'Only digits are allowed')
//     // .required('Field is required'),
//     // familyDeductible: Yup.string()
//     // .required('Field is required'),
//     // familyAmountMet: Yup.string()
//     // .required('Field is required'),
//     // remainingDeductible: Yup.string()
//     // .matches(/^\d*\.?\d*$/, 'Only digits are allowed')
//     // .required('Field is required'),
//     // approval: Yup.string()
//     // .matches(/^\d*\.?\d*$/, 'Only digits are allowed')
//     // .required('Field is required'),
//     // estimatedAllowable: Yup.string()
//     // .matches(/^\d*\.?\d*$/, 'Only digits are allowed')
//     // .required('Field is required'),
//     // totalEstimated: Yup.string()
//     // .matches(/^\d*\.?\d*$/, 'Only digits are allowed')
//     // .required('Field is required'),

// })
  const {
    register,
    control,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: false,
    shouldUseNativeValidation: false,
    delayError: undefined,
    defaultValues: defValues,
    // resolver: yupResolver(validationSchema),

  });

 



  
 
  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/Patients/SearchInsuranceName?patientId=${AutpattientID}`,
        {
          headers,
        }
      )
      .then((response) => {
        // console.log('testname',response[0].dob)
        var dateYMD = Moment(response.dob).format('YYYY-MM-DD')
        setDOB(dateYMD)
        // setpatientdob(response.dob)
        // setpatientdob(Moment(response.dob).format('YYYY-MM-DD'));
        setpatientdiercttname(response.patientName)
        setpatientinsurancename(response.planName)
        setpatientphone(response.phoneNumber)
        setmemeberid(response.memberId)

       
        // setRefresh(!refresh)
      })

      .catch((error) => {
        console.error("There was an error!", error);
      });
  }, [AutpattientID]);
  useEffect(() => {
// if(columnIDcpt>0)
// {
//   axios
//   .get(
//     `${process.env.REACT_APP_API_URL}/PatientNote/AuthorizationFormByCPTById?Id=${columnIDcpt}&authId=${columnIDcpt}`,
//     { headers }
//   )
//   .then(response => {
//     console.log('response.dataaaaa: ', response.address
//     )
//     // fields.forEach(field => {
//     //   setValue(field, response[field])
//     // })
//     setCodevob(response.cptcode)
//     setdegdvob(response.iCD)
//     setProdiceridvob(response.providerId)
//     // setFormDataId(response.formId)
   
//   })

//   .catch(error => {
//     console.error('There was an error!', error)
//   })
// }
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/AutherizationRequest/PriorAuthRequestByFormId?formId=${Formdid1}&authId=${columnID1}`,
        { headers }
      )
      .then(response => {
        console.log('response.dataaaaa: ', response.address
        )
        if(response && response.id>0)
          {
            setPriorAuthRequestFormId(response.id)
            setCodevob(response.procedureCode1)
        setdegdvob(response.diagnosisCode1)
        setcpap(response.cpap)
        setappliance(response.appliance)
        setGAP(response.gap)
        setProdiceridvob(response.provider_id)
          }
          else 
          setPriorAuthRequestFormId(-1)
        // fields.forEach(field => {
        //   setValue(field, response[field])
        // })
        
        // setFormDataId(response.formId)
       
      })

      .catch(error => {
        console.error('There was an error!', error)
      })

    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, [Formdid1, columnID])

  useEffect(() => {
    if(
      priorAuthRequestFormId==-1)
      {
        axios
        .get(
          `${process.env.REACT_APP_API_URL}/PatientNote/AuthorizationFormByCPTById?Id=${columnIDcpt}&authId=${columnIDcpt}`,
          { headers }
        )
        .then(response => {
          console.log('response.dataaaaaaaaaaa: ', response.address
          )
          // fields.forEach(field => {
          //   setValue(field, response[field])
          // })
          setCodevob(response.cptcode)
          setdegdvob(response.iCD)
          setProdiceridvob(response.providerId)
          // setFormDataId(response.formId)
         
        })
      
        .catch(error => {
          console.error('There was an error!', error)
        })
      }
  
    
        // empty dependency array means this effect will only run once (like componentDidMount in classes)
      }, [columnIDcpt,priorAuthRequestFormId])

  useEffect(() => {
    setLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/AutherizationRequest/AuthApprovalFormById?Id=${Formdid}&authId=${columnID}`,
        { headers }
      )
      .then(response => {
        fields.forEach(field => {
          setValue(field, response[field])
          setLoading(false)
        })

        console.log('response.dataaaaa: ', response)
        setPatId(response.id)
        setPatName(response.patientName)
        console.log('testresponse',response.patientName)
        // var dateYMD = Moment(response.dob).format('YYYY-MM-DD')
        // setpatientdob(dateYMD)
        // setPatQuestion(response.patientQuestionnaire)
        var rdate = Moment(response.rangeDate).format('YYYY-MM-DD')
        setRangeDate(rdate)
        var rdate = Moment(response.rangeDateform).format('YYYY-MM-DD')
        setRangeDateform(rdate)
        setrangecomments(response.rangecomments)
        setbackofficecomment(response.backOffice)
        var insudate = Moment(response.insuranceEffectiveDate).format('YYYY-MM-DD')
        setInsuDate(insudate)
        setInduDeduct(response.individualDeductible)
        setInduAmou(response.individualAmountMet)
        setRemaDeduct(response.remainingDeductible)
        setFamDeduct(response.familyDeductible)
        setFamAmount(response.familyAmountMet)
        setprimaryInsurance(response.primaryInsurance)
        // setInsuName(response.insuranceName)
        // setPolName(response.policyNumber)
        // setPhoneNumber(response.phoneNumber)
        setRepName(response.representativeName)
        setContract(response.contract)
        // setAddNotes(response.additionalNotes)
        setInNetwork(response.inNetwork)
        setOutNetwork(response.outNetwork)
        setContractYear(response.contractYear)
        setCellReference(response.cellReference)
        setApproval(response.approval)

        // setPAuthNmbr(response.preAuthorizationNumber)
        setApDentist(response.approvedDentist)
        setPauthTreatment(response.preAuthorizationTreatment)
        setCoInsu(response.coInsurancePercAllowable)
        setCoInsuRespon(response.coInsurancePercPatRespon)
        setPInsurance(response.primaryInsurance)
        setestimatedAllowable(response.estimatedAllowable)
        settotalEstimated(response.totalEstimated)
        // setInactive(response.inactive)
        // setPracticeId(response.practiceId)
        // setCreatedBy(response.createdBy)
        // var CreatedDate = Moment(response.createdDate).format('YYYY-MM-DD')
        // setCreatedDate(CreatedDate)
        var CellDate = Moment(response.cellDate).format('YYYY-MM-DD')
        setCellDate(CellDate)
        // setUpdatedBy(response.updatedBy)
        // var UpdatedDate = Moment(response.updatedDate).format('YYYY-MM-DD')
        // setUpdatedDate(UpdatedDate)
        // var CcDate = Moment(response.clientCreatedDate).format('YYYY-MM-DD')
        // setCcDate(CcDate)
        // var CUDate = Moment(response.clientUpdatedDate).format('YYYY-MM-DD')
        // setCUDate(CUDate)
        setCalendarYear(response.calenderYear)
        // setProvName(response.providerName)
        setcellPhone(response.preAuthorizationNumber)
       
        
      })

      .catch(error => {
        console.error('There was an error!', error)
      })

    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, [Formdid,columnID,patName])

  useEffect(() => {
    // POST request using axios inside useEffect React hook
    axios
      .get(`${process.env.REACT_APP_API_URL}/Procedures/Procedures`, {
        headers
      })
      .then(response => {
        var prac = [];
        response.forEach(field => {
          prac.push({
            label: field.code,
            value: field.code,
            
           
          });
         
        });
        setProcedureCode1List(prac);
        
      })
      .catch(error => {});

  }, []);
  useEffect(() => {
    // POST request using axios inside useEffect React hook
    axios
      .get(`${process.env.REACT_APP_API_URL}/Diagnosis/Diagnosis`, {
        headers
      })
      .then(response => {
        var prac = [];
        response.forEach(field => {
          prac.push({
            label: field.code,
            value: field.code
          });
        });
        setDiagnosisList(prac);
        
      })
      .catch(error => {});

  }, []);

 const PriorPracticeID = props.PriorPracticeID
  useEffect(
    () => {
      // setProviders([]);
      // setValue("provider_id", null);
      // if (userPractice != null || userPractice != undefined || userPractice > 0) {
      // POST request using axios inside useEffect React hook
      if(PriorPracticeID == practiceID) {
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/Providers/getPracticeProvider?parcticeid=${practiceID}`,
          { headers }
        )
        .then(response => {
          var prov = [];
          response.forEach(field => {
            prov.push({
              label: field.name,
              value: field.id
            });
          });
          setProviders(prov);
        })
        .catch(error => {});
    } else {
      axios
      .get(
        `${process.env.REACT_APP_API_URL}/Providers/getPracticeProvider?patientid=${AutpattientID}`,
        { headers }
      )
      .then(response => {
        var prov = [];
        response.forEach(field => {
          prov.push({
            label: `${field.firstName}${field.lastName}`,
            value: field.id
          });
        });
        setProviders(prov);
      })
      .catch(error => {});
    }
  },[practiceID]
  );
  const onSubmit = (data) => {
    setSubmitting(true);
    setdisable(true)
    const postData = data;
    const postData1 = data;
    
  const postdatanew ={
    id: columnID,
    approved: true,
    submission:true,
    approval:true,
    practiceId: data.practiceId,
    patientId: props.AutpattientID1,
    AuthRequest: false,
    
  }
  postData.practiceId=  data.practiceId
  postData.id =patId
   postData.authId=columnID
   postData.approved = true
   postData.authCPTId=columnID
   postData.patientName=props.patientNameee 
   postData.patientId=props.AutpattientID1
   postData.representativeName=repName
   postData.calenderYear= ccyear
   postData.contract =contract
   postData.contractYear = contractYear
   postData.insuranceEffectiveDate= insuDate
   postData.cellDate=cellDate
   postData.coInsurancePercAllowable=coInsu
   postData.coInsurancePercPatRespon= coInsuRespon
   postData.inNetwork= inNetwork
   postData.outNetwork=outNetwork
   postData.individualDeductible= induDeduct
   postData.individualAmountMet=induAmou
   postData.remainingDeductible = remaDeduct
   postData.familyDeductible= famDeduct
   postData.familyAmountMet=famAmount
   postData.primaryInsurance =pInsurance
   postData.preAuthorizationTreatment = pauthTreatment
   postData.preAuthorizationNumber =cellPhone
   postData.approvedDentist =apDentist
   postData.approval= approval

   
   postData.rangeDate =rangeDate
   postData.rangeDateform=rangeDateform
   postData.estimatedAllowable =estimatedAllowable
   postData.totalEstimated = totalEstimated
   postData.rangecomments=rangecomments
   postData.backOffice=backofficecomment

   postData.cellReference =cellReference
                    axios
                      .post(
                        `${process.env.REACT_APP_API_URL}/AutherizationRequest/AddAuthApprovalForm`,
                        postData,
                        {
                          headers,
                        }
                      )
                      .then((response) => {
                        if (response.id>0) {
                          console.log("check Data", response);
                          setSubmitting(false);
                         
                          toast.success('Record saved successfully', { 
                            position: "bottom-center",
                            autoClose: 1500,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        
                            onClose: () => {
                             
                              props.updateHealthHistory(response.id, response.authId, response.authCPTId)
                            

                                console.log('checkid',response.id)
                                setdisable(false);
                                props.handleClose4()
                            },

                            });
                         
                        } else {
                          setSubmitting(false);
                          setdisable(false);

                        }
                      })
                      .catch((error) => {
                        setSubmitting(false);
                        setdisable(false);
                        alert.success(`Error ${error.message}`, {
                          type: "error",
                        });
                      });
                      axios
                      .post(
                        `${process.env.REACT_APP_API_URL}/AutherizationRequest/AddAuthorization`,
                        postdatanew,
                        {
                          headers,
                        }
                      )
                      .then((response) => {
                        
                      })
                      .catch((error) => {
                        console.error("There was an error!", error);
                      });
  };
  const saveunappored = (data) => {
    setSubmitting(true);
    setdisable(true)
    const postData = data;
    const postData1 = data;
    const postdatanew ={
      id: columnID,
      approved: false,
      submission:false,
      approval:false,
      practiceId: data.practiceId,
      patientId: props.AutpattientID1,
      AuthRequest: true,

    
    }
    postData.practiceId= data.practiceId
    postData.id =patId
    postData.approved = false
    postData.authId=columnID
    postData.authCPTId=columnID
    postData.patientName=props.patientNameee 
    postData.patientId=props.AutpattientID1
    postData.representativeName=repName
    postData.calenderYear= ccyear
    postData.contract =contract
    postData.contractYear = contractYear
    postData.insuranceEffectiveDate= insuDate
    postData.cellDate=cellDate
    postData.coInsurancePercAllowable=coInsu
    postData.coInsurancePercPatRespon= coInsuRespon
    postData.inNetwork= inNetwork
    postData.outNetwork=outNetwork
    postData.individualDeductible= induDeduct
    postData.individualAmountMet=induAmou
    postData.remainingDeductible = remaDeduct
    postData.familyDeductible= famDeduct
    postData.familyAmountMet=famAmount
    postData.primaryInsurance =pInsurance
    postData.preAuthorizationTreatment = pauthTreatment
    postData.preAuthorizationNumber =cellPhone
    postData.approvedDentist =apDentist
    postData.approval= approval
    postData.rangeDate =rangeDate
    postData.rangeDateform=rangeDateform
    postData.estimatedAllowable =estimatedAllowable
    postData.totalEstimated = totalEstimated
    postData.rangecomments=rangecomments
    postData.backOffice=backofficecomment
 
    postData.cellReference =cellReference
                     axios
                       .post(
                         `${process.env.REACT_APP_API_URL}/AutherizationRequest/AddAuthApprovalForm`,
                         postData,
                         {
                           headers,
                         }
                       )
                       .then((response) => {
                         if (response.id) {
                           console.log("check Data", response);
                           setSubmitting(false);
                           toast.success('Record saved successfully', { 
                            position: "bottom-center",
                            autoClose: 1500,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                             onClose: () => {
                               props.updateHealthHistory(response.id, response.authId)
                                 console.log('checkid',response.id)
                                 setdisable(false);
                                 props.handleClose4()
                             },
 
                             });
                          
                         } else {
                           setSubmitting(false);
                           setdisable(false);
 
                         }
                       })
                       .catch((error) => {
                         setSubmitting(false);
                         setdisable(false);
                         alert.success(`Error ${error.message}`, {
                           type: "error",
                         });
                       });
                       axios
                       .post(
                         `${process.env.REACT_APP_API_URL}/AutherizationRequest/AddAuthorization`,
                         postdatanew,
                         {
                           headers,
                         }
                       )
                       .then((response) => {
                         
                       })
                       .catch((error) => {
                         console.error("There was an error!", error);
                       });
                     

  };
  const Markunappored = (data) => {
    setSubmitting(true);
    setdisable(true)
    const postData = data;
    const postData1 = data;
    
  const postdatanew ={
    id: columnID,
    approved: false,
  }
  postData.practiceId= practiceID
  postData.id =patId
  postData.approved = false
  postData.authId=columnID
  postData.patientName=props.patientNameee 
  postData.patientId=props.AutpattientID1
  postData.representativeName=repName
  postData.calenderYear= ccyear
  postData.contract =contract
  postData.contractYear = contractYear
  postData.insuranceEffectiveDate= insuDate
  postData.cellDate=cellDate
  postData.coInsurancePercAllowable=coInsu
  postData.coInsurancePercPatRespon= coInsuRespon
  postData.inNetwork= inNetwork
  postData.outNetwork=outNetwork
  postData.individualDeductible= induDeduct
  postData.individualAmountMet=induAmou
  postData.remainingDeductible = remaDeduct
  postData.familyDeductible= famDeduct
  postData.familyAmountMet=famAmount
  postData.primaryInsurance =pInsurance
  postData.preAuthorizationTreatment = pauthTreatment
  postData.preAuthorizationNumber =cellPhone
  postData.approvedDentist =apDentist
  postData.approval= approval
  postData.rangeDate =rangeDate
  postData.rangeDateform=rangeDateform
  postData.estimatedAllowable =estimatedAllowable
  postData.totalEstimated = totalEstimated
  postData.rangecomments=rangecomments
  postData.backOffice=backofficecomment

  postData.cellReference =cellReference
                    axios
                      .post(
                        `${process.env.REACT_APP_API_URL}/AutherizationRequest/AddAuthApprovalForm`,
                        postData,
                        {
                          headers,
                        }
                      )
                      .then((response) => {
                        if (response.id) {
                          console.log("check Data", response);
                          setSubmitting(false);
                          toast.success('Record saved successfully', { 
                            position: "bottom-center",
                            autoClose: 1500,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                            onClose: () => {
                              props.updateHealthHistory(response.id, response.authId)
                                console.log('checkid',response.id)
                                setdisable(false);
                                props.handleClose4()
                            },

                            });
                         
                        } else {
                          setSubmitting(false);
                          setdisable(false);

                        }
                      })
                      .catch((error) => {
                        setSubmitting(false);
                        setdisable(false);
                        alert.success(`Error ${error.message}`, {
                          type: "error",
                        });
                      });
                      axios
                      .post(
                        `${process.env.REACT_APP_API_URL}/AutherizationRequest/MarkAsUnApprovedAuth`,
                        postdatanew,
                        {
                          headers,
                        }
                      )
                      .then((response) => {
                        
                      })
                      .catch((error) => {
                        console.error("There was an error!", error);
                      });
  };



 
  

 



  const handlePrint1 = useReactToPrint({
    content: () => printRef1.current,
    onBeforeGetContent: () => {
      setPrintPage(true);
    },
    onAfterPrint: () => {
      setPrintPage(false);
    },
    
  });

  useEffect(() => {
    console.log("printRef1 in useEffect:", printRef1.current);
    if (printpage) {
        // Delay the execution to ensure the printing content is ready
        handlePrint1()

    
  
    }
  }, [printpage]);






  return (
    <div>
     
      <Container>
      {isLoading ? (
              <div style={{ margin: '15rem', textAlign: 'center' }}>
                <CircularProgress />
              </div>
            ) : (<>
               <Grid container  style={{ color: "black" }} spacing={1} >
        
        <Grid item xs={12}>
        <PrintIcon
                       onClick={handlePrint1}
                        fontSize="large"
                       style={{
                          color: 'white',
                          paddingRight: '5px',
                           cursor: 'pointer',
                          float:'right',
                          color:'356F60'
                          }}
                        ></PrintIcon>
        </Grid>
        <Grid item xs={1}></Grid>
    <Grid item xs={3} sm={3} xl={3}>
      <Typography
        style={{
          marginTop: "8px",
          float: "left",
          fontSize: "16px",
          fontWeight: "Bold",
        }}
      >
        Procedure Code(s):
      </Typography>
      </Grid>
    <Grid item  xs={4} sm={4} xl={4} style={{marginTop:"10px"}}>
      <Controller
              name="procedureCode"
              {...register("procedureCode")}
              control={control}
              render={({ field }) =>
                <ReactSelect
                  {...field}
                  fullWidth
                  value={procedureCode1List.find(option => option.value === codevob)}
                  
                  // value={procedureCode1List.filter(function(option) {
                  //   return (
                  //     option.value === getValues("procedureCode")
                  //   );
                  // })}
                  onChange={e => {
                    console.log('eeee',e)
                      setValue("procedureCode", e.value);
                      setProcedureCode1ID(e.value);
                     
                    }}
                   
                  options={procedureCode1List}
                  // styles={customStyles}
                />
               
              }
            />
    </Grid>
    
    <Grid item xs={4}></Grid>
    <Grid item xs={1}></Grid>
    <Grid item xs={3}>
      <Typography
        style={{
          marginTop: "8px",
          float: "left",
          fontSize: "16px",
          fontWeight: "Bold",
          textUnderlineOffset: "true",
        }}
      >
        Diagnosis Code(s):
      </Typography>
      </Grid>
    <Grid item xs={4}>
  
     <Controller
              name="diagnosisCode"
              {...register("diagnosisCode")}
              control={control}
              render={({ field }) =>
                <ReactSelect
                  {...field}
                  fullWidth
                  value={diagnosisList.find(option => option.value === degvob)}
                  // value={diagnosisList.filter(function(option) {
                  //   return (
                  //     option.value === getValues("diagnosisCode")
                  //   );
                  // })}
                  onChange={e => {
                      setValue("diagnosisCode", e.value);
                      setDiagnosisID(e.value);
                     
                    }}
               
                  options={diagnosisList}
                />
               
              }
            />
    </Grid>
 
    <Grid item xs={4}></Grid>
    <Grid item xs={1}></Grid>
    <Grid item xs={4} >
      <Typography
        sx={{
          marginTop: "20px",

        }}
      >
        *Name of Dr(provider) Rendering Service:
      </Typography>
      </Grid>
    <Grid item xs={3} sm={3} xl={3} sx={{ marginTop:'10px'}}>
      <Controller
        name="providerId"
        // {...register("practiceId")}
        control={control}
        render={({ field }) => (
        <ReactSelect
        {...field}
        default
        size="small"
        style={{
          width: "22em",
           }}
           value={providers.find(option => option.value == prodiceridvob)}
           
        // value={providers.filter(function(option) {
        //   return option.value === getValues("providerId");
        // })}
        isDisabled={props.Markaporoved}
        onChange={value => {
          setValue("providerId", value.value);
          setProvidersid(value.value);
          // setProvName("provider_id", value.value);
          // setProviders(value.value);
        }}
        options={providers}
      />
        )}
      />
    </Grid>
  
    <Grid item xs={3}></Grid>

    <Grid container sx={{ marginTop: "20px" }}>
      <Grid item xs={1}></Grid>
      <Grid item xs={1.3} sm={1.3} xl={1.3}>
        <Typography
          sx={{
            marginTop: "10px",
          }}
        >
          Patient Name:
        </Typography>
        </Grid>
      <Grid item xs={4} >
        <Controller
        
          name="patientName"
          // {...register("patientName")}
          control={control}
          
          render={({ field }) => (
            <TextField size="small" variant="standard" {...field} 
            InputProps={{
              readOnly: true,
            }}
            fullWidth
            value={patientdiercttname} 
        onChange={e => {
          setValue('patientName', e.target.value)
          setpatientdiercttname(e.target.value)
        
          
        }}
        inputProps={{
          readOnly: true,  // Corrected prop name
        }}
        />
          )}
        />
      </Grid>
      <Grid item xs={1}></Grid>
      <Grid item xs={0.6}>
        <Typography
          sx={{
            marginTop: "10px",
            
            // marginLeft: "20px",
          }}
        >
          DOB:
        </Typography>
        </Grid>
      <Grid item xs={4}>
      <Controller
        name="dob"
        control={control}
        render={({ field }) => (
          <TextField
            type="date"
            size="small"
            variant="standard"
            {...field}
            fullWidth
            value={dob}
            onChange={(e) => {
              setValue('dob', e.target.value);
              setDOB(e.target.value);
            }}
            InputProps={{
              readOnly: true,
            }}
          />
        )}
      />



      </Grid>
      <Grid item xs={0.5}></Grid>
    </Grid>

    <Grid container sx={{ marginTop: "20px" }} spacing={1}>
      <Grid item xs={1}></Grid>
      <Grid item xs={1.1}>
        <Typography
          sx={{
            marginTop: "10px",
           
          }}
        >
          Insurance:
        </Typography>
        </Grid>
      <Grid item xs={2.5} sx={{ marginLeft: "-10px" }}>
        <Controller
          name="insuranceName"
          // {...register("dob")}
          control={control}
          render={({ field }) => (
            <TextField
            InputProps={{
              readOnly: true,
            }}
              // type="date"
              size="small"
              variant="standard"
              {...field}
              style={{width:"90%"}}

              value={patientinsurancename}
              onChange={e => {
                setValue('insuranceName', e.target.value)
                setpatientinsurancename(e.target.value)
              }}
              inputProps={{
                readOnly: true,  // Corrected prop name
              }}
            />
          )}
        />
      </Grid>
      <Grid item xs={1.2} style={{marginLeft:'15px'}}>
        <Typography
          sx={{
            marginTop: "10px",
           
          }}
        >
          Member Id:
        </Typography>
        </Grid>
      <Grid item xs={2} sx={{ marginLeft: "-10px" }}>
        <Controller
          name="memberId"
          // {...register("phoneNumber")}
          control={control}
          render={({ field }) => (
            <TextField
            // InputProps={{
            //   readOnly: true,
            // }}
              size="small"
              variant="standard"
              {...field}
             fullWidth

              value={memberid}
              onChange={e => {
              setValue('memberId', e.target.value)
              setmemeberid(e.target.value)
              }}
              inputProps={{
                readOnly: true,  // Corrected prop name
              }}
            />
          )}
        />
        
      </Grid>
      <Grid item xs={1.1}  >
        <Typography
          sx={{
            marginTop: "10px",
            
          }}
        >
          Phone No:
        </Typography>
        </Grid>
      <Grid item xs={2} sx={{ marginLeft: "-10px" }} >
        <Controller
          name="phoneNumber"
          // {...register("phoneNumber")}
          control={control}
          render={({ field }) => (
            <TextField
            // InputProps={{
            //   readOnly: true,
            // }}
              size="small"
              variant="standard"
              {...field}
              fullWidth
              value={patientphone}
              onChange={e => {
                setValue('phoneNumber', e.target.value)
                setpatientphone(e.target.value)
              }}
              inputProps={{
                readOnly: true,  // Corrected prop name
              }}
            />
          )}
        />
        
      </Grid>
      </Grid>
      <Grid container sx={{ marginTop: "20px" }}>
      <Grid item xs={1}></Grid>
      <Grid item xs={2}>
        <Typography
          sx={{
            marginTop: "10px",
           
          }}
        >
          Representative Name:
        </Typography>
        </Grid>
      <Grid item xs={2} >
        <Controller
          name="representativeName"
          // {...register("representativeName")}
          control={control}
          render={({ field }) => (
            <TextField
              size="small"
              variant="standard"
              {...field}
              fullWidth
              value={repName}
              onChange={e => {
                setValue('representativeName', e.target.value)
                setRepName(e.target.value)
              }}
            />
          )}
        />
      </Grid>


      <Grid item xs={1}></Grid>

<Grid item xs={1.2}>
        <Typography
          sx={{
            marginTop: "10px",
          }}
        >
          Date Of Call:
        </Typography>
        </Grid>
      <Grid item xs={3} >
        <Controller
          name="cellDate"
          // {...register("cellDate")}
          control={control}
          render={({ field }) => (
            <TextField
              type="date"
              size="small"
              variant="standard"
              {...field}
              style={{width:"45%"}}

              value={cellDate}
              onChange={e => {
                setValue('cellDate', e.target.value)
                setCellDate(e.target.value)
              }}
            />
          )}
        />
      </Grid>
      {/* <Grid item xs={0.5}></Grid> */}
      </Grid>

    <Grid container sx={{ marginTop: "20px" }}>
      <Grid item xs={1} ></Grid>
      <Grid item xs={2.2}>
        <Typography
          sx={{
            marginTop: "10px",
          
          }}
        >
          Insurance Effective Date:
        </Typography>
        </Grid>
      <Grid item xs={2} >
        <Controller
          name="insuranceEffectiveDate"
          // {...register("insuranceEffectiveDate")}
          control={control}
          render={({ field }) => (
            <TextField
              type="date"
              size="small"
              variant="standard"
              {...field}
              style={{width:"100%"}}

              value={insuDate}
              onChange={e => {
                setValue('insuranceEffectiveDate', e.target.value)
                setInsuDate(e.target.value)
              }}
            />
          )}
        />
      </Grid>
      <Grid item xs={0.5} ></Grid>

        <Grid item xs={1.4}  >
        <Typography
          sx={{
            marginTop: "10px",
            // marrginLeft: "20px",
           
          }}
        >
          Calender Year:
        </Typography>
        </Grid>

<Grid item xs={1.2} >
        <Controller
          name="calenderYear"
          // {...register("calenderYear")}
          control={control}
          render={({ field }) => (
            <TextField
            type="text"
              size="small"
              variant="standard"
              
              {...field}
              // inputProps={{
              //   inputMode: 'numeric',
              //   pattern: '[0-9]*',
              //   onInput: (e) => {
              //     const inputValue = e.target.value.trim();
              //     if (inputValue === '' || /^[0-9]*$/.test(inputValue)) {
              //       e.target.value = inputValue !== '' ? Math.max(0, parseInt(inputValue)).toString().slice(0, 10) : ''; // Allow only positive integers
              //     } else {
              //       e.target.value = ''; // Set value to empty if non-numeric input
              //     }
              //     field.onChange(e);
              //   },
              // }}
              value={ccyear}
              onChange={e => {
                setValue('calenderYear', e.target.value)
                setCalendarYear(e.target.value)
              }}
              
              fullWidth
            />
          )}
        />
      </Grid>
      <Grid item xs={0.5}  ></Grid>

      <Grid item xs={1.2}>
        <Typography
     sx={{
      marginTop: "10px",
      // marrginLeft: "20px",
     
    }}
        >
           Or Contract:
        </Typography>
        </Grid>

      <Grid item xs={1.5}  >
        <Controller
          name="contract"
          // {...register("contract")}
          control={control}
          render={({ field }) => (
            <TextField
            type="text"
              size="small"
              variant="standard"
              {...field}
              // inputProps={{
              //   inputMode: 'numeric',
              //   pattern: '[0-9]*',
              //   onInput: (e) => {
              //     const inputValue = e.target.value.trim();
              //     if (inputValue === '' || /^[0-9]*$/.test(inputValue)) {
              //       e.target.value = inputValue !== '' ? Math.max(0, parseInt(inputValue)).toString().slice(0, 10) : ''; // Allow only positive integers
              //     } else {
              //       e.target.value = ''; // Set value to empty if non-numeric input
              //     }
              //     field.onChange(e);
              //   },
              // }}
              value={contract}
              onChange={e => {
                setValue('contract', e.target.value)
                setContract(e.target.value)
              }}
              fullWidth
            />
          )}
        />
        </Grid>
      </Grid>

      <Grid item xs={0.5}>
        
        </Grid>
      </Grid>
  
      <Grid container sx={{ marginTop: "20px" }}>
        <Grid item xs={3}></Grid>
        <Grid item xs={2}>
        <Typography
          sx={{
            marginTop: "10px",
            // float: "right",
          }}
        >
          If Contract Year?:
        </Typography>
        </Grid>
      <Grid item xs={4} >
        <Controller
          name="contractYear"
          // {...register("contractYear")}
          control={control}
          render={({ field }) => (
            <TextField
            type="text"
              size="small"
              variant="standard"
              {...field}
              // inputProps={{
              //   inputMode: 'numeric',
              //   pattern: '[0-9]*',
              //   onInput: (e) => {
              //     const inputValue = e.target.value.trim();
              //     if (inputValue === '' || /^[0-9]*$/.test(inputValue)) {
              //       e.target.value = inputValue !== '' ? Math.max(0, parseInt(inputValue)).toString().slice(0, 10) : ''; // Allow only positive integers
              //     } else {
              //       e.target.value = ''; // Set value to empty if non-numeric input
              //     }
              //     field.onChange(e);
              //   },
              // }}
              fullWidth
              value={contractYear}
              onChange={e => {
                setValue('contractYear', e.target.value)
                setContractYear(e.target.value)
              }}
            />
          )}
        />
      </Grid>
      <Grid item xs={3}></Grid>
      
    </Grid>
<Grid container spacing={1}>  
<Grid item xs={3}></Grid>
<Grid item xs={2}>
        <Typography
          sx={{
            marginTop: "10px",
            // float: "right",
          }}
        >
          Primary insurance
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <div>
          <input
            type="radio"
            name="primaryInsurance"
            checked={pInsurance ? true : false}
            onClick={(e) => {
              setValue('primaryInsurance', true)
              setPInsurance(true)

            }}

            // {...register("heartProblems")}
            style={{ marginLeft: "20px", marginTop: "12px" }}
          />{" "}
          Yes
          <input
            type="radio"
            checked={pInsurance ? false : true}
            onClick={(e) => {
              setValue("primaryInsurance", false);
              setPInsurance(false);
            }}
            style={{ marginLeft: "20px" }}
          />{" "}
          No
        </div>

      </Grid>
      <Grid item xs={3}></Grid>
</Grid>


    <Grid container sx={{ marginTop: "20px" }}>
      {/* <Grid item xs={3.5}></Grid> */}
      <Grid item xs={3}></Grid>
      <Grid item xs={1}>
        <Typography
          sx={{
            marginTop: "10px",
            // float: "right",
          }}
        >
          Co-ins
        </Typography>
        </Grid>
      <Grid item xs={3}>
        <Controller
          name="coInsurancePercAllowable"
          // {...register("coInsurancePercAllowable")}
          control={control}
          render={({ field }) => (
            <TextField
              type='text'
              size="small"
              variant="standard"
              {...field}
              fullWidth
              value={coInsu}
              onChange={e => {
                setValue('coInsurancePercAllowable', e.target.value)
                setCoInsu(e.target.value)
              }}
              style={{
                paddingLeft: '3px'
              }}
            />
          )}
        />
      </Grid>
      <Grid item xs={2}>
        <Typography
          sx={{
            marginTop: "10px",
            // float: "left",
          }}
        >
          % of Allowable 
        </Typography>
      </Grid>
      <Grid item xs={3}></Grid>
    </Grid>
<Grid container spacing={1}>
<Grid item xs={3}></Grid>
  <Grid item xs={6}>
<Typography>(What the ins-co will take responsibility for)</Typography>
  </Grid>
  <Grid item xs={3}></Grid>

</Grid>


    <Grid container sx={{ marginTop: "20px" }}>
      <Grid item xs={3}></Grid>
      <Grid item xs={1}>
        <Typography
          sx={{
            marginTop: "10px",
            // float: "right",
          }}
        >
          Co-ins
        </Typography>
        </Grid>
      <Grid item xs={2}>
        <Controller
          name="coInsurancePercPatRespon"
          // {...register("coInsurancePercPatRespon")}
          control={control}
          render={({ field }) => (
            <TextField
              type='text'
              size="small"
              variant="standard"
              {...field}
              fullWidth
              value={coInsuRespon}
              onChange={e => {
                setValue('coInsurancePercPatRespon', e.target.value)
                setCoInsuRespon(e.target.value)
              }}
              
              style={{
                paddingLeft: '3px'
              }}
            />
          )}
        />
      </Grid>
      <Grid item xs={3}>
        <Typography
          sx={{
            marginTop: "10px",
            float: "left",
          }}
        >
          (Patient responsibility)
        </Typography>
      </Grid>
      <Grid item xs={3}></Grid>
    </Grid>

    <Grid container sx={{ marginTop: "20px" }}>
      <Grid item xs={1}></Grid>
      <Grid item xs={1.1}>
        <Typography
          sx={{
            marginTop: "10px",
           
          }}
        >
          In Network:
        </Typography>
        </Grid>
      <Grid item xs={2} >
        <Controller
          name="inNetwork"
          // {...register("inNetwork")}
          control={control}
          render={({ field }) => (
            <TextField
              size="small"
              variant="standard"
              {...field}
              value={inNetwork}
              onChange={e => {
                setValue('inNetwork', e.target.value)
                setInNetwork(e.target.value)
              }}
              fullWidth

            />
          )}
        />
      </Grid>
      <Grid item xs={1.5}></Grid>
      <Grid item xs={1.5}>
        <Typography
          sx={{
            marginTop: "10px",
            
          }}
        >
          Out-Of-Network:
        </Typography>
        </Grid>
      <Grid item xs={2} >
        <Controller
          name="outNetwork"
          // {...register("outNetwork")}
          control={control}
          render={({ field }) => (
            <TextField
              size="small"
              variant="standard"
              {...field}
              value={outNetwork}
              onChange={e => {
                setValue('outNetwork', e.target.value)
                setOutNetwork(e.target.value)
              }}
              fullWidth

            />
          )}
        />
      </Grid>
      <Grid item xs={2}></Grid>
    </Grid>

    <Grid container>
      <Grid item xs={1}></Grid>
      <Grid item xs={3.5}>
        <Typography>
          *You may not balance bill the patient unless the EOB states
          otherwise
        </Typography>
      </Grid>


     
      <Grid item xs={1}></Grid>
      <Grid item xs={3.5}>
        <Typography>
          You may not balance bill the patient unless the EOB states
          otherwise
        </Typography>
      </Grid>
      <Grid item xs={1.5}></Grid>
    </Grid>

    <Grid container sx={{ marginTop: "20px" }}>
      <Grid item xs={3.5}></Grid>
      <Grid item xs={2.5}>
        <Typography
          sx={{
            // marginTop: "10px",
            marginLeft: "20px",
          }}
        >
          *Individual Deductible : $
        </Typography>
        </Grid>
      <Grid item xs={1}>
        <Controller
          name="individualDeductible"
          // {...register("individualDeductible")}
          control={control}
          render={({ field }) => (
            <TextField
            type="text"
              size="small"
              variant="standard"
              {...field}
              value={induDeduct}
              onChange={e => {
                setValue('individualDeductible', e.target.value)
                setInduDeduct(e.target.value)
              }}
              // error={!!errors.individualDeductible}
              //   helperText={errors.individualDeductible?.message}
  //             inputProps={{
  //               inputMode: 'numeric',
  //               inputMode: 'numeric',
  // pattern: '^\\d*\\.?\\d[0-9]*$',
                
  //               onInput: (e) => {
  //                 const inputValue = e.target.value.trim();
  //                 if (inputValue === '' || /^\d*\.?\d[0-9]*$/.test(inputValue)) {
  //                   e.target.value = inputValue !== '' ? parseFloat(inputValue).toFixed(2) : ''; 
  //                 } else {
  //                   e.target.value = ''; 
  //                 }
  //                 field.onChange(e);
  //               },
  //             }}
              
              fullWidth
            />
          )}
        />
      </Grid>
   
      <Grid item xs={4.5}></Grid>
    </Grid>

    <Grid container sx={{ marginTop: "20px" }}>
      <Grid item xs={4}></Grid>
      <Grid item xs={2}>
        <Typography
          sx={{
            marginLeft: "10px",
          }}
        >
          Amount met: $
        </Typography>
        </Grid>
      <Grid item xs={1} sx={{ marginLeft: "-33px" }}>
        <Controller
          name="individualAmountMet"
          // {...register("individualAmountMet")}
          control={control}
          render={({ field }) => (
            <TextField
            type="text"
              size="small"
              variant="standard"
              {...field}
              value={induAmou}
              onChange={e => {
                setValue('individualAmountMet', e.target.value)
                setInduAmou(e.target.value)
              }}
              fullWidth
              // error={!!errors.individualAmountMet}
              // helperText={errors.individualAmountMet?.message}
             
            />
          )}
        />
      </Grid>
      <Grid item xs={4}></Grid>
    </Grid>

    <Grid container sx={{ marginTop: "20px" }}>
      <Grid item xs={4}></Grid>
      <Grid item xs={2.4}>
        <Typography
            sx={{
              // marginTop: "10px",
              // marginLeft : "70px"
            }}
        >
          Remaining Deductible: $
        </Typography>
        </Grid>
      <Grid item xs={1} sx={{ marginLeft: "-10px" }}>
        <Controller
          name="remainingDeductible"
          // {...register("remainingDeductible")}
          control={control}
          render={({ field }) => (
            <TextField
            type="text"
              size="small"
              variant="standard"
              {...field}
              value={remaDeduct}
              onChange={e => {
                setValue('remainingDeductible', e.target.value)
                setRemaDeduct(e.target.value)
              }}
              fullWidth
              // error={!!errors.remainingDeductible}
              // helperText={errors.remainingDeductible?.message}
            />
          )}
        />
      </Grid>
      <Grid item xs={4}></Grid>
    </Grid>

    <Grid container sx={{ marginTop: "20px" }}>
      <Grid item xs={4}></Grid>
      <Grid item xs={2.5}>
        <Typography
            sx={{
              // marginTop: "10px",
              marginLeft: "15px",
            }}
        >
          *Family Deductible: $
        </Typography>
        </Grid>
      <Grid item xs={1} sx={{ marginLeft: "-30px" }}>
        <Controller
          name="familyDeductible"
          // {...register("familyDeductible")}
          control={control}
          render={({ field }) => (
            <TextField
            type="text"
              size="small"
              variant="standard"
              {...field}
              value={famDeduct}
              onChange={e => {
                setValue('familyDeductible', e.target.value)
                setFamDeduct(e.target.value)
              }}
              fullWidth
              // error={!!errors.familyDeductible}
              // helperText={errors.familyDeductible?.message}
            />
          )}
        />
      </Grid>
      <Grid item xs={4}></Grid>
    </Grid>

    <Grid container sx={{ marginTop: "20px" }}>
      <Grid item xs={4}></Grid>
      <Grid item xs={2.2}>
        <Typography
            sx={{
              // marginTop: "10px",
              marginLeft: "40px",
            }}
        >
          Amount met: $
        </Typography>
        </Grid>
      <Grid item xs={1} sx={{ marginLeft: "-30px" }}>
        <Controller
          name="familyAmountMet"
          // {...register("familyAmountMet")}
          control={control}
          render={({ field }) => (
            <TextField
            type="text"
              size="small"
              variant="standard"
              {...field}
              value={famAmount}
              onChange={e => {
                setValue('familyAmountMet', e.target.value)
                setFamAmount(e.target.value)
              }}
              fullWidth
              // error={!!errors.familyAmountMet}
              // helperText={errors.familyAmountMet?.message}
            />
          )}
        />
      </Grid>
      <Grid item xs={4}></Grid>
    </Grid>

    <Grid container sx={{ marginTop: "20px" }}>
      <Grid item xs={1}></Grid>
      <Grid item xs={5.2}>
        <Typography
           sx={{
            marginTop: "10px",
            marginLeft: "10px",
          }}
        >
          *Is pre-authorization needed before treatment?
        </Typography>
      </Grid>

      <Grid item xs={2} sx={{ marginLeft: "-80px" }}>
        <div>
          <input
            type="radio"
            name="preAuthorizationTreatment"
            checked={pauthTreatment ? true : false}
            onClick={(e) => {
              setValue('preAuthorizationTreatment', true)
              setPauthTreatment(true)

            }}

            style={{ marginLeft: "20px", marginTop: "12px" }}
          />{" "}
          Yes
          <input
            type="radio"
            checked={pauthTreatment ? false : true}
            onClick={(e) => {
              setValue("preAuthorizationTreatment", false);
              setPauthTreatment(false);
            }}
            style={{ marginLeft: "20px" }}
          />{" "}
          No
        </div>

      </Grid>
      <Grid item xs={0.8}>
        <Typography
          sx={{
            marginTop: "10px",
            marginLeft: "-2px"
          }}
        >
          Phone :
        </Typography>
        </Grid>
      <Grid item xs={1.6} >
      <InputMask
          mask='+1 999-999-9999'
          disabled={false}
          value={cellPhone}
          style={{ height: '30px' }}
          onChange={e => {
            setValue('preAuthorizationNumber', e.target.value)
            setcellPhone(e.target.value)
          }}
        >
          {() => <TextField id='outlined-basic' variant="standard" fullWidth size='small' />}
        </InputMask>
       {/* <Controller
          name="preAuthorizationNumber"
        
          control={control}
          render={({ field }) => (
            <TextField
           
              size="small"
              variant="standard"
              {...field}
              fullWidth
              value={patientphone}
              onChange={e => {
                setValue('preAuthorizationNumber', e.target.value)
                setpatientphone(e.target.value)
              }}
            />
          )}
        /> */}
      </Grid>
      <Grid item xs={0.5}></Grid>
    </Grid>


    <Grid container sx={{ marginTop: "20px" }}>
    <Grid item xs={1}></Grid>
      <Grid item xs={5.5}>
        <Typography
          sx={{
            marginTop: "10px",
            marginLeft: "70px",
          }}
        >
             Has the patient used CPAP in the past?
        </Typography>
      </Grid>
      <Grid item xs={5}>
        <Controller
          name="cpap"
          control={control}
          render={({ field }) => (
            <TextField
              type="text"
              size="small"
              variant="standard"
              {...field}
              value={cpap}
              onChange={e => {
                setValue('cpap', e.target.value)
                setcpap(e.target.value)
              }}
              InputProps={{
                readOnly: true,
              }}
              
              fullWidth
            />
          )}
        />
      </Grid>
      <Grid item xs={1}></Grid>
      <Grid item xs={5.5}>
        <Typography
          sx={{
            marginTop: "10px",
            marginLeft: "70px",
          }}
        >
             Has the patient has an oral appliance in the past?
        </Typography>
      </Grid>
      <Grid item xs={5}>
        <Controller
          name="appliance"
          control={control}
          render={({ field }) => (
            <TextField
              type="text"
              size="small"
              variant="standard"
              {...field}
              value={appliance}
              onChange={e => {
                setValue('appliance', e.target.value)
                setappliance(e.target.value)
              }}
              InputProps={{
                readOnly: true,
              }}
              
              fullWidth
            />
          )}
        />
      </Grid>
      <Grid item xs={1}></Grid>
      <Grid item xs={6}>
        <Typography
          sx={{
            marginTop: "10px",
            marginLeft: "70px",
          }}
        >
             Would you like to obtain GAP (if available on plan)?
        </Typography>
      </Grid>
      <Grid item xs={4.5}>
        <Controller
          name="gap"
          control={control}
          render={({ field }) => (
            <TextField
              type="text"
              size="small"
              variant="standard"
              {...field}
              value={GAP}
              onChange={e => {
                setValue('gap', e.target.value)
                setGAP(e.target.value)
              }}
              InputProps={{
                readOnly: true,
              }}
              
              fullWidth
            />
          )}
        />
      </Grid>
      

      
 
    </Grid>
    <Grid container>
    <Grid item xs={4}></Grid>
    <Grid item xs={1}>
        <Typography
          sx={{
            marginTop: "10px",
            // marginLeft : "70px"
          }}
        >
          Approval :
        </Typography>
        </Grid>
        <Grid item xs={3}>
        <Controller
          name="approval"
          // {...register("approval")}
          control={control}
          render={({ field }) => (
            <TextField
              type="text"
              size="small"
              variant="standard"
              {...field}
              value={approval}
              onChange={e => {
                setValue('approval', e.target.value)
                setApproval(e.target.value)
              }}
              // error={!!errors.approval}
              // helperText={errors.approval?.message}
              
              fullWidth
            />
          )}
        />
      </Grid>
      <Grid item xs={4}></Grid>
    </Grid>
   
{approval== ''?(<></>):(<>

  <Grid container sx={{ marginTop: "20px" }}>
      <Grid item xs={4}></Grid>
      <Grid item xs={1.5}>
        <Typography
          sx={{
            marginTop: "10px",
            marginLeft: "20px",
          }}
        >
          Date Range :
        </Typography>
      </Grid>
   
      <Grid item xs={1}>
        <Typography
            sx={{
              marginTop: "10px",
              marginLeft: "20px",
            }}
          >
            From:
          </Typography>
        </Grid>
        <Grid item xs={2} >
     
        <Controller
          name="rangeDateform"
          control={control}
          render={({ field }) => (
            <TextField
              type="date"
              size="small"
              variant="standard"
              {...field}
              fullWidth
              value={rangeDateform}
              onChange={e => {
                setValue('rangeDateform', e.target.value)
                setRangeDateform(e.target.value)
              }}
            />
          )}
        />
      </Grid>
      <Grid item xs={0.5}>
         </Grid>
      <Grid item xs={0.5}>

<Typography
    sx={{
      marginTop: "10px",
      
    }}
  >
    To:
  </Typography>
</Grid>
<Grid item xs={1.5} >
      
        <Controller
         name="rangeDate"
         // {...register("rangeDate")}
         
          control={control}
          render={({ field }) => (
            <TextField
              type="date"
              size="small"
              variant="standard"
              {...field}
              fullWidth
              value={rangeDate}
              onChange={e => {
                setValue('rangeDate', e.target.value)
                setRangeDate(e.target.value)
              }}
            />
          )}
        />
      </Grid>
      <Grid item xs={1.5}></Grid>
     

     </Grid>
</>)}
<Grid container spacing={1}>
    <Grid item xs={1}></Grid>
      <Grid item xs={3.5}>
      <Typography
            sx={{
              marginTop: "10px",
              marginLeft: "20px",
            }}
          >
           Estimated Allowable from Carrier:$
            </Typography>
            </Grid>
      <Grid item xs={1}>
      <Controller
          name="estimatedAllowable"
          // {...register("estimatedAllowable")}
          control={control}
          render={({ field }) => (
            <TextField
            type="text"
              size="small"
              variant="standard"
              {...field}
              value={estimatedAllowable}
              onChange={e => {
                setValue('estimatedAllowable', e.target.value)
                setestimatedAllowable(e.target.value)
              }}
              // error={!!errors.estimatedAllowable}
              // helperText={errors.estimatedAllowable?.message}
              fullWidth
            />
          )}
        />
      </Grid>
      <Grid item xs={4}>
      <Typography
            sx={{
              marginTop: "10px",
              marginLeft: "20px",
            }}
          >
           Estimated Total Patient Responsibility:$
            </Typography>
            </Grid>
      <Grid item xs={1}>
      <Controller
          name="totalEstimated"
          // {...register("totalEstimated")}
          control={control}
          render={({ field }) => (
            <TextField
            type="text"
              size="small"
              variant="standard"
              {...field}
               value={totalEstimated}
              onChange={e => {
                setValue('totalEstimated', e.target.value)
                settotalEstimated(e.target.value)
              }}
              // error={!!errors.totalEstimated}
              // helperText={errors.totalEstimated?.message}
              fullWidth
            />
          )}
        />
      </Grid>
      <Grid item xs={1.5}></Grid>
      {UserType == 'Front Office' || UserType == 'Front Office Admin' ?(<>
        <Grid item xs={1}></Grid>
      <Grid item xs={10} style={{marginTop:"10px"}}>
      <Typography
          sx={{
            marginTop: "10px",
            // marginLeft : "70px"
            fontWeight:'bold'
          }}
        >
          Comments
        </Typography>
        </Grid>
      <Grid item xs={1}></Grid>
      <Grid item xs={1}></Grid>
      <Grid item xs={10} style={{marginTop:"10px"}}>
      <Controller
          name='rangecomments'
          // {...register('rangecomments')}
          control={control}
          render={({ field }) => (
            <TextareaAutosize
              aria-label='minimum height'
              minRows={3}
              placeholder='Enter comments'
              style={{
                width: '100%',
                borderRadius: '8px',
                border: '2px solid #black',
                height: '60px',
              }}
              {...field}
              fullWidth
              value={rangecomments}
              onChange={e => {
                setValue('rangecomments', e.target.value)
                setrangecomments(e.target.value)
              }}
            />
          )}
        />

      </Grid>
      <Grid item xs={1}></Grid>
      
      </>):(<>
        
        <Grid item xs={1}></Grid>
      <Grid item xs={5} style={{marginTop:"10px"}}>
      <Typography
          sx={{
            marginTop: "10px",
            // marginLeft : "70px"
            fontWeight:'bold'
          }}
        >
          Comments
        </Typography>
        </Grid>
      <Grid item xs={5} style={{marginTop:"10px"}}>
      <Typography
          sx={{
            marginTop: "10px",
            // marginLeft : "70px"
            fontWeight:'bold'
          }}
        >
          Back Office Comments
        </Typography>

      </Grid>
      <Grid item xs={1}></Grid>
      <Grid item xs={1}></Grid>
      <Grid item xs={5} style={{marginTop:"10px"}}>
      <Controller
          name='rangecomments'
          // {...register('rangecomments')}
          control={control}
          render={({ field }) => (
            <TextareaAutosize
              aria-label='minimum height'
              minRows={2}
              placeholder='Enter comments'
              style={{
                width: '100%',
                borderRadius: '8px',
                border: '2px solid #black',
                height: '60px', // set a fixed height here
              }}
              {...field}
              fullWidth
              value={rangecomments}
              onChange={e => {
                setValue('rangecomments', e.target.value)
                setrangecomments(e.target.value)
              }}
            />
          )}
        />
      </Grid>
      <Grid item xs={5} style={{marginTop:"10px"}}>
      <Controller
          name='backOffice'
          // {...register('rangecomments')}
          control={control}
          render={({ field }) => (
            <TextareaAutosize
              aria-label='minimum height'
              minRows={2}
              placeholder='Enter comments'
              style={{
                width: '100%',
                borderRadius: '8px',
                border: '2px solid #black',
                height: '60px', // set a fixed height here

              }}
              {...field}
              fullWidth
              value={backofficecomment}
              onChange={e => {
                setValue('backOffice', e.target.value)
                setbackofficecomment(e.target.value)
              }}
            />
          )}
        />
      </Grid>
      <Grid item xs={1}></Grid>
      </>)}
      </Grid>

<Grid container sx={{ marginTop: "20px" }}>
  <Grid item xs={3}></Grid>
  <Grid item xs={2.1}>
        <Typography
          sx={{
            marginTop: "10px",
            // marginLeft : "70px"
          }}
        >
          Cell referenced :
        </Typography>
        </Grid>
      <Grid item xs={3} sx={{ marginLeft: "-50px" }}>
        <Controller
          name="cellReference"
          // {...register("cellReference")}
          control={control}
          render={({ field }) => (
            <TextField
              type="text"
              size="small"
              variant="standard"
              {...field}
              value={cellReference}
              onChange={e => {
                setValue('cellReference', e.target.value)
                setCellReference(e.target.value)
              }}
              
              fullWidth
            />
          )}
        />
      </Grid>
      <Grid item xs={4}></Grid>
    

    <Grid container sx={{ marginTop: "20px" }}>
      <Grid item xs={1}></Grid>
      <Grid item xs={3}>
        <Typography
          sx={{
            marginTop: "10px",
            // marginLeft : "70px"
            fontWeight:'bold'
          }}
        >
          Additional Notes :
        </Typography>
      </Grid>
      <Grid item xs={8}></Grid>
    </Grid>

    <Grid container sx={{ marginTop: "5px", marginBottom: "30px" }}>
      <Grid item xs={1}></Grid>
      <Grid item xs={10}>
        <Typography
          sx={{
            marginTop: "10px",
            // marginLeft : "70px"
          }}
        >
          Insurance Disclaimer: "A quote of benefits and/or authorization
          does not guarantee payment or verify eligibility. Payment or
          benefits are subject to all terms,condition,limitation, and
          exclusions of the member's contract at time of service."
        </Typography>
      </Grid>
      <Grid item xs={1}></Grid>
    </Grid>
   

   
  </Grid>
            
            </>)}
     
     
        {printpage &&  (
          <Grid container  style={{ color: "black" }} spacing={1} ref={printRef1} >
          <Grid item xs={12}> 
        <Headroom>
            <Grid
                  container
                  style={{ backgroundColor: '#356F60', position: 'sticky',top: 0,
                  zIndex: 10000,}}
                >
                  <Grid item xs={12} sm={12} xl={12}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        height: '3rem'
                      }}
                    >
                      <DialogTitle
                        style={{
                          flex: 1,
                          textAlign: 'center',
                        }}
                      >
                        <h1
                          style={{
                            fontWeight: 'bold',
                            textAlign: 'center',
                            color: '#fff',
                            textDecoration: 'underline',
                            fontSize:'2rem'

                          }}
                        >
                         Prior_auth & VOB

                        </h1>
                      </DialogTitle>
                      <Typography style={{color:'white',marginRight:'10px'}}>Patient Name:</Typography>
                      <Typography style={{color:'white',fontWeight:'bold',marginRight:'60px'}}>{(PpatientName && !patName) && (
                          <div>{PpatientName}</div>
                        )}

                        {(!PpatientName && patName) && (
                          <div>{patName}</div>
                        )}

                        {(!PpatientName && !patName) && (
                          ''
                        )}
                        </Typography>
                      <Typography style={{color:'white',marginRight:'10px'}}>ID:</Typography>
                      <Typography style={{color:'white',fontWeight:'bold',marginRight:'60px'}}>{columnID}</Typography>
                   
                    
                    </div>
                  </Grid>
                </Grid>
              </Headroom>
              </Grid>
        
          <Grid item xs={1}></Grid>
          <Grid item xs={3} sm={3} xl={3}>
            <Typography
              style={{
                marginTop: "8px",
                float: "left",
                fontSize: "16px",
                fontWeight: "Bold",
              }}
            >
              Procedure Code(s):
            </Typography>
          </Grid>
          <Grid item  xs={4} sm={4} xl={4} style={{marginTop:"10px"}}>
         
            <Controller
                    name="procedureCode"
                    {...register("procedureCode")}
                    control={control}
                    render={({ field }) =>
                      <ReactSelect
                        {...field}
                        fullWidth
                        value={procedureCode1List.find(option => option.value === codevob)}
                        // value={procedureCode1List.filter(function(option) {
                        //   return (
                        //     option.value === getValues("procedureCode")
                        //   );
                        // })}
                        onChange={e => {
                          console.log('eeee',e)
                            setValue("procedureCode", e.value);
                            setProcedureCode1ID(e.value);
                           
                          }}
                         
                        options={procedureCode1List}
                        // styles={customStyles}
                      />
                     
                    }
                  />
          </Grid>
         
        
          <Grid item xs={4}></Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={3}>
            <Typography
              style={{
                marginTop: "8px",
                float: "left",
                fontSize: "16px",
                fontWeight: "Bold",
                textUnderlineOffset: "true",
              }}
            >
              Diagnosis Code(s):
            </Typography>
          </Grid>
          <Grid item xs={4}>
        
           <Controller
                    name="diagnosisCode"
                    {...register("diagnosisCode")}
                    control={control}
                    render={({ field }) =>
                      <ReactSelect
                        {...field}
                        fullWidth
                        value={diagnosisList.find(option => option.value === degvob)}
                        // value={diagnosisList.filter(function(option) {
                        //   return (
                        //     option.value === getValues("diagnosisCode")
                        //   );
                        // })}
                        onChange={e => {
                            setValue("diagnosisCode", e.value);
                            setDiagnosisID(e.value);
                           
                          }}
                     
                        options={diagnosisList}
                      />
                     
                    }
                  />
          </Grid>
          <Grid item xs={4}></Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={4} >
            <Typography
              sx={{
                marginTop: "20px",
              }}
            >
              *Name of Dr(provider) Rendering Service:
            </Typography>
          </Grid>
          <Grid item xs={3} sm={3} xl={3} sx={{ marginTop:'10px'}}>
      <Controller
        name="providerId"
        // {...register("practiceId")}
        control={control}
        render={({ field }) => (
        <ReactSelect
        {...field}
        default
        size="small"
        style={{
               width: "22em",
           }}
           value={providers.find(option => option.value === prodiceridvob)}
           
        // value={providers.filter(function(option) {
        //   return option.value === getValues("providerId");
        // })}
        isDisabled={props.Markaporoved}
        onChange={value => {
          setValue("providerId", value.value);
          setProvidersid(value.value);
          // setProvName("provider_id", value.value);
          // setProviders(value.value);
        }}
        options={providers}
      />
        )}
      />
    </Grid>
          <Grid item xs={3}></Grid>

          <Grid container sx={{ marginTop: "20px" }}>
      <Grid item xs={1}></Grid>
      <Grid item xs={1.5}>
        <Typography
          sx={{
            marginTop: "10px",
          }}
        >
          Patient Name:
        </Typography>
        </Grid>
      <Grid item xs={4} >
        <Controller
        
          name="patientName"
          // {...register("patientName")}
          control={control}
          render={({ field }) => (
            <TextField size="small" variant="standard" {...field} 
            fullWidth
            InputProps={{
              readOnly: true,
            }}
            value={patientdiercttname} 
        onChange={e => {
          setValue('patientName', e.target.value)
          setpatientdiercttname(e.target.value)
        
          
        }}
        inputProps={{
          readOnly: true,  // Corrected prop name
        }}
        />
          )}
        />
      </Grid>
      <Grid item xs={1}></Grid>
      <Grid item xs={1}>
        <Typography
          sx={{
            marginTop: "10px",
            
            // marginLeft: "20px",
          }}
        >
          DOB:
        </Typography>
        </Grid>
      <Grid item xs={3.5}>
      <Controller
        name="dob"
        control={control}
        render={({ field }) => (
          <TextField
            type="date"
            size="small"
            variant="standard"
            {...field}
            value={dob}
          fullWidth
            onChange={(e) => {
              setValue('dob', e.target.value);
              setDOB(e.target.value);
            }}
            InputProps={{
              readOnly: true,
            }}
          />
        )}
      />



      </Grid>
     
    </Grid>

    <Grid container sx={{ marginTop: "20px" }} spacing={1}>
      <Grid item xs={1}></Grid>
      <Grid item xs={1.1}>
        <Typography
          sx={{
            marginTop: "10px",
           
          }}
        >
          Insurance:
        </Typography>
        </Grid>
      <Grid item xs={2.5} sx={{ marginLeft: "-10px" }}>
        <Controller
          name="insuranceName"
          // {...register("dob")}
          control={control}
          render={({ field }) => (
            <TextField
            InputProps={{
              readOnly: true,
            }}
              // type="date"
              size="small"
              variant="standard"
              {...field}
              style={{width:"90%"}}

              value={patientinsurancename}
              onChange={e => {
                setValue('insuranceName', e.target.value)
                setpatientinsurancename(e.target.value)
              }}
              inputProps={{
                readOnly: true,  // Corrected prop name
              }}
            />
          )}
        />
      </Grid>
      <Grid item xs={1.2} style={{marginLeft:'15px'}}>
        <Typography
          sx={{
            marginTop: "10px",
           
          }}
        >
          Member Id:
        </Typography>
        </Grid>
      <Grid item xs={2} sx={{ marginLeft: "-10px" }}>
        <Controller
          name="memberId"
          // {...register("phoneNumber")}
          control={control}
          render={({ field }) => (
            <TextField
            // InputProps={{
            //   readOnly: true,
            // }}
              size="small"
              variant="standard"
              {...field}
             fullWidth

              value={memberid}
              onChange={e => {
              setValue('memberId', e.target.value)
              setmemeberid(e.target.value)
              }}
              inputProps={{
                readOnly: true,  // Corrected prop name
              }}
            />
          )}
        />
        
      </Grid>
      <Grid item xs={1.1}  >
        <Typography
          sx={{
            marginTop: "10px",
            
          }}
        >
          Phone No:
        </Typography>
        </Grid>
      <Grid item xs={2} sx={{ marginLeft: "-10px" }} >
        <Controller
          name="phoneNumber"
          // {...register("phoneNumber")}
          control={control}
          render={({ field }) => (
            <TextField
            // InputProps={{
            //   readOnly: true,
            // }}
              size="small"
              variant="standard"
              {...field}
              fullWidth
              value={patientphone}
              onChange={e => {
                setValue('phoneNumber', e.target.value)
                setpatientphone(e.target.value)
              }}
              inputProps={{
                readOnly: true,  // Corrected prop name
              }}
            />
          )}
        />
        
      </Grid>
      </Grid>
          <Grid container sx={{ marginTop: "20px" }}>
            <Grid item xs={1}></Grid>
            <Grid item xs={3}>
              <Typography
                sx={{
                  marginTop: "10px",
                  
                }}
              >
                Representative Name:
              </Typography>
            </Grid>
            <Grid item xs={2} >
              <Controller
                name="representativeName"
                // {...register("representativeName")}
                control={control}
                render={({ field }) => (
                  <TextField
                    size="small"
                    variant="standard"
                    {...field}
                    fullWidth
                    value={repName}
                    onChange={e => {
                      setValue('representativeName', e.target.value)
                      setRepName(e.target.value)
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={1}></Grid>

            <Grid item xs={2}>
              <Typography
                sx={{
                  marginTop: "10px",
               
                  
                }}
              >
                Date Of Call:
              </Typography>
            </Grid>
            <Grid item xs={3} >
              <Controller
                name="cellDate"
                // {...register("cellDate")}
                control={control}
                render={({ field }) => (
                  <TextField
                    type="date"
                    size="small"
                    variant="standard"
                    {...field}
                    fullWidth
                    value={cellDate}
                    onChange={e => {
                      setValue('cellDate', e.target.value)
                      setCellDate(e.target.value)
                    }}
                  />
                )}
              />
            </Grid>
            {/* <Grid item xs={0.5}></Grid> */}
          </Grid>

          <Grid container sx={{ marginTop: "20px" }}>
      <Grid item xs={1} ></Grid>
      <Grid item xs={2.2}>
        <Typography
          sx={{
            marginTop: "10px",
          
          }}
        >
          Insurance Effective Date:
        </Typography>
        </Grid>
      <Grid item xs={2} >
        <Controller
          name="insuranceEffectiveDate"
          // {...register("insuranceEffectiveDate")}
          control={control}
          render={({ field }) => (
            <TextField
              type="date"
              size="small"
              variant="standard"
              {...field}
            fullWidth

              value={insuDate}
              onChange={e => {
                setValue('insuranceEffectiveDate', e.target.value)
                setInsuDate(e.target.value)
              }}
            />
          )}
        />
      </Grid>
      <Grid item xs={0.5} ></Grid>

        <Grid item xs={1.4}  >
        <Typography
          sx={{
            marginTop: "10px",
            // marrginLeft: "20px",
           
          }}
        >
          Calender Year:
        </Typography>
        </Grid>

<Grid item xs={1.2} >
        <Controller
          name="calenderYear"
          // {...register("calenderYear")}
          control={control}
          render={({ field }) => (
            <TextField
            type="text"
              size="small"
              variant="standard"
              
              {...field}
              // inputProps={{
              //   inputMode: 'numeric',
              //   pattern: '[0-9]*',
              //   onInput: (e) => {
              //     const inputValue = e.target.value.trim();
              //     if (inputValue === '' || /^[0-9]*$/.test(inputValue)) {
              //       e.target.value = inputValue !== '' ? Math.max(0, parseInt(inputValue)).toString().slice(0, 10) : ''; // Allow only positive integers
              //     } else {
              //       e.target.value = ''; // Set value to empty if non-numeric input
              //     }
              //     field.onChange(e);
              //   },
              // }}
              value={ccyear}
              onChange={e => {
                setValue('calenderYear', e.target.value)
                setCalendarYear(e.target.value)
              }}
              
              fullWidth
            />
          )}
        />
      </Grid>
      <Grid item xs={0.5}  ></Grid>

      <Grid item xs={1.2}>
        <Typography
     sx={{
      marginTop: "10px",
      // marrginLeft: "20px",
     
    }}
        >
           Or Contract:
        </Typography>
        </Grid>

      <Grid item xs={1.5}  >
        <Controller
          name="contract"
          // {...register("contract")}
          control={control}
          render={({ field }) => (
            <TextField
            type="text"
              size="small"
              variant="standard"
              {...field}
              // inputProps={{
              //   inputMode: 'numeric',
              //   pattern: '[0-9]*',
              //   onInput: (e) => {
              //     const inputValue = e.target.value.trim();
              //     if (inputValue === '' || /^[0-9]*$/.test(inputValue)) {
              //       e.target.value = inputValue !== '' ? Math.max(0, parseInt(inputValue)).toString().slice(0, 10) : ''; // Allow only positive integers
              //     } else {
              //       e.target.value = ''; // Set value to empty if non-numeric input
              //     }
              //     field.onChange(e);
              //   },
              // }}
              value={contract}
              onChange={e => {
                setValue('contract', e.target.value)
                setContract(e.target.value)
              }}
              fullWidth
            />
          )}
        />
        </Grid>
      </Grid>

          <Grid container sx={{ marginTop: "20px" }}>
        <Grid item xs={3}></Grid>
        <Grid item xs={2}>
        <Typography
          sx={{
            marginTop: "10px",
            // float: "right",
          }}
        >
          If Contract Year?:
        </Typography>
        </Grid>
      <Grid item xs={4} >
        <Controller
          name="contractYear"
          // {...register("contractYear")}
          control={control}
          render={({ field }) => (
            <TextField
            type="text"
              size="small"
              variant="standard"
              {...field}
              // inputProps={{
              //   inputMode: 'numeric',
              //   pattern: '[0-9]*',
              //   onInput: (e) => {
              //     const inputValue = e.target.value.trim();
              //     if (inputValue === '' || /^[0-9]*$/.test(inputValue)) {
              //       e.target.value = inputValue !== '' ? Math.max(0, parseInt(inputValue)).toString().slice(0, 10) : ''; // Allow only positive integers
              //     } else {
              //       e.target.value = ''; // Set value to empty if non-numeric input
              //     }
              //     field.onChange(e);
              //   },
              // }}
              fullWidth
              value={contractYear}
              onChange={e => {
                setValue('contractYear', e.target.value)
                setContractYear(e.target.value)
              }}
            />
          )}
        />
      </Grid>
      <Grid item xs={3}></Grid>
      
    </Grid>
<Grid container spacing={1}>  
<Grid item xs={3}></Grid>
<Grid item xs={2}>
        <Typography
          sx={{
            marginTop: "10px",
            // float: "right",
          }}
        >
          Primary insurance
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <div>
          <input
            type="radio"
            name="primaryInsurance"
            checked={pInsurance ? true : false}
            onClick={(e) => {
              setValue('primaryInsurance', true)
              setPInsurance(true)

            }}

            // {...register("heartProblems")}
            style={{ marginLeft: "20px", marginTop: "12px" }}
          />{" "}
          Yes
          <input
            type="radio"
            checked={pInsurance ? false : true}
            onClick={(e) => {
              setValue("primaryInsurance", false);
              setPInsurance(false);
            }}
            style={{ marginLeft: "20px" }}
          />{" "}
          No
        </div>

      </Grid>
      <Grid item xs={3}></Grid>
</Grid>


    <Grid container sx={{ marginTop: "20px" }}>
      {/* <Grid item xs={3.5}></Grid> */}
      <Grid item xs={3}></Grid>
      <Grid item xs={1}>
        <Typography
          sx={{
            marginTop: "10px",
            // float: "right",
          }}
        >
          Co-ins
        </Typography>
        </Grid>
      <Grid item xs={3}>
        <Controller
          name="coInsurancePercAllowable"
          // {...register("coInsurancePercAllowable")}
          control={control}
          render={({ field }) => (
            <TextField
              type='text'
              size="small"
              variant="standard"
              {...field}
              fullWidth
              value={coInsu}
              onChange={e => {
                setValue('coInsurancePercAllowable', e.target.value)
                setCoInsu(e.target.value)
              }}
              style={{
                paddingLeft: '3px'
              }}
            />
          )}
        />
      </Grid>
      <Grid item xs={2}>
        <Typography
          sx={{
            marginTop: "10px",
            // float: "left",
          }}
        >
          % of Allowable 
        </Typography>
      </Grid>
      <Grid item xs={3}></Grid>
    </Grid>
<Grid container spacing={1}>
<Grid item xs={3}></Grid>
  <Grid item xs={6}>
<Typography>(What the ins-co will take responsibility for)</Typography>
  </Grid>
  <Grid item xs={3}></Grid>

</Grid>


    <Grid container sx={{ marginTop: "20px" }}>
      <Grid item xs={3}></Grid>
      <Grid item xs={1}>
        <Typography
          sx={{
            marginTop: "10px",
            // float: "right",
          }}
        >
          Co-ins
        </Typography>
        </Grid>
      <Grid item xs={2}>
        <Controller
          name="coInsurancePercPatRespon"
          // {...register("coInsurancePercPatRespon")}
          control={control}
          render={({ field }) => (
            <TextField
              type='text'
              size="small"
              variant="standard"
              {...field}
              fullWidth
              value={coInsuRespon}
              onChange={e => {
                setValue('coInsurancePercPatRespon', e.target.value)
                setCoInsuRespon(e.target.value)
              }}
              
              style={{
                paddingLeft: '3px'
              }}
            />
          )}
        />
      </Grid>
      <Grid item xs={3}>
        <Typography
          sx={{
            marginTop: "10px",
            float: "left",
          }}
        >
          (Patient responsibility)
        </Typography>
      </Grid>
      <Grid item xs={3}></Grid>
    </Grid>

          <Grid container sx={{ marginTop: "20px" }}>
            <Grid item xs={2.5}></Grid>
            <Grid item xs={1.5}>
              <Typography
                sx={{
                  marginTop: "10px",
                }}
              >
                In Network:
              </Typography>
            </Grid>
            <Grid item xs={2} sx={{ marginLeft: "-10px" }}>
              <Controller
                name="inNetwork"
                // {...register("inNetwork")}
                control={control}
                render={({ field }) => (
                  <TextField
                    size="small"
                    variant="standard"
                    {...field}
                    value={inNetwork}
                    onChange={e => {
                      setValue('inNetwork', e.target.value)
                      setInNetwork(e.target.value)
                    }}
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={2}>
              <Typography
                sx={{
                  marginTop: "10px",
                }}
              >
                Out-Of-Network:
              </Typography>
            </Grid>
            <Grid item xs={2} sx={{ marginLeft: "-10px" }}>
              <Controller
                name="outNetwork"
                // {...register("outNetwork")}
                control={control}
                render={({ field }) => (
                  <TextField
                    size="small"
                    variant="standard"
                    {...field}
                    value={outNetwork}
                    onChange={e => {
                      setValue('outNetwork', e.target.value)
                      setOutNetwork(e.target.value)
                    }}
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={1}></Grid>
          </Grid>

          <Grid container>
            <Grid item xs={2}></Grid>
            <Grid item xs={3.5}>
              <Typography>
                *You may not balance bill the patient unless the EOB states
                otherwise
              </Typography>
            </Grid>

            <Grid item xs={1}></Grid>
            <Grid item xs={3.5}>
              <Typography>
                You may not balance bill the patient unless the EOB states
                otherwise
              </Typography>
            </Grid>
            <Grid item xs={1.5}></Grid>
          </Grid>

          <Grid container sx={{ marginTop: "20px" }}>
            <Grid item xs={3.5}></Grid>
            <Grid item xs={3}>
              <Typography
                sx={{
                  // marginTop: "10px",
                  marginLeft: "20px",
                }}
              >
                *Individual Deductible : $
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <Controller
                name="individualDeductible"
                // {...register("individualDeductible")}
                control={control}
                render={({ field }) => (
                  <TextField
                  type="text"
                    size="small"
                    variant="standard"
                    {...field}
                    value={induDeduct}
                    onChange={e => {
                      setValue('individualDeductible', e.target.value)
                      setInduDeduct(e.target.value)
                    }}
        //             inputProps={{
        //               inputMode: 'numeric',
        //               inputMode: 'numeric',
        // pattern: '^\\d*\\.?\\d[0-9]*$',
                      
        //               onInput: (e) => {
        //                 const inputValue = e.target.value.trim();
        //                 if (inputValue === '' || /^\d*\.?\d[0-9]*$/.test(inputValue)) {
        //                   e.target.value = inputValue !== '' ? parseFloat(inputValue).toFixed(2) : ''; 
        //                 } else {
        //                   e.target.value = ''; 
        //                 }
        //                 field.onChange(e);
        //               },
        //             }}
                    
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={4.5}></Grid>
          </Grid>

          <Grid container sx={{ marginTop: "20px" }}>
            <Grid item xs={4}></Grid>
            <Grid item xs={3}>
              <Typography
                sx={{
                  marginLeft: "10px",
                }}
              >
                Amount met: $
              </Typography>
            </Grid>
            <Grid item xs={1} sx={{ marginLeft: "-33px" }}>
              <Controller
                name="individualAmountMet"
                // {...register("individualAmountMet")}
                control={control}
                render={({ field }) => (
                  <TextField
                  type="text"
                    size="small"
                    variant="standard"
                    {...field}
                    value={induAmou}
                    onChange={e => {
                      setValue('individualAmountMet', e.target.value)
                      setInduAmou(e.target.value)
                    }}
                    fullWidth
                   
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}></Grid>
          </Grid>

          <Grid container sx={{ marginTop: "20px" }}>
            <Grid item xs={4}></Grid>
            <Grid item xs={3}>
              <Typography
                sx={{
                  // marginTop: "10px",
                  // marginLeft : "70px"
                }}
              >
                Remaining Deductable: $
              </Typography>
            </Grid>
            <Grid item xs={1} sx={{ marginLeft: "-10px" }}>
              <Controller
                name="remainingDeductible"
                // {...register("remainingDeductible")}
                control={control}
                render={({ field }) => (
                  <TextField
                  type="text"
                    size="small"
                    variant="standard"
                    {...field}
                    value={remaDeduct}
                    onChange={e => {
                      setValue('remainingDeductible', e.target.value)
                      setRemaDeduct(e.target.value)
                    }}
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}></Grid>
          </Grid>

          <Grid container sx={{ marginTop: "20px" }}>
            <Grid item xs={4}></Grid>
            <Grid item xs={3}>
              <Typography
                sx={{
                  // marginTop: "10px",
                  marginLeft: "15px",
                }}
              >
                *Family Deductable: $
              </Typography>
            </Grid>
            <Grid item xs={1} sx={{ marginLeft: "-30px" }}>
              <Controller
                name="familyDeductible"
                // {...register("familyDeductible")}
                control={control}
                render={({ field }) => (
                  <TextField
                  type="text"
                    size="small"
                    variant="standard"
                    {...field}
                    value={famDeduct}
                    onChange={e => {
                      setValue('familyDeductible', e.target.value)
                      setFamDeduct(e.target.value)
                    }}
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}></Grid>
          </Grid>

          <Grid container sx={{ marginTop: "20px" }}>
            <Grid item xs={4}></Grid>
            <Grid item xs={3}>
              <Typography
                sx={{
                  // marginTop: "10px",
                  marginLeft: "40px",
                }}
              >
                Amount met: $
              </Typography>
            </Grid>
            <Grid item xs={1} sx={{ marginLeft: "-30px" }}>
              <Controller
                name="familyAmountMet"
                // {...register("familyAmountMet")}
                control={control}
                render={({ field }) => (
                  <TextField
                  type="text"
                    size="small"
                    variant="standard"
                    {...field}
                    value={famAmount}
                    onChange={e => {
                      setValue('familyAmountMet', e.target.value)
                      setFamAmount(e.target.value)
                    }}
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}></Grid>
          </Grid>

          <Grid container sx={{ marginTop: "20px" }}>
            <Grid item xs={1}></Grid>
            <Grid item xs={6}>
              <Typography
                sx={{
                  marginTop: "10px",
                  marginLeft: "10px",
                }}
              >
                *Is pre-authorization needed before treatment?
              </Typography>
            </Grid>
            <Grid item xs={0.1}></Grid>

            <Grid item xs={2} sx={{ marginLeft: "-80px" }}>
              <div>
                <input
                  type="radio"
                  name="preAuthorizationTreatment"
                  checked={pauthTreatment ? true : false}
                  onClick={(e) => {
                    setValue('preAuthorizationTreatment', true)
                    setPauthTreatment(true)

                  }}

                  style={{ marginLeft: "20px", marginTop: "12px" }}
                />{" "}
                Yes
                <input
                  type="radio"
                  checked={pauthTreatment ? false : true}
                  onClick={(e) => {
                    setValue("preAuthorizationTreatment", false);
                    setPauthTreatment(false);
                  }}
                  style={{ marginLeft: "20px" }}
                />{" "}
                No
              </div>

            </Grid>

            <Grid item xs={1}>
              <Typography
                sx={{
                  marginTop: "10px",
                  marginLeft: "-2px"
                }}
              >
                Phone :
              </Typography>
            </Grid>
            <Grid item xs={1.6} >
            <InputMask
                mask='+1 999-999-9999'
                disabled={false}
                value={cellPhone}
                style={{ height: '30px' }}
                onChange={e => {
                  setValue('preAuthorizationNumber', e.target.value)
                  setcellPhone(e.target.value)
                }}
              >
                {() => <TextField id='outlined-basic' variant="standard" fullWidth size='small' />}
              </InputMask>
             {/* <Controller
                name="preAuthorizationNumber"
              
                control={control}
                render={({ field }) => (
                  <TextField
                 
                    size="small"
                    variant="standard"
                    {...field}
                    fullWidth
                    value={patientphone}
                    onChange={e => {
                      setValue('preAuthorizationNumber', e.target.value)
                      setpatientphone(e.target.value)
                    }}
                  />
                )}
              /> */}
            </Grid>
            <Grid item xs={0.5}></Grid>
          </Grid>

          <Grid container sx={{ marginTop: "20px" }}>
            <Grid item xs={1}></Grid>
            <Grid item xs={5}>
              <Typography
                sx={{
                  marginTop: "10px",
                  marginLeft: "70px",
                }}
              >
                Are dentist approve to perform services?
              </Typography>
            </Grid>
            <Grid item xs={2} sx={{ marginLeft: "-40px" }}>
              <div>
                <input
                  type="radio"
                  name="approvedDentist"
                  checked={apDentist ? true : false}
                  onClick={(e) => {
                    setValue('approvedDentist', true)
                    setApDentist(true)

                  }}

                  // {...register("heartProblems")}
                  style={{ marginLeft: "20px", marginTop: "12px" }}
                />{" "}
                Yes
                <input
                  type="radio"
                  checked={apDentist ? false : true}
                  onClick={(e) => {
                    setValue("approvedDentist", false);
                    setApDentist(false);
                  }}
                  style={{ marginLeft: "20px" }}
                />{" "}
                No
              </div>
            </Grid >
            {/* <Grid item xs={1}></Grid> */}
            <Grid item xs={1}>
              <Typography
                sx={{
                  marginTop: "10px",
                  // marginLeft : "70px"
                }}
              >
                Approval :::
              </Typography>
            </Grid>
            <Grid item xs={1.5}>
              <Controller
                name="approval"
                // {...register("approval")}
                control={control}
                render={({ field }) => (
                  <TextField
                    type="text"
                    size="small"
                    variant="standard"
                    {...field}
                    value={approval}
                    onChange={e => {
                      setValue('approval', e.target.value)
                      setApproval(e.target.value)
                    }}
                    
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={0.5}></Grid>
          </Grid>
          <Grid container spacing={1}>
          <Grid item xs={4}></Grid>
            <Grid item xs={1.5}>
              <Typography
                sx={{
                  marginTop: "10px",
                  marginLeft: "20px",
                }}
              >
                Date Range
              </Typography>
            </Grid>
          
            <Grid item xs={1}>
              <Typography
                  sx={{
                    marginTop: "10px",
                    marginLeft: "20px",
                  }}
                >
                  From
                </Typography>
              </Grid>
              <Grid item xs={2} >
              <Controller
                name="rangeDateform"
               
                control={control}
                render={({ field }) => (
                  <TextField
                    type="date"
                    size="small"
                    variant="standard"
                    {...field}
                    fullWidth
                    value={rangeDateform}
                    onChange={e => {
                      setValue('rangeDateform', e.target.value)
                      setRangeDateform(e.target.value)
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={0.5}>
             </Grid>
            <Grid item xs={0.5}>

<Typography
    sx={{
      marginTop: "10px",
      
    }}
  >
    To:
  </Typography>
</Grid>
<Grid item xs={1.5} >
  <Controller
    name="rangeDate"
    // {...register("rangeDate")}
    
    control={control}
    render={({ field }) => (
      <TextField
        type="date"
        size="small"
        variant="standard"
        {...field}
        fullWidth
        value={rangeDate}
        onChange={e => {
          setValue('rangeDate', e.target.value)
          setRangeDate(e.target.value)
        }}
      />
    )}
  />
</Grid>
            <Grid item xs={1.5}></Grid>
          </Grid>
          <Grid container sx={{ marginTop: "20px" }}>
           
            <Grid item xs={1}></Grid>
            <Grid item xs={3.5}>
            <Typography
                  sx={{
                    marginTop: "10px",
                    marginLeft: "20px",
                  }}
                >
                 Estimated Allowable from Carrier:$
                  </Typography>
            </Grid>
            <Grid item xs={2}>
            <Controller
                name="estimatedAllowable"
                // {...register("estimatedAllowable")}
                control={control}
                render={({ field }) => (
                  <TextField
                  type="text"
                    size="small"
                    variant="standard"
                    {...field}
                    value={estimatedAllowable}
                    onChange={e => {
                      setValue('estimatedAllowable', e.target.value)
                      setestimatedAllowable(e.target.value)
                    }}
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
            <Typography
                  sx={{
                    marginTop: "10px",
                    marginLeft: "20px",
                  }}
                >
                 Estimated Total Patient Responsibility:$
                  </Typography>
            </Grid>
            <Grid item xs={1}>
            <Controller
                name="totalEstimated"
                // {...register("totalEstimated")}
                control={control}
                render={({ field }) => (
                  <TextField
                  type="text"
                    size="small"
                    variant="standard"
                    {...field}
                     value={totalEstimated}
                    onChange={e => {
                      setValue('totalEstimated', e.target.value)
                      settotalEstimated(e.target.value)
                    }}
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={0.5}></Grid>
            {UserType == 'Front Office' || UserType == 'Front Office Admin'  ?(<>
        <Grid item xs={1}></Grid>
      <Grid item xs={10} style={{marginTop:"10px"}}>
      <Typography
          sx={{
            marginTop: "10px",
            // marginLeft : "70px"
            fontWeight:'bold'
          }}
        >
          Comments
        </Typography>

      </Grid>
      <Grid item xs={1}></Grid>
      <Grid item xs={1}></Grid>
      <Grid item xs={10} style={{marginTop:"10px"}}>
      <Controller
          name='rangecomments'
          // {...register('rangecomments')}
          control={control}
          render={({ field }) => (
            <TextareaAutosize
              aria-label='minimum height'
              minRows={3}
              placeholder='Enter comments'
              style={{
                width: '100%',
                borderRadius: '8px',
                border: '2px solid #black',
                height: '80px', // set a fixed height here
              }}
              {...field}
              fullWidth
              value={rangecomments}
              onChange={e => {
                setValue('rangecomments', e.target.value)
                setrangecomments(e.target.value)
              }}
            />
          )}
        />

      </Grid>
      <Grid item xs={1}></Grid>
      
      </>):(<>
        <Grid item xs={1}></Grid>
      <Grid item xs={5} style={{marginTop:"10px"}}>
      <Typography
          sx={{
            marginTop: "10px",
            // marginLeft : "70px"
            fontWeight:'bold'
          }}
        >
          Comments
        </Typography>

      </Grid>
      <Grid item xs={5} style={{marginTop:"10px"}}>
      <Typography
          sx={{
            marginTop: "10px",
            // marginLeft : "70px"
            fontWeight:'bold'
          }}
        >
          Back Office Comments
        </Typography>

      </Grid>
      <Grid item xs={1}></Grid>
      <Grid item xs={1}></Grid>
      <Grid item xs={5} style={{marginTop:"10px"}}>
      <Controller
          name='rangecomments'
          // {...register('rangecomments')}
          control={control}
          render={({ field }) => (
            <TextareaAutosize
              aria-label='minimum height'
              minRows={2}
              placeholder='Enter comments'
              style={{
                width: '100%',
                borderRadius: '8px',
                border: '2px solid #black',
                height: '60px', // set a fixed height here

              }}
              {...field}
              fullWidth
              value={rangecomments}
              onChange={e => {
                setValue('rangecomments', e.target.value)
                setrangecomments(e.target.value)
              }}
            />
          )}
        />

      </Grid>
      <Grid item xs={5} style={{marginTop:"10px"}}>
      <Controller
          name='backOffice'
          // {...register('rangecomments')}
          control={control}
          render={({ field }) => (
            <TextareaAutosize
              aria-label='minimum height'
              minRows={2}
              placeholder='Enter comments'
              style={{
                width: '100%',
                borderRadius: '8px',
                border: '2px solid #black',
                height: '60px', // set a fixed height here

              }}
              {...field}
              fullWidth
              value={backofficecomment}
              onChange={e => {
                setValue('backOffice', e.target.value)
                setbackofficecomment(e.target.value)
              }}
            />
          )}
        />

      </Grid>
      <Grid item xs={1}></Grid>
      
      </>)}

          </Grid>
        

          <Grid container sx={{ marginTop: "20px" }}>
            <Grid item xs={3}></Grid>
            <Grid item xs={3}>
              <Typography
                sx={{
                  marginTop: "10px",
                  // marginLeft : "70px"
                }}
              >
                Cell referenced :
              </Typography>
            </Grid>
            <Grid item xs={3} sx={{ marginLeft: "-50px" }}>
              <Controller
                name="cellReference"
                // {...register("cellReference")}
                control={control}
                render={({ field }) => (
                  <TextField
                    type="text"
                    size="small"
                    variant="standard"
                    {...field}
                    value={cellReference}
                    onChange={e => {
                      setValue('cellReference', e.target.value)
                      setCellReference(e.target.value)
                    }}
                    
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}></Grid>
          </Grid>

          <Grid container sx={{ marginTop: "20px" }}>
            <Grid item xs={1}></Grid>
            <Grid item xs={3}>
              <Typography
                sx={{
                  marginTop: "10px",
                  // marginLeft : "70px"
                  fontWeight:'bold'
                }}
              >
                Additional Notes :
              </Typography>
            </Grid>
            <Grid item xs={8}></Grid>
          </Grid>

          <Grid container sx={{ marginTop: "5px", marginBottom: "30px" }}>
            <Grid item xs={1}></Grid>
            <Grid item xs={10}>
              <Typography
                sx={{
                  marginTop: "10px",
                  // marginLeft : "70px"
                }}
              >
                Insurance Disclaimer: "A quote of benefits and/or authorization
                does not guarantee payment or verify eligibility. Payment or
                benefits are subject to all terms,condition,limitation, and
                exclusions of the member's contract at time of service."
              </Typography>
            </Grid>
            <Grid item xs={1}></Grid>
          </Grid>
         

         
        </Grid>
        )}
      
      
        <Grid container spacing={1}>
 {UserType == 'Front Office' || UserType == 'Front Office Admin' ?(<></>):(<>
            <Grid item xs={12} lg={12} sm={12}>
            <Button
            className="Button"
              onClick={handleSubmit(onSubmit)}
              variant="gradient"
              disabled={disable == true?true:false }
              style={{
                marginTop: "0px",
                marginBottom: "20px",
                float: "right",
                // marginLeft: "-50px",
                // marginRight: "60px",
                width: "200px",
                height: "35px",
                backgroundColor: "#A574FD",
                color: "white",
                fontSize: "14px",
              }}
            >
              Save Approve
            </Button>
            <Button
            className="Button"
              onClick={handleSubmit(saveunappored)}
              variant="gradient"
              disabled={disable == true?true:false }
              style={{
                marginTop: "0px",
                marginBottom: "20px",
                float: "right",
                // marginLeft: "-50px",
                // marginRight: "60px",
                width: "200px",
                height: "35px",
                marginRight:'30px',
                backgroundColor: "#A574FD",
                color: "white",
                fontSize: "14px",
              }}
            >
              Save As Pending
            </Button>
            {props.Markaporoved == true?(<>
              <Button
            className="Button"
              onClick={handleSubmit(Markunappored)}
              variant="gradient"
              disabled={disable == true?true:false }
              style={{
                marginTop: "0px",
                marginBottom: "20px",
                float: "right",
                // marginLeft: "-50px",
                // marginRight: "60px",
                width: "220px",
                height: "35px",
                marginRight:'30px',
                backgroundColor: "#A574FD",
                color: "white",
                fontSize: "14px",
              }}
            >
              Mark as Un Approved
            </Button>
            </>):(<></>)}
           
          </Grid>
          
          </>)}
        </Grid>
      </Container>
      
    </div>
  );
};

export default Prior_auth;
