import React, { useEffect, useReducer, useState, Fragment } from 'react'

import { Grid, Button, MenuItem, Typography, Divider, InputAdornment } from '@mui/material'

// import MuiAlert from '@mui/material'
import axios from 'axios'
import Box from '@mui/material/Box'
import ReactSelect from 'react-select'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import PermIdentity from '@mui/icons-material/PermIdentity'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import TextField from '@mui/material/TextField'
// import { useLocation } from 'react-router-dom'
import DeleteIcon from '@mui/icons-material/Delete'
import ClickAwayListener from '@mui/material/ClickAwayListener';
import CircularProgress from "@mui/material/CircularProgress";

import { Controller, useForm } from 'react-hook-form'
import { useAlert } from 'react-alert'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import AddIcon from '@mui/icons-material/Add'
// import CircularProgress from '@material-ui/core/CircularProgress'
// import Autocomplete from '@material-ui/lab/Autocomplete'
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined'
import { useNavigate } from "react-router-dom";
import { TabPanel, TabContext, TabList } from "@mui/lab";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import moment from 'moment'
import Moment from 'moment'
import DialogTitle from '@mui/material/DialogTitle';
import Headroom from "react-headroom";
import Draggable from 'react-draggable'
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen'
import Modal from '@mui/material/Modal'
import VideoCallIcon from '@mui/icons-material/VideoCall'
import { ToastContainer, toast } from 'react-toastify';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { Backdrop } from '@mui/material';


import Alert from '@mui/material/Alert'




const AppointmentCreate = props => {
  // console.log('🚀 ~ file: AppointmentCreate.js ~ line 162 ~ Appointment ~ Appointment', props.providerID)
  // API Authanticatiion
  const token = localStorage.getItem('Token')
  const auth = `Bearer ${token}`
  const headers = {
    Authorization: auth
  }
  const fields = [
    "id",
    "userId",
    "appointmentType",
    "appointmentDate",
    "startTime",
    "endTime",
    "locationId",
    "appointmentStatus",
    "appointmentReason",
    "duration",
    "practiceId",
    "providerId",
    "supervisorId",
    "providerName"
  ];
  const defValues = {
    id: 0,
    userId: 0,
    appointmentType: "",
    appointmentDate: "",
    startTime: "",
    endTime: "",
    locationId: 0,
    appointmentStatus: "",
    appointmentReason: "",
    duration: 0,
    practiceId: 0,
    supervisorId: 0,
    providerId: 0,
    providerName:''
  };
  const username = localStorage.getItem('UserName')
  const Userid = localStorage.getItem('userid')



  const navigate = useNavigate();
  const alert = useAlert();

  const validationSchema = Yup.object({
    // supervisorId: Yup.string().required("Supervisor is required"),
    // providerId: Yup.string().required("Please Select a Provider"),

    patientName: Yup.string().required("Patient is required"),

  });
  const {
    register,
    control,
    handleSubmit,
    getValues,
    setValue,
    watch,
    formState: { errors }
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: false,
    shouldUseNativeValidation: false,
    delayError: undefined,
    resolver: yupResolver(validationSchema),
    defaultValues: defValues
  });
  const [submitting, setSubmitting] = useState(false);

  const [SuperviseID, setSuperviseID] = useState(props.SuperviseID);
  const [ProviderId, setProviderId] = useState(props.ProviderId);
  console.log('SuperviseID---', SuperviseID);
  console.log('ProviderId----', ProviderId);

  
  const [SuperviseIDmodal, setSuperviseIDmodal] = useState("");
  const [appointmentDate, setappointmentDate] = React.useState(
    props.currentDate.start ?
     Moment(props.currentDate.start).format('YYYY-MM-DD') 
    : Moment().format('YYYY-MM-DD')
  );
  const startDateRangeFormat = new Date(appointmentDate).toISOString().split('T')[0];
  const [sTime, setSTime] = useState(props.sTime);
  const [eTime, setETime] = useState(props.eTime);
  console.log('Endtime', eTime)

  const [appid, setappid] = useState(props.appid !== undefined ? props.appid : 0)


  const startTime = sTime
  const endTime = eTime
  useEffect(() => {
    duration(startTime, endTime);

    // Pass the state variables as arguments
  }, [endTime, startTime]);
  const duration = (startTime, endTime) => {
    console.log("just checking duration wala Fn call ho raha hai ", endTime);

    // Make sure to use startTime instead of sTime in the next line
    const startTimeDuration = moment(`01/01/2022 ${startTime}`);
    const endTimeDuration = moment(`01/01/2022 ${endTime}`);

    // Assuming setValue function is defined somewhere in your code
    const duration = endTimeDuration.diff(startTimeDuration, "minutes", true);
    setValue("duration", duration);
    console.log("Duration:", duration);
  };

  useEffect(() => {
    if (appid > 0) {
      axios
        .get(`${process.env.REACT_APP_API_URL}/Appointment/getAppointmentById?id=${appid}`, {
          headers
        })

        .then(response => {
          fields.forEach(field => {
            setValue(field, response[field]);
            console.log("response.data: ", response);
            var dateYMD = Moment(response.appointmentDate).format('YYYY-MM-DD')
            setappointmentDate(dateYMD)
            var dateYMD = Moment(response.startTime).format("HH:mm:ss")
            setSTime(dateYMD)
            var dateYMD = Moment(response.endTime).format("HH:mm:ss")
            setETime(dateYMD)
            setValue('patientName', response.patientName)
            // setValue('providerId', response.providerId)
            setProviderId(response.providerId)


          });
          setPatientID(response.patientId)


        })
        .catch(error => { });
    }
  }, [appid]);

  const [refresh, setRefresh] = useState(false)

  const ApplyRefresh = props.applyRefreshh
  const applyRefresh = () => {
    setRefresh(!refresh);
  };
  const [patientError, setPatientError] = useState(false);
  const onSubmit = (data) => {
    setSubmitting(true);
    console.log('data', data)
    const StartTimedate = `${appointmentDate ? appointmentDate : startDateRangeFormat}T${sTime}`;
    const EndTimeDate = `${appointmentDate ? appointmentDate : startDateRangeFormat}T${eTime}`;
    const postData = {
      ...data,
      startTime: StartTimedate,
      endTime: EndTimeDate,
      providerId: Number(data.providerId),  
      appointmentDate: appointmentDate,
      supervisorId: SuperviseID
  };
 
  if (!patientID) {
    setPatientError(true);
    setSubmitting(false);
    return;
  } 
  if (!ProviderId) {
    setProviderError(true);
    setSubmitting(false);
    return;
  } 
   
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/Appointment/AddAppointment`,
        postData,
        { headers }
      )
      .then(response => {
        props.CloseModal()
        if (typeof props.applyRefresh === 'function') {
          props.applyRefresh()
        }


        setSubmitting(false);
        alert.success("Record saved successfully.", {
          type: "success",
          onClose: () => {
            props.CloseModal()
            // navigate("/TrackHours");
          }
        });
      })
      .catch(error => {
        setSubmitting(false);
        alert.error(`Error ${error.message}`, {
          type: "error"
        });
      });
  };
  const [openAlert, setOpenAlert] = useState(false);
  const handleClickOpen = () => {

    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);

  };

  //Modal
  const handleDeleteById = () => {
    const postData = {

      id: appid,
      deleted: true,
    };

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/Appointment/AddAppointment`,
        postData,
        {
          headers,
        }
      )
      .then((response) => {
        setSubmitting(false);
        setOpenAlert(false);
        props.applyRefresh()
        alert.success("Deleted Record successfully.", {
          type: "success",
          onClose: () => {
            props.CloseModal()
            // navigate("/TrackHours");
          }
        });

      })
      .catch((error) => {
        console.error(
          "There was an error!",
          error
        );
      });
  };


  const [value, setvalue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setvalue(newValue);
  };

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  })

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])
  const { patientId } = props
  const [state, setState] = useState({ width: 320, height: 200 })
  const { width, height } = useState(windowSize)
  const [open, setOpen] = useState(false)
  const [openn, setOpenn] = useState(false)
  const [roomId, setRoomId] = useState(null);
  const [doc, setDoc] = useState()


  const handleClose = () => {
    setOpenn(false)

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/Users/meetingFinishedBySupervisor?ProviderId=${ProviderId}`,
        null,
        {
          headers,
        }
      )
      .then((response) => {
        if (response.id) {
          console.log(response)
        } else {
          //   setApiError(response.data)
        }
      })
      .catch((error) => { })
  }



  const styleDetailsPdf = {
    // width: '80%',
    // height: '85%',
    width: state.width,
    height: state.height,
    bgcolor: '#F0FFFF',
    position: 'absolute',
    backgroundColor: 'white',
    top: '94px',
    // left: '267px',
    // bottom: '100px',
    right: '100px',
    margin: 0,
    marginTop: 0,
    paddingBottom: '10px',
    padding: '0px',
    // overflow: 'auto',
    zIndex: '10',
    // tabIndex: "-1",
  }

  const minimizedStyle = {
    width: '200px',
    height: '100px',
    position: 'fixed',
    bottom: '20px',
    right: '10px',
    zIndex: '11',
    transition: 'all 0.3s ease',
  }


  const handleResizeStop = (e, direction, ref, d) => {
    var oldState = state

    setState({
      width: oldState.width + d.width,
      height: oldState.height + d.height,
    })
  }

  const resizableStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white',
    outline: 'black',
    border: '1px solid black',
    padding: '16px',
    position: 'absolute',
  }
  const UserType = localStorage.getItem("User_Type")
  const UserName = localStorage.getItem("UserName")
  const [providerList, setProviderList] = useState([]);
  const [providerError, setProviderError] = useState(false); 
  useEffect(() => {
    if (ProviderId) {
      const selectedProvider = providerList.find(option => option.value === ProviderId);
      if (selectedProvider) {
        setValue("providerName", selectedProvider.label); 
        setValue("providerId", selectedProvider.value);
      }
    }
  }, [ProviderId, providerList, setValue]);
  const practiceId = localStorage.getItem('default_paractice')
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/Providers/getProviders?practiceId=${practiceId}`, {
        headers
      })
      .then(response => {
        var prac = [];
        response.forEach(field => {
          prac.push({
            label: `${field.firstName} ${field.lastName}`,
            value: field.id
          });
        });
        setProviderList(prac);


      })
      .catch(error => { });

  }, []);
  const [patient, setPatient] = React.useState(false);

  const [patientData, setPatientData] = React.useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [patientNamee, setPatientNamee] = useState('');
  const [Client, setClient] = useState('');
  const [patientID, setPatientID] = React.useState('')


  const [searchText, setSearchText] = useState('');
  const onPatientHandle = async (value) => {
    console.log(value);
    setIsLoading(true);

    const sendData = {
      id: 0,
      lastName: value,
      firstName: '',
      ssn: '',
      status: '',
      cellPhone: '',
      address: '',
      dob: ''
    };
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/Patients/searchPatient`,
        sendData,
        { headers },
      )

      .then((response) => {
        setPatientData(response);
        setPatient(true);
        setIsLoading(false);


      })
      .catch((error) => {
        console.error('There was an error!', error);
        setIsLoading(false);

      });
  };
  const patientListStyle = {
    cursor: 'pointer',
    hover: {
      background: '#3b3b3b !important',
      color: 'rgba(5, 152, 236, 0.637) !important'
    },
    color: 'green',
    selection: {
      background: '#D7624E',
      color: 'white'
    }
  };
  return (
    <React.Fragment>
      <Grid container spacing={1}>
        <Dialog
          open={openAlert}
          onClose={handleCloseAlert}
          aria-labelledby="responsive-dialog-title"

          BackdropComponent={Backdrop}
          BackdropProps={{
            invisible: true,
          }}
        >
          <DialogContent>
            <DialogContentText style={{ color: 'black' }}>
              Do you want to delete the record?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDeleteById} style={{ background: 'red', color: 'white' }} autoFocus>
              Yes
            </Button>
            <Button autoFocus onClick={handleCloseAlert} style={{ color: 'white', background: '#3085d6' }}>
              No
            </Button>

          </DialogActions>
        </Dialog>
        <Modal
          open={openn}
          closeOnEscape={false}
          closeOnClickOutside={false}
          onClose={() => { }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box>
            <Grid xs={8}>
              <Box style={{ cursor: 'move' }}>
                <Draggable
                  bounds={{ left: -800, top: -100, right: 400, bottom: 500 }}
                >
                  <Box sx={{ resizableStyle }}>
                    <Grid container style={{ backgroundColor: '#356f60' }} >
                      <Grid item xs={9}></Grid>
                      <Grid item xs={3}>
                        <Button sx={{ mb: 1 }} className="Button" onClick={handleClose} variant='contained'
                          style={{
                            fontSize: '14px', fontWeight: 'bold', float: 'right', marginTop: '10px', marginRight: '25px',
                            marginBottom: '5px',
                            width: '80px',
                            height: '35px',
                          }}>
                          <CancelOutlinedIcon
                            fontSize='small'
                            style={{
                              color: 'red',
                              paddingRight: '4px',
                              paddingBottom: '2px'
                            }}
                          ></CancelOutlinedIcon>
                          Close
                        </Button>

                      </Grid>
                    </Grid>

                    {openn ? (
                      <Grid
                        container
                        columnSpacing={1}
                        alignContent="center"
                        justifyContent="center"
                      >
                        <div
                          id="iframe_container"
                          style={{
                            position: 'absolute',
                            top: '3%',
                            // top: '15%',
                            bottom: '0%',
                            left: '0%',
                            right: '0%',
                            marginTop: '10px',
                            // marginBottom:"10px",
                          }}
                        >
                          <iframe
                            name="ifr"
                            // scrolling="yes"
                            fullWidth
                            style={{
                              position: 'absolute',
                              height: '100%',
                              width: '100%',
                            }}
                            src={`${doc}`}
                            frameBorder="0"
                            allow="microphone; camera"
                            allowFullScreen
                          />
                        </div>
                      </Grid>
                    ) : null}
                  </Box>
                </Draggable>
              </Box>
            </Grid>
          </Box>
        </Modal>
        <Grid item xs={12} sm={12} xl={12} >
          <Headroom >
            <Grid
              container
              style={{ backgroundColor: '#356F60', position: 'sticky', padding: '13px' }}
            >
              <Grid item xs={11} sm={11} xl={11} >

                <Typography style={{ color: 'white', fontWeight: '600', textDecoration: 'underline', fontSize: '18px' }}>
                  Appointment Create
                </Typography>

              </Grid>
              <Grid item xs={1} sm={1} xl={1} >

                <CancelOutlinedIcon style={{ color: 'red', cursor: 'pointer' }} onClick={props.CloseModal} />
              </Grid>
            </Grid>
          </Headroom>
        </Grid>
        <Grid item xs={12} sm={12} xl={12} style={{ padding: '20px' }}>

          <Grid container spacing={1}>
            <Grid item xs={6} sm={6} xl={6}>
              <Typography style={{ fontSize: '14px' }}>
                Provider
              </Typography>
            </Grid>
            <Grid item xs={6} sm={6} xl={6}>
              <Typography style={{ fontSize: '14px' }}>
                Patient
              </Typography>
            </Grid>

            <Grid item xs={6} sm={6} xl={6}>
                  <Controller
                name="providerId"
                {...register("providerId")}
                control={control}
                render={({ field }) =>
                  <ReactSelect
                    {...field}
                    fullWidth
                    value={providerList.find(option => option.value === ProviderId)}
                    onChange={(selectedOption) => {
                      setValue("providerId", selectedOption.value);
                      setValue("providerName", selectedOption.label);  // Set the providerName
                      setProviderId(selectedOption.value);
                      setProviderError(false)
                    }}
                    options={providerList}
                  />
                 
                }
              />
              {providerError && <p style={{ color: 'red', fontSize: '14px' }}>Please select Provider.</p>}
             
            </Grid>
            <Grid item xs={6}>
              <ClickAwayListener onClickAway={() => setPatient(false)}>
                <TextField
                  {...register('patientName')}
                  size="small"
                  fullWidth
                  autoComplete="off"
                  placeholder="Enter Patient"
                  value={watch('patientName')} // Using value from form state
                  onKeyUp={(ev) => {
                    console.log('ee:', ev.target.value);
                    setSearchText(ev.target.value);
                    setPatientData([]);
                    if (ev.target.value !== null && ev.target.value !== '' && ev.target.value.length > 0) {
                      onPatientHandle(ev.target.value);
                    } else {
                      setPatient(false);
                    }
                  }}
                  error={errors.patientName}
                  helperText={errors.patientName ? errors.patientName.message : ''}
                />
              </ClickAwayListener>
                {patientError &&<p style={{color:'red',fontSize:'14px'}}>Patient Not Found</p>}
              {isLoading && (
                <CircularProgress size={20} style={{ marginLeft: '-40px' }} />
              )}
              {patient && (
                <div
                  className="col-xs-6"
                  style={{
                    height: 150,
                    overflowY: 'scroll',
                    padding: '14px 16px',
                    fontSize: '0.875rem',
                    color: '#67748e',
                    borderRadius: '5px',
                    background: 'white',
                    textAlign: 'justify',
                    textJustify: 'inter-word',
                    position: 'absolute',
                    zIndex: '99',
                    width: '39%',
                    border: '1px solid #6cb5f9'
                  }}
                >
                  {patientData.length > 0 ? (
                    patientData.map((s, index) => {
                      var pat = `${patientData[index].lastName}, ${patientData[index].firstName}`;
                      var i = pat.indexOf(searchText);
                      var p1 = pat.substring(0, i);
                      var p = pat.substring(i, i + searchText.length);
                      var p2 = pat.substring(i + searchText.length, pat.length);

                      return (
                        <p
                          style={patientListStyle}
                          onClick={(e) => {
                            setValue('patientName', `${patientData[index].lastName}, ${patientData[index].firstName}`);
                            setValue('patientId', patientData[index].id);
                            setValue('patientName', `${patientData[index].lastName}, ${patientData[index].firstName}`);
                            setPatientData([]);
                            setPatient(false);
                            setPatientID(patientData[index].id);
                            setPatientNamee(`${patientData[index].lastName}, ${patientData[index].firstName}`);
                            setPatientError(false)
                          }}
                          onBlur={(e) => {
                            setPatientData([]);
                            setPatient(false);
                          }}
                          onChange={(e) => {
                            setPatientData([]);
                            setPatient(false);
                            setPatientNamee('');
                          }}
                          key={index} // Add key prop to avoid React warning
                        >
                          <span>{p1}</span>
                          <span style={{ backgroundColor: 'yellow' }}>{p}</span>
                          <span>{p2}</span>
                        </p>
                      );
                    })
                  ) : (
                    <Typography>No Record found</Typography>
                  )}
                </div>
              )}
            </Grid>
            <Grid item xs={6} sm={6} xl={6}>
              <Typography style={{ fontSize: '14px' }}>
                Start Time
              </Typography>
            </Grid>
            <Grid item xs={6} sm={6} xl={6}>
              <Typography style={{ fontSize: '14px' }}>
                End Time
              </Typography>
            </Grid>
            <Grid item xs={6} sm={6} xl={6}>
              <Controller
                name="startTime"
                {...register("startTime")}
                control={control}
                render={({ field }) =>
                  <TextField
                    type="time"
                    size="small"
                    {...field}
                    fullWidth
                    // value={props.sTime}
                    value={sTime ? sTime : ''}
                    onChange={e => {
                      setValue('startTime', e.target.value)
                      setSTime(e.target.value)
                    }}


                  />}
              />

            </Grid>
            <Grid item xs={6} sm={6} xl={6}>
              <Controller
                name="endTime"
                // {...register("endTime")}
                control={control}
                render={({ field }) =>
                  <TextField
                    type="time"
                    size="small"
                    {...field}
                    fullWidth
                    // value={props.eTime}
                    value={eTime ? eTime : ''}
                    onChange={e => {
                      setValue('endTime', e.target.value)
                      setETime(e.target.value)
                    }}
                  />}
              />

            </Grid>
            <Grid item xs={6} sm={6} xl={6}>
              <Typography style={{ fontSize: '14px' }}>
                Date
              </Typography>
            </Grid>
            <Grid item xs={6} sm={6} xl={6}>
              <Typography style={{ fontSize: '14px' }}>
                Duration
              </Typography>
            </Grid>
            <Grid item xs={6} sm={6} xl={6}>
              <Controller
                name="appointmentDate"
                // {...register("userName")}
                control={control}
                render={({ field }) =>
                  <TextField
                    type="date"
                    size="small"
                    // {...field}
                    fullWidth
                    value={appointmentDate}
                    onChange={e => {
                      setValue('appointmentDate', e.target.value)
                      setappointmentDate(e.target.value)
                    }}


                  />}
              />

            </Grid>

            <Grid item xs={6} sm={6} xl={6}>
              <Controller
                name="duration"
                {...register("duration")}
                control={control}
                render={({ field }) =>
                  <TextField
                    type="text"
                    size="small"
                    placeholder="please enter duration"
                    {...field}
                    fullWidth
                    InputProps={{
                      readOnly: true,
                      endAdornment: (
                        <InputAdornment position="end">minutes...</InputAdornment>
                      ),
                    }}
                  // value={duration}
                  // onChange={e => {
                  //   setValue("duration", e.target.value);
                  // }}

                  // value={Relationshipcontant}
                  // onChange={ev => {
                  //   console.log('ee:', ev.target.value)
                  //  setRelationshipcontant(ev.target.value)

                  //   }
                  // }

                  />}
              />

            </Grid>


            <Grid item xs={12} sm={12} xl={12}>
              <Typography style={{ fontSize: '14px' }}>
                Reason
              </Typography>
            </Grid>
            <Grid item xs={6} sm={6} xl={6}>
              <Controller
                name="appointmentReason"
                {...register("appointmentReason")}
                control={control}
                render={({ field }) =>
                  <TextField
                    type="text"
                    size="small"
                    placeholder="please enter reason"
                    {...field}
                    fullWidth

                  />}
              />

            </Grid>

            <Grid item xs={6} sm={6} xl={6}>
            </Grid>


            {appid > 0 ? ('') : (
              <Grid item xs={4} lg={4} style={{ marginTop: '10px' }}>
              </Grid>

            )}

            <Grid item xs={4} lg={4} style={{ marginTop: '10px' }}>
              <Button
                className="Button"
                onClick={handleSubmit(onSubmit)}
                disabled={submitting}

                fullWidth
                style={{ borderRadius: '10px', color: 'white', height: '40px', borderColor: "#EA7772", fontSize: '14px', fontWeight: 'bold' }}
                type="submit"
              >
                Save
              </Button>
            </Grid>
            <Grid item xs={4} lg={4} style={{ marginTop: '10px' }}>
              <Button
                className="Button"
                onClick={props.CloseModal}
                disabled={submitting}
                fullWidth
                style={{ borderRadius: '10px', color: 'white', height: '40px', borderColor: "#EA7772", fontSize: '14px', fontWeight: 'bold' }}

              >

                Cancel
              </Button>
            </Grid>
            <Grid item xs={4} lg={4} style={{ marginTop: '10px' }}>
              {appid > 0 ? (<Button
                className="Button"
                onClick={handleClickOpen}
                fullWidth
                style={{ borderRadius: '10px', color: 'white', height: '40px', borderColor: "#EA7772", fontSize: '14px', fontWeight: 'bold' }}

              >
                <DeleteIcon

                  color="black"
                  style={{
                    marginRight: '5px',
                    float: 'right',
                    marginTop: '5px',
                    color: 'red',
                    cursor: 'pointer',
                    zIndex: 250000,
                  }}
                />
                Delete
              </Button>) : ''}



            </Grid>
          </Grid>


        </Grid>

      </Grid>
    </React.Fragment>
  )
}
export default AppointmentCreate;
