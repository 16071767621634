import React, { useCallback, useMemo, useState, useEffect } from "react";
import { Container } from "reactstrap";
import { Button, Grid, TextField } from "@mui/material";
import axios from "axios";
import { Controller, useForm } from "react-hook-form";
import { useAlert } from "react-alert";
import Checkbox from "@mui/material/Checkbox";
import { useNavigate } from "react-router-dom";
import RadioGroup from "@mui/material/RadioGroup";
import Moment from 'moment'
import Select from "react-select";
import SearchIcon from '@mui/icons-material/Search'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./Button.css"
import ClickAwayListener from '@mui/material/ClickAwayListener';
import InputAdornment from '@mui/material/InputAdornment'

// const history = useHistory();
const OSAPAGE = (props) => {
  
  // const patiname = props.PatientName;
  // const Formdidi = props.formDid;
  const [Formdid, setFormdid] = useState(props.formDid !== undefined ? props.formDid : 0)
  const [columnID, setcolumnID] = useState(props.columnID !== undefined ? props.columnID : 0)
  const [AutpattientID, setAutpattientID] = useState(props.AutpattientID)
  console.log('test', AutpattientID)
  // const patientId = props.patientId;
  const patientId = (props.callingFrom =="update"?props.patientid:props.patientId)
  const [patientName, setpatientName] = useState(props.callingFrom == 'OSARequest' ?props.OSARequestpatientName:props.patientName)

  // const [patientName, setpatientName] = useState(props.patientName);
  console.log('paaa',patientName)

  const pname =props.PatientName
  const [careProvDate, setCareProvDate] = React.useState(Moment().format('YYYY-MM-DD'));
  const [datee, setDatee] = React.useState(Moment().format('YYYY-MM-DD'));
  const [consentDatee, setConsentDate] = React.useState(Moment().format('YYYY-MM-DD'));
  const [idd, setIdd] = useState('');
  const [patId, setPatId] = useState('');
  const [patName, setPatName] = useState(pname);
  const [insuName, setInsuName] = useState('');
  const [insuId, setInsuId] = useState('');
  const [Address, setAddresses] = useState("");
  // const Address = props.callingFrom=="Pateint"? props.Address:addresses;
  const [city, setCity] = useState('');
  const [states, setStates] = useState('');
  const [zipCodes, setZipCodes] = useState('');
  const [phones, setPhones] = useState('');
  const [heartProblems, setHeartProblems] = useState();
  const [heartPDetails, setHeartPDetails] = useState('');
  const [highBloodP, setHighBloodP] = useState();
  const [bloodPDetails, setBloodPDetails] = useState('');
  const [diabetes, setDiabetes] = useState();
  const [diabetesDetail, setDiabetesDetail] = useState('');
  const [thyroidSynd, setThyroidSynd] = useState('');
  const [thyroidSyndDetail, setThyroidSyndDetail] = useState('');
  const [presMeds, setPresMeds] = useState('');
  const [presMedDetails, setPresMedDetails] = useState('');
  const [attachList, setAttachList] = useState('');
  const [alcoholCon, setAlcoholCon] = useState('');
  const [smokingLevel, setSmokingLevel] = useState('');
  const [sleepiness, setSleepiness] = useState('');
  const [impairedCog, setImpairedCog] = useState();
  const [insomnia, setInsomnia] = useState();
  const [heartDisease, setHeartDisease] = useState('');
  const [inapprop, setInapprop] = useState('');
  const [excessives, setExcessives] = useState('');
  const [moodDisorders, setMoodDisorders] = useState('');
  const [hypertension, setHypertension] = useState('');
  const [historyStroke, setHistoryStroke] = useState('');
  const [cpap, setCpap] = useState('');
  const [cpap1, setCpap1] = useState('');

  const [cpapLimit, setCpapLimit] = useState('');
  const [deviceCausing, setDeviceCausing] = useState('');
  const [deviceIrritated, setDeviceIrritated] = useState('');
  const [intestIssues, setIntestIssues] = useState('');
  const [others, setOthers] = useState('');
  const [latexAllergy, setLatexAllergy] = useState('');
  const [prevSleep, setPrevSleep] = useState('');
  const [ahiScore, setAhiScore] = useState('');
  const [ahi, setAhi] = useState('');
  const [oralSleepAppl, setOralSleepAppl] = useState('');
  const [oralSleepYear, setOralSleepYear] = useState('');
  const [sitReading, setSitReading] = useState('');
  const [watchingTV, setWatchingTV] = useState('');
  const [sitPlace, setSitPlace] = useState('');
  const [passengerCar, setPassengerCar] = useState('');
  const [layingToRest, setLayingToRest] = useState('');
  const [sitTalking, setSitTalking] = useState('');
  const [sitQuietly, setSitQuietly] = useState('');
  const [consumed, setConsumed] = useState('');
  const [carStationary, setCarStationary] = useState('');
  const [pSig, setPSig] = useState('');
  const [periodCondition, setPeriodCondition] = useState('');
  const [tonsilsEnlarged, setTonsilsEnlarged] = useState('');
  const [grindingOfTeeth, setGrindingOfTeeth] = useState('');
  const [palpitation, setPalpitation] = useState('');
  const [mandiMovement, setMandiMovement] = useState('');
  const [smoothMovement, setSmoothMovement] = useState('');
  const [luxation, setLuxation] = useState('');
  const [tenPlus, setTenPlus] = useState('');
  const [feelFatigued, setFeelFatigued] = useState('');
  const [notUsingCPAP, setNotUsingCPAP] = useState('');
  const [headaches, setHeadaches] = useState('');
  const [daytimeNapping, setDaytimeNapping] = useState('');
  const [provName, setProvName] = useState('');
  const [provSig, setProvSig] = useState('');
  const [patDOB, setPatDOB] = useState('');
  const [orderPhyName, setOrderPhyName] = useState('');
  const [necessityPat, setNecessityPat] = useState('');
  const [inactivve, setInactivve] = useState('');
  const [pracId, setPracId] = useState('');
  const [maxOp, setMaxOp] = useState('');
  const [maxProt, setMaxProt] = useState('');
  const [leftMovements, setLeftMovements] = useState('');
  const [rightMovements, setRightMovements] = useState('');
  const [consentSig, setConsentSig] = useState('');
  const [prName, setPrName] = useState('');
  const [totals, setTotal] = useState('');

  const [suggestions, setSuggestions] = useState([]);
  const [selectedProvider, setSelectedProvider] = useState(null);
  const [openInsurances, setOpenInsurances] = useState(false)
  const [insuranceNames, setInsuranceNames] = React.useState([])

  const fields = [
    "id",
    "patientName",
    "patientId",
    "insuranceName",
    "insurancId",
    "address",
    "city",
    "state",
    "zipCode",
    "phone",
    "heartProblems",
    "heartProblemsDetails",
    "highBloodPressure",
    "highBloodPressureDetails",
    "diabetes",
    "diabetesDetail",
    "thyroidSyndrome",
    "thyroidSyndromeDeatils",
    "prescribedMedicines",
    "prescribedMedicinesDetails",
    "attachList",
    "alcoholConsumption",
    "smokingLevel",
    "sleepiness",
    "impairedCognition",
    "insomnia",
    "ischemicHeartDisease",
    "inappropriate",
    "excessive",
    "moodDisorders",
    "hypertension",
    "historyOfStroke",
    "cpap",
    "cpap1",
    "cpapDaysLimit",
    "deviceCausing",
    "deviceIrritated",
    "intestinalissues",
    "other",
    "latexAllergy",
    "previousSleepStudy",
    "ahiScore",
    "oralSleepAppliance",
    "oralSleepYear",
    "sittingAndReading",
    "watchingTV",
    "sittingInactivePlace",
    "passengerCar",
    "layingToRest",
    "sittingAndTalking",
    "sittingQuietly",
    "consumed",
    "carStationary",
    "patientSignature",
    "sleepinessDate",
    "periodontalCondition",
    "tonsilsEnlarged",
    "grindingOfTeeth",
    "palpitation",
    "mandibularMovement",
    "smoothMovement",
    "luxation",
    "epworthOfTenPlus",
    "feelingFatigued",
    "notUsingCPAP",
    "headaches",
    "inappropriateDaytimeNapping",
    "ahi",
    "providerCareName",
    "providerCareSignature",
    "careProviderDate",
    "orderingPhysicianName",
    "necessityPatient",
    "patDOB",
    "inactive",
    "practiceId",
    "maxOpening",
    "maxProtrusion",
    "maxLateralLeftMovements",
    "maxLateralRightMovements",
    "consentDate",
    "consentSignature",
    "printName",
    "total",
  ];
  const defValues = {
    id: 0,
    patientId: patientId,
    patientName: "",
    insuranceName: "",
    insurancId: 0,
    address: "",
    city: "",
    state: "",
    zipCode: "",
    phone: "",
    heartProblems: "",
    heartProblemsDetails: "",
    highBloodPressure: "",
    highBloodPressureDetails: "",
    diabetes: "",
    diabetesDetail: "",
    thyroidSyndrome: "",
    thyroidSyndromeDeatils: "",
    prescribedMedicines: true,
    prescribedMedicinesDetails: "",
    attachList: "",
    alcoholConsumption: "",
    smokingLevel: "",
    sleepiness: false,
    impairedCognition: "",
    insomnia: "",
    ischemicHeartDisease: "",
    inappropriate: "",
    excessive: false,
    moodDisorders: "",
    hypertension: "",
    historyOfStroke: "",
    cpap: "",
    cpap1: "",
    cpapDaysLimit: "",
    deviceCausing: "",
    deviceIrritated: "",
    intestinalissues: "",
    other: "",
    latexAllergy: "",
    previousSleepStudy: "",
    ahiScore: "",
    oralSleepAppliance: "",
    oralSleepYear: "",
    sittingAndReading: "",
    watchingTV: "",
    sittingInactivePlace: "",
    passengerCar: "",
    layingToRest: "",
    sittingAndTalking: "",
    sittingQuietly: "",
    consumed: "",
    carStationary: "",
    patientSignature: "",
    sleepinessDate: "",
    periodontalCondition: "",
    tonsilsEnlarged: "",
    grindingOfTeeth: "",
    palpitation: "",
    mandibularMovement: "",
    smoothMovement: "",
    luxation: "",
    epworthOfTenPlus: "",
    feelingFatigued: "",
    notUsingCPAP: "",
    headaches: "",
    inappropriateDaytimeNapping: "",
    ahi: "",
    providerCareName: "",
    providerCareSignature: "",
    careProviderDate: "",
    orderingPhysicianName: "",
    necessityPatient: "",
    patDOB: "",
    inactive: true,
    practiceId: 0,
    maxOpening: 0,
    maxProtrusion: 0,
    maxLateralLeftMovements: 0,
    maxLateralRightMovements: 0,
    consentDate: "",
    consentSignature: "",
    printName: "",
    total: "",

  };

 
  const navigate = useNavigate();
 
  const alert = useAlert();
  const token = localStorage.getItem('Token')
  const auth = `Bearer ${token}`
  const headers = {
    Authorization: auth
  }

  const {
    register,
    control,
    handleSubmit,
    getValues,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: false,
    shouldUseNativeValidation: false,
    delayError: undefined,
    // resolver: yupResolver(validationSchema),
    defaultValues: defValues,
  });

  const [submitting, setSubmitting] = useState(false);
  useEffect(() => {

    axios
      .get(
        `${process.env.REACT_APP_API_URL}/AutherizationRequest/ObstructiveSleepApneaById?Id=${Formdid}&authId=${columnID}`,
        { headers }
      )
      .then(response => {
        fields.forEach(field => {
          setValue(field, response[field])
        })

        console.log('response.dataaaaa: ', response)
        setIdd(response.id)
        setPatId(response.patientId)
        setPatName(response.patientName)
        setInsuName(response.insuranceName)
        setInsuId(response.insurancId)
        setAddresses(response.address)
        setCity(response.city)
        setStates(response.state)
        setZipCodes(response.zipCode)
        setPhones(response.phone)
        setHeartProblems(response.heartProblems)
        setHeartPDetails(response.heartProblemsDetails)
        setHighBloodP(response.highBloodPressure)
        setBloodPDetails(response.highBloodPressureDetails)
        setDiabetes(response.diabetes)
        setDiabetesDetail(response.diabetesDetail)
        setThyroidSynd(response.thyroidSyndrome)
        // setThyroidSynd(response.thyroidSyndromeDeatils)
        setPresMeds(response.prescribedMedicines)
        setPresMedDetails(response.prescribedMedicinesDetails)
        setAttachList(response.attachList)
        setAlcoholCon(response.alcoholConsumption)
        setSmokingLevel(response.smokingLevel)
        setSleepiness(response.sleepiness)
        setImpairedCog(response.impairedCognition)
        setInsomnia(response.insomnia)
        setHeartDisease(response.ischemicHeartDisease)
        setInapprop(response.inappropriate)
        setExcessives(response.excessive)
        setMoodDisorders(response.moodDisorders)
        setHypertension(response.hypertension)
        setHistoryStroke(response.historyOfStroke)
        setCpap(response.cpap)
        setCpap1(response.cpap1)

        setCpapLimit(response.cpapDaysLimit)
        setDeviceCausing(response.deviceCausing)
        setDeviceIrritated(response.deviceIrritated)
        setIntestIssues(response.intestinalissues)
        setOthers(response.other)
        setLatexAllergy(response.latexAllergy)
        setPrevSleep(response.previousSleepStudy)
        setAhiScore(response.ahiScore)
        setAhi(response.ahi)
        setOralSleepAppl(response.oralSleepAppliance)
        setOralSleepYear(response.oralSleepYear)
        setSitReading(response.sittingAndReading)
        setWatchingTV(response.watchingTV)
        setSitPlace(response.sittingInactivePlace)
        setPassengerCar(response.passengerCar)
        setLayingToRest(response.layingToRest)
        setSitTalking(response.sittingAndTalking)
        setSitQuietly(response.sittingQuietly)
        setConsumed(response.consumed)
        setCarStationary(response.carStationary)
        setPSig(response.patientSignature)
        setPeriodCondition(response.periodontalCondition)
        setTonsilsEnlarged(response.tonsilsEnlarged)
        setGrindingOfTeeth(response.grindingOfTeeth)
        setPalpitation(response.palpitation)
        setMandiMovement(response.mandibularMovement)
        setSmoothMovement(response.smoothMovement)
        setLuxation(response.luxation)
        setTenPlus(response.epworthOfTenPlus)
        setFeelFatigued(response.feelingFatigued)
        setNotUsingCPAP(response.notUsingCPAP)
        setHeadaches(response.headaches)
        setDaytimeNapping(response.inappropriateDaytimeNapping)
        setProvName(response.providerCareName)
        setProvSig(response.providerCareSignature)
        setOrderPhyName(response.orderingPhysicianName)
        setNecessityPat(response.necessityPatient)
        // var PattDob = Moment(response.patDOB).format('YYYY-MM-DD')
        setPatDOB(response.patDOB)
        setInactivve(response.inactive)
        setPracId(response.practiceId)
        setMaxOp(response.maxOpening)
        setMaxProt(response.maxProtrusion)
        setLeftMovements(response.maxLateralLeftMovements)
        setRightMovements(response.maxLateralRightMovements)
        setConsentSig(response.consentSignature)
        setPrName(response.printName)
        setTotal(response.total)

        var PattDob2 = Moment(response.sleepinessDate).format('YYYY-MM-DD');
        setDatee(PattDob2);

        var PattDob1 = Moment(response.careProviderDate).format('YYYY-MM-DD');
        setCareProvDate(PattDob1);

        var conscDob = Moment(response.consentDate.format('YYYY-MM-DD'));
        setConsentDate(conscDob);
      })

      .catch(error => {
        console.error('There was an error!', error)
      })

    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, [Formdid,patId,columnID])
  const onSubmit = (data) => {
    setSubmitting(true);
    const postData = data;
    postData.total =Total
    console.log('postData', postData)
    postData.authId = columnID
    {props.callingFrom=="Patient"?postData.patientName = patientName:postData.patientName = pname }
    {props.callingFrom=="Patient"?postData.patientId = AutpattientID:postData.patientId = patientId }
    {props.callingFrom=="Patient"?postData.id=idd:postData.id=idd}
    // postData.patientName= patientName
    // postData.patientId = AutpattientID
    // postData.address = Address;
    console.log("check Data", postData);
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/AutherizationRequest/AddObstructiveSleepApnea`,
        postData,
        {
          headers,
        }
      )
      .then((response) => {
        if (response.id) {
          console.log("check Data", response);
          setSubmitting(false);
          toast.success('Record saved successfully', { 
            position: "bottom-center",
            // autoClose: 2500,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "light",
            onClose: () => {
              if(props.callingFrom =='Patient') 
              {
                props.updateHealthHistory(response.id, response.authId)

                props.handleClose1()
                console.log('checkid',response.id)
              }
              else if(props.callingFrom=="OSARequest")
              {
                // props.updateHealthHistory(response.id, response.authId, response.patientName)
                props.updateHealthHistory(response.id, response.authId)

                props.handleClose1()
              }
              else if(props.callingFrom=="update")
              {
                // props.updateHealthHistory(response.id, response.authId, response.patientName)
                props.closevobpage() 
              }
              else if(props.callingFrom =="dashboard")
              {
                props.updateHealthHistory(response.id, response.authId, response.patientName)
                // props.closevobpage()
              }
             
              else {
                props.closeosapage()

              }
            
            },
          });
        } else {
          setSubmitting(false);
        }
      })
      .catch((error) => {
        setSubmitting(false);
        alert.success(`Error ${error.message}`, {
          type: "error",
        });
      });
  };


 
  const zipCodeListStyle = {
    cursor: 'pointer',
    '&:hover': {
      background: '#3b3b3b !important',
      color: 'rgba(5, 152, 236, 0.637) !important'
    }
  }
  const inputProps = [
    'id',
    'insuranceName',

  ];

  const onchangeinsurce = async value => {
    setOpenInsurances(true)

    axios
      .get(`${process.env.REACT_APP_API_URL}/Insurances/getInsuranceByName?Name=${value}`, { headers, inputProps })
      .then(response => {
        setOpenInsurances(true)
        setInsuranceNames(response);

      })
      .catch(error => {
        console.error('Error fetching insurance suggestions:', error);
      })

  }
  const [patientinsurancename, setpatientinsurancename] = useState("");
  const [patientiaddress, setpatientaddress] = useState("");
  const [patientphone, setpatientphone] = useState("");
  const [patientcity, setpatientcity] = useState("");
  const [patientsate, setpatientsate] = useState("");
  const [patientzipcode, setpatientzipcode] = useState("");

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/Patients/SearchInsuranceName?patientId=${AutpattientID}`,
        {
          headers,
        }
      )
      .then((response) => {
        // console.log('testname',response[0].dob)
        var dateYMD = Moment(response.dob).format('YYYY-MM-DD')
        // setpatientdob(dateYMD)
        // setpatientdob(response.dob)
        // setpatientdob(Moment(response.dob).format('YYYY-MM-DD'));

        setpatientinsurancename(response.planName)
           setpatientcity(response.city)
      setpatientsate(response.state)
      setpatientzipcode(response.zipCode)
      
      setpatientaddress(response.address)
        setpatientphone(response.phoneNumber)

       
        // setRefresh(!refresh)
      })

      .catch((error) => {
        console.error("There was an error!", error);
      });
  }, [AutpattientID]);

  const values = watch();

  const calculateTotal = () => {
    const fieldse = getValues([
      "watchingTV",
      // sitReading,
      "sittingAndReading",
      "sittingInactivePlace",
      "passengerCar",
      "layingToRest",
      "sittingAndTalking",
      "sittingQuietly",
      "carStationary",
    ]);

    return Object.values(fieldse).reduce((total, value) => total + (parseInt(value) || 0), 0);
  };
  const Total = calculateTotal(); 
  return (
    <Container>
      <Grid container columnSpacing={1}>
        <Grid item xs={1}></Grid>
        <Grid item xs={10}>
          {/* <h1
            style={{
              fontWeight: "bold",
              textAlign: "center",
              color: "black",
            }}
          >
            Snoring and obstructive sleep apnea (OSA)
          </h1> */}
        </Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={4}></Grid>
        <Grid item xs={4}>
          {/* <h1
            style={{
              fontWeight: "bold",
              textAlign: "center",
              color: "black",
            }}
          >
            Screener{" "}
          </h1> */}
        </Grid>
        <Grid item xs={4}></Grid>
        <Grid item xs={3}></Grid>
        <Grid item xs={7}>
          <h6 style={{ textAlign: "center", marginTop:'2rem' }}>
            Snoring and daytime sleepiness can have a profound impact on quality
            of life:{" "}
          </h6>
        </Grid>
        <Grid item xs={2}></Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={2} style={{ marginTop: "10px" }}>
          <h6 style={{ fontWeight: "bold" }}>• Daytime sleepiness </h6>
        </Grid>
        <Grid item xs={9} style={{ marginTop: "10px" }}>
          <h6 style={{ marginLeft: "-25px" }}>
            {" "}
            - less effectiveness at work and increased risk of accidents
          </h6>
        </Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={2.5}>
          <h6 style={{ fontWeight: "bold" }}>• Reduced energy-poor </h6>
        </Grid>
        <Grid item xs={8.5}>
          <h6 style={{ marginLeft: "-18px" }}>
            motivation to exercise and weight gain
          </h6>
        </Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={2}>
          <h6 style={{ fontWeight: "bold" }}>• Relationship issues </h6>
        </Grid>
        <Grid item xs={9}>
          <h6 style={{ marginLeft: "-25px" }}>
            - sleeping in different bedrooms, reduced sex life, and higher
            stress levels
          </h6>
        </Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={2}>
          <h6 style={{ fontWeight: "bold" }}>• Hypertension </h6>
        </Grid>
        <Grid item xs={9}>
          <h6 style={{ marginLeft: "-60px" }}>
            - those who snore or suffer from OSA have an elevated risk of high
            blood pressure
          </h6>
        </Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={11} style={{ marginTop: "10px" }}>
          <h6>
            OSA is a serious condition in which a person stops breathing (or
            suffers extremely low oxygen levels) while asleep. It often
          </h6>
        </Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={11}>
          <h6>occurs in conjunction with snoring.</h6>
        </Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={2} style={{ marginTop: "10px" }}>
          <h5>Patient name:</h5>
        </Grid>
        <Grid item xs={4} style={{ marginLeft: "-40px" }}>
          <Controller
            name="patientName"
            {...register("patientName")}
            control={control}
            render={({ field }) => (
              <TextField size="small" variant="standard" {...field} fullWidth
              value={props.callingFrom == 'Patient' || props.callingFrom == 'OSARequest' ? patientName : patName}

              //  value={props.callingFrom =='Patient'?patientName:patName} 
              onChange={e => {
                setValue('patientName', e.target.value)
                setPatName('patientName',e.target.value)
                setpatientName('patientName',e.target.value)
              }}
              inputProps={{
                readOnly: true,  // Corrected prop name
              }}
              />
            )}
          />
        </Grid>
        <Grid item xs={5}></Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={2} style={{ marginTop: "10px" }}>
          <h5>Insurance:</h5>
        </Grid>
        <Grid item xs={4} style={{ marginLeft: "-40px" }}>
        {/* <Controller
                                name='insuranceName'
                                {...register('insuranceName')}
                                control={control}
                                render={({ field }) => (
                                  <TextField
                                    {...field}
                                    type="input"
                                    fullWidth
                                    size='small'
                                    onKeyUp={(ev) => {
                                   
                                      if (ev.target.value !== '' && ev.target.value !== null) {
                                        onchangeinsurce(ev.target.value);
                                      } else {
                                        setInsuranceNames([]);
                                        setOpenInsurances(false);
                                      }
                                    }}
                                  />
                                )}
                              />

                              {openInsurances ? (
                                <div
                                  className="col-xs-3"
                                  style={{
                                    padding: '14px 16px',
                                    fontSize: '0.875rem',
                                    color: '#67748e',
                                    borderRadius: '5px',
                                    background: 'white',
                                    textAlign: 'justify',
                                    textJustify: 'inter-word',
                                    // position: 'absolute',
                                    // zIndex: '99',
                                    width: '100%',
                                    border: '1px solid #6cb5f9'
                                  }}
                                >
                                  {insuranceNames.map((s, index) => (
                                    <p
                                      style={zipCodeListStyle}
                                      onClick={(ev) => {
                                        setValue(
                                          'insuranceName',
                                          insuranceNames[index].id
                                        )
                                        setValue(
                                          'insuranceName',
                                          insuranceNames[index].payerid
                                        )
                                        setValue(
                                          'insuranceName',
                                          insuranceNames[index].name
                                        )
                                        setOpenInsurances(false)
                                        setInsuranceNames([])
                                      }}
                                      onBlur={(ev) => {
                                        setInsuranceNames([])
                                        setOpenInsurances(false)
                                      }}
                                      onChange={(ev) => {
                                        setInsuranceNames([])
                                        setOpenInsurances(false)
                                      }}
                                    >
                                      {s.name}
                                    </p>
                                  ))}
                                </div>
                              ) : (
                                ''
                              )} */}
                                    <Controller
                name="insuranceName"
                // {...register("dob")}
                control={control}
                render={({ field }) => (
                  <TextField
                  InputProps={{
                    readOnly: true,
                  }}
                    // type="date"
                    size="small"
                    variant="standard"
                    {...field}
                    fullWidth
                    value={patientinsurancename}
                    onChange={e => {
                      setValue('insuranceName', e.target.value)
                      setpatientinsurancename(e.target.value)
                    }}
                    inputProps={{
                      readOnly: true,  // Corrected prop name
                    }}
                  />
                )}
              />
        </Grid>
        <Grid item xs={5} style={{ marginLeft: "-40px" }}>
          
        </Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={1} style={{ marginTop: "10px" }}>
          <h5>Address:</h5>
        </Grid>
        <Grid item xs={2} style={{ marginLeft: "-6px" }}>
     
        {/* <Controller
          name="address"
          {...register("address")}
          control={control}
          render={({ field }) => (
            <TextField size="small" variant="standard" {...field} fullWidth />
          )}
        /> */}
         <Controller
          name="address"
          // {...register("dob")}
          control={control}
          render={({ field }) => (
            <TextField
              variant="standard"
              style={{
                width: "75%",
              }}
              size="small"
              fullWidth
              {...field}
              name="address"
             
              // type="date"
              value={patientiaddress}
              onChange={e => {
                setValue('address', e.target.value)
                setpatientaddress(e.target.value)
              }}
              inputProps={{
                readOnly: true,  // Corrected prop name
              }}
            />
          )}
        />
        </Grid>
        <Grid item xs={1} style={{ marginTop: "10px" }}>
          <h5>City</h5>
        </Grid>
        <Grid item xs={2} style={{ marginLeft: "-40px" }}>
          {/* <Controller
            name="city"
            {...register("city")}
            control={control}
            render={({ field }) => (
              <TextField size="small" variant="standard" {...field} fullWidth />
            )}
          /> */}
           <Controller
          name="address"
          // {...register("dob")}
          control={control}
          render={({ field }) => (
            <TextField
              variant="standard"
              style={{
                width: "75%",
              }}
              size="small"
              fullWidth
              {...field}
              name="city"
             
              // type="date"
              value={patientcity}
              onChange={e => {
                setValue('city', e.target.value)
                setpatientcity(e.target.value)
              }}
              inputProps={{
                readOnly: true,  // Corrected prop name
              }}
            />
          )}
        />
        </Grid>
        <Grid item xs={1} style={{ marginTop: "10px" }}>
          <h5>State</h5>
        </Grid>
        <Grid item xs={2} style={{ marginLeft: "-30px" }}>
          {/* <Controller
            name="state"
            {...register("state")}
            control={control}
            render={({ field }) => (
              <TextField size="small" variant="standard" {...field} fullWidth />
            )}
          /> */}
           <Controller
          name="state"
          // {...register("dob")}
          control={control}
          render={({ field }) => (
            <TextField
              variant="standard"
              style={{
                width: "75%",
              }}
              size="small"
              fullWidth
              {...field}
              name="state"
             
              // type="date"
              value={patientsate}
              onChange={e => {
                setValue('state', e.target.value)
                setpatientsate(e.target.value)
              }}
              inputProps={{
                readOnly: true,  // Corrected prop name
              }}
            />
          )}
        />
        </Grid>
        <Grid item xs={1} style={{ marginTop: "10px" }}>
          <h5>Zip</h5>
        </Grid>
        <Grid item xs={2} style={{ marginLeft: "-40px" }}>
          {/* <Controller
            name="zipCode"
            {...register("zipCode")}
            control={control}
            render={({ field }) => (
              <TextField size="small" variant="standard" {...field} fullWidth />
            )}
          /> */}
           <Controller
          name="zipCode"
          // {...register("dob")}
          control={control}
          render={({ field }) => (
            <TextField
              variant="standard"
              style={{
                width: "75%",
              }}
              size="small"
              fullWidth
              {...field}
              name="zipCode"
              // type="date"
              value={patientzipcode}
              onChange={e => {
                setValue('zipCode', e.target.value)
                setpatientzipcode(e.target.value)
              }}
              inputProps={{
                readOnly: true,  // Corrected prop name
              }}
            />
          )}
        />
        </Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={2} style={{ marginTop: "10px" }}>
          <h5>Phone#</h5>
        </Grid>
        <Grid item xs={4} style={{ marginLeft: "-70px" }}>
          {/* <Controller
            name="phone"
            {...register("phone")}
            control={control}
            render={({ field }) => (
              <TextField size="small" variant="standard" {...field} fullWidth />
            )}
          /> */}
           <Controller
                name="phone"
                // {...register("phoneNumber")}
                control={control}
                render={({ field }) => (
                  <TextField
                  // InputProps={{
                  //   readOnly: true,
                  // }}
                    size="small"
                    variant="standard"
                    {...field}
                    fullWidth
                    value={patientphone}
                    onChange={e => {
                      setValue('phone', e.target.value)
                      setpatientphone(e.target.value)
                    }}
                    inputProps={{
                      readOnly: true,  // Corrected prop name
                    }}
                  />
                )}
              />
        </Grid>
        <Grid item xs={5}></Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={10} style={{ marginTop: "20px" }}>
          <h6 style={{ textAlign: "center" }}>
            The following questions relate to your lifestyle and general health.
            Please indicate whether you suffer from any of the{" "}
          </h6>
        </Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={3}></Grid>
        <Grid item xs={7}>
          <h6 style={{ textAlign: "center" }}>
            below, providing further details when the answer is yes.{" "}
          </h6>
        </Grid>
        <Grid item xs={2}></Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={2} style={{ marginTop: "10px" }}>
          <h5>Heart problems</h5>
        </Grid>
        <Grid item xs={3}>
          <div>
            <input
              type="radio"
              name="heartProblems"
              checked={heartProblems ? true : false}
              onClick={(e) => {
                setValue('heartProblems', true)
                setHeartProblems(true)

              }}

              // {...register("heartProblems")}
              style={{ marginLeft: "20px", marginTop: "12px" }}
            />{" "}
            Yes
            <input
              type="radio"
              checked={heartProblems ? false : true}
              onClick={(e) => {
                setValue("heartProblems", false);
                setHeartProblems(false);
              }}
              style={{ marginLeft: "20px" }}
            />{" "}
            No
          </div>
        </Grid>

        <Grid item xs={4} style={{ marginLeft: "-40px" }}>
          <Controller
            name="heartProblemsDetails"
            {...register("heartProblemsDetails")}
            control={control}
            render={({ field }) => (
              <TextField size="small" variant="standard" {...field} fullWidth />
            )}
          />
        </Grid>
        <Grid item xs={2}></Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={2} style={{ marginTop: "10px" }}>
          <h5>High blood pressure</h5>
        </Grid>
        <Grid item xs={3}>
          <div>
            <input
              type="radio"
              checked={highBloodP ? true : false}
              onClick={(e) => {
                console.log('checked value', e)
                setValue("highBloodPressure", true);
                setHighBloodP(true);
              }}
              style={{ marginLeft: "20px", marginTop: "16px" }}
            />{" "}
            Yes
            <input
              type="radio"
              checked={highBloodP ? false : true}
              onClick={(e) => {
                setValue("highBloodPressure", false);
                setHighBloodP(false);
              }}
              style={{ marginLeft: "20px" }}
            />{" "}
            No
          </div>





        </Grid>

        <Grid item xs={4} style={{ marginLeft: "-40px" }}>
          <Controller
            name="highBloodPressureDetails"
            {...register("highBloodPressureDetails")}
            control={control}
            render={({ field }) => (
              <TextField size="small" variant="standard" {...field} fullWidth />
            )}
          />
        </Grid>
        <Grid item xs={2}></Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={2} style={{ marginTop: "10px" }}>
          <h5>Diabetes </h5>
        </Grid>
        <Grid item xs={3}>
          <div>
            <input
              type="radio"
              checked={diabetes ? true : false}
              onClick={(e) => {
                console.log('checked value', e)
                setValue("diabetes", true);
                setDiabetes(true);
              }}
              style={{ marginLeft: "20px" }}
            />{" "}
            Yes
            <input
              type="radio"
              checked={diabetes ? false : true}
              onClick={(e) => {
                setValue("diabetes", false);
                setDiabetes(false);
              }}
              style={{ marginLeft: "20px" }}
            />{" "}
            No
          </div>
        </Grid>
        <Grid item xs={4} style={{ marginLeft: "-40px" }}>
          <Controller
            name="diabetesDetail"
            {...register("diabetesDetail")}
            control={control}
            render={({ field }) => (
              <TextField size="small" variant="standard" {...field} fullWidth />
            )}
          />
        </Grid>
        <Grid item xs={2}></Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={2} style={{ marginTop: "10px" }}>
          <h5>Thyroid syndrome</h5>
        </Grid>
        <Grid item xs={3}>
          <div>
            <input
              type="radio"
              checked={thyroidSynd ? true : false}
              onClick={(e) => {
                console.log('checked value', e)
                setValue("thyroidSyndrome", true);
                setThyroidSynd(true);
              }}
              style={{ marginLeft: "20px" }}
            />{" "}
            Yes
            <input
              type="radio"
              checked={thyroidSynd ? false : true}
              onClick={(e) => {
                setValue("thyroidSyndrome", false);
                setThyroidSynd(false);
              }}
              style={{ marginLeft: "20px" }}
            />{" "}
            No
          </div>
        </Grid>
        <Grid item xs={4} style={{ marginLeft: "-40px" }}>
          <Controller
            name="thyroidSyndromeDeatils"
            {...register("thyroidSyndromeDeatils")}
            control={control}
            render={({ field }) => (
              <TextField size="small" variant="standard" {...field} fullWidth />
            )}
          />
        </Grid>
        <Grid item xs={2} ></Grid>
        <Grid item xs={1} ></Grid>

        
        <Grid item xs={10} style={{ marginTop: "10px" }}>
          <h5>Do you take any prescribed medicines? </h5>
        </Grid>
        <Grid item xs={1}>
          {/* <div>
            <input type="radio" value="Yes" name="c5" 
            onChange={(e) => {setprescribedMedicines(e.target.value)
              console.log("Value ......." , e.target.value)}}/> Yes
            <input type="radio" value="No" name="c5" style={{marginLeft:'10px'}}
            onChange={(e) => {setprescribedMedicines(e.target.value)
              console.log("Value ......." , e.target.value)}}/> No
          </div> */}
        </Grid>
        <Grid item xs={1} ></Grid>

        <Grid item xs={10} >
          <Controller
            name="prescribedMedicinesDetails"
            {...register("prescribedMedicinesDetails")}
            control={control}
            render={({ field }) => (
              <TextField size="small" placeholder="Plesae Enter prescribed medicines" variant="standard" rows={3} multiline {...field} fullWidth />
            )}
          />
        </Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={10} style={{ marginTop: "10px" }}>
          <h5>Please list:</h5>
        </Grid>
        <Grid item xs={1} ></Grid>
        <Grid item xs={1} ></Grid>

        
        <Grid item xs={10} >
          <Controller
            name="attachList"
            {...register("attachList")}
            control={control}
            render={({ field }) => (
              <TextField size="small"  variant="standard" placeholder="Please Attach The List" multiline rows ={3} {...field} fullWidth
              InputProps={{
                endAdornment: <InputAdornment position='end'><b>(attach if needed)</b></InputAdornment>,
            
              }}
              />
            )}
          />
        </Grid>
        {/* <Grid item xs={2} style={{ marginTop: "10px" }}>
          {" "}
          <h5>(attach if needed)</h5>
        </Grid> */}
          <Grid item xs={1} ></Grid>
          <Grid item xs={1} ></Grid>

        <Grid item xs={10} style={{ marginTop: "10px" }}>
          <h5>Alcohol consumption</h5>
        </Grid>
        <Grid item xs={1} ></Grid>
        <Grid item xs={1} ></Grid>

        <Grid item xs={10} >
          <Controller
            name="alcoholConsumption"
            {...register("alcoholConsumption")}
            control={control}
            render={({ field }) => (
              <TextField size="small" variant="standard" multiline placeholder="Please Enter The Alcohol Comsuption" rows={3} {...field} fullWidth
              InputProps={{
                endAdornment: <InputAdornment position='end'><b>units/week</b></InputAdornment>,
            
              }}
              />
            )}
          />
        </Grid>
        {/* <Grid item xs={2} style={{ marginTop: "10px" }}>
          {" "}
          <h5>units/week</h5>
        </Grid> */}
        {/* <Grid item xs={1}></Grid> */}
        <Grid item xs={1} ></Grid>
        <Grid item xs={1} ></Grid>

        <Grid item xs={10} style={{ marginTop: "10px" }}>
          <h5>Smoking level</h5>
        </Grid>
        <Grid item xs={1} ></Grid>
        <Grid item xs={1} ></Grid>


        <Grid item xs={10} >
          <Controller
            name="smokingLevel"
            {...register("smokingLevel")}
            control={control}
            render={({ field }) => (
              <TextField size="small" variant="standard"  
               InputProps={{
                endAdornment: <InputAdornment position='end'><b>no/day</b></InputAdornment>,
            
              }} placeholder="Smoking Level..." multiline rows={3} {...field} fullWidth />
            )}
          />
        </Grid>
        {/* <Grid item xs={2} style={{ marginTop: "10px" }}>
          {" "}
          <h5>no/day</h5>
        </Grid> */}
        <Grid item xs={2}></Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={7} style={{ marginTop: "10px" }}>
          <h6>
            Please indicate if you have suffered from any of the conditions
            below:
          </h6>
        </Grid>
        <Grid item xs={4}></Grid>

        <Grid container style={{ marginLeft: "8px", marginBottom: "15px" }}>
          <Grid item xs={1}></Grid>
          <Grid item xs={1}>
            <Checkbox
              name="sleepiness"
              checked={sleepiness ? true : false}
              onClick={() => {
                setValue("sleepiness", !sleepiness);
                setSleepiness(!sleepiness);
              }}
            />
            {/* <Checkbox /> */}
          </Grid>
          <Grid item xs={4} style={{ marginTop: "10px", marginLeft: "-30px" }}>
            <h6> Sleepiness that interferes with daily activities</h6>
          </Grid>
          <Grid item xs={0.6}></Grid>
          <Grid item xs={1} style={{ marginLeft: "-15px" }}>
            <Checkbox
              name="excessive"
              checked={excessives ? true : false}
              onClick={() => {
                setValue("excessive", !excessives);
                setExcessives(!excessives);
              }}
            />

          </Grid>
          <Grid item xs={4} style={{ marginTop: "10px", marginLeft: "-40px" }}>
            <h6> Excessive daytime sleepiness</h6>
          </Grid>
          <Grid item xs={0.5}></Grid>
        </Grid>

        <Grid item xs={1}></Grid>
        <Grid item xs={1}>
          <Checkbox
            name="impairedCognition"
            checked={impairedCog ? true : false}
            onClick={() => {
              setValue("impairedCognition", !impairedCog);
              setImpairedCog(!impairedCog);
            }}
          />
        </Grid>
        <Grid item xs={4} style={{ marginTop: "10px" }}>
          <h6> Impaired cognition </h6>
        </Grid>
        <Grid item xs={1}>
          <Checkbox
            name="moodDisorders"
            checked={moodDisorders ? true : false}
            onClick={() => {
              setValue("moodDisorders", !moodDisorders);
              setMoodDisorders(!moodDisorders);
            }}
          />
        </Grid>
        <Grid item xs={4} style={{ marginTop: "10px" }}>
          <h6>Mood disorders</h6>
        </Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={1}>
          <Checkbox
            name="insomnia"
            checked={insomnia ? true : false}
            onClick={() => {
              setValue("insomnia", !insomnia);
              setInsomnia(!insomnia);
            }}
          />
        </Grid>
        <Grid item xs={4} style={{ marginTop: "10px" }}>
          <h6>Insomnia</h6>
        </Grid>
        <Grid item xs={1}>
          <Checkbox
            name="hypertension"
            checked={hypertension ? true : false}
            onClick={() => {
              setValue("hypertension", !hypertension);
              setHypertension(!hypertension);
            }}
          />
        </Grid>
        <Grid item xs={4} style={{ marginTop: "10px" }}>
          <h6>Hypertension</h6>
        </Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={1}>
          <Checkbox
            name="ischemicHeartDisease"
            checked={heartDisease ? true : false}
            onClick={() => {
              setValue("ischemicHeartDisease", !heartDisease);
              setHeartDisease(!heartDisease);
            }}
          />

        </Grid>
        <Grid item xs={4} style={{ marginTop: "10px" }}>
          <h6> Ischemic heart disease</h6>
        </Grid>
        <Grid item xs={1}>
          <Checkbox
            name="historyOfStroke"
            checked={historyStroke ? true : false}
            onClick={() => {
              setValue("historyOfStroke", !historyStroke);
              setHistoryStroke(!historyStroke);
            }}
          />
        </Grid>
        <Grid item xs={4} style={{ marginTop: "10px" }}>
          <h6>History of stroke</h6>
        </Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={1}>
          <Checkbox
            name="inappropriateDaytimeNapping"
            checked={daytimeNapping ? true : false}
            onClick={() => {
              setValue("inappropriateDaytimeNapping", !daytimeNapping);
              setDaytimeNapping(!daytimeNapping);
            }}
          />
        </Grid>
        <Grid item xs={7} style={{ marginTop: "10px" }}>
          <h6>
            {" "}
            Inappropriate daytime napping (e.g., during driving, conversation,or
            eating)
          </h6>
        </Grid>

        <Grid item xs={3}></Grid>

        <Grid item xs={2}></Grid>
        <Grid item xs={8} style={{ marginTop: "30px" }}>
          <h4 style={{ fontWeight: "bold", textAlign: "center" }}>
            Have you tried a Nasal CPAP  <input
              type="radio"
              checked={cpap1 ? true : false}
              onClick={(e) => {
                console.log('checked value', e)
                setValue("cpap1", true);
                setCpap1(true);
              }}
              style={{ marginLeft: "20px" }}
            />{" "}
            Yes
            <input
              type="radio"
              checked={cpap1 ? false : true}
              onClick={(e) => {
                setValue("cpap1", false);
                setCpap1(false);
              }}
              style={{ marginLeft: "20px" }}
            />{" "}
            No{" "}
            <div>
            
          </div>
          </h4>
        </Grid>
        <Grid item xs={2}></Grid>

        <Grid item xs={1}></Grid>
        <Grid item xs={3.5} style={{ marginTop: "10px" }}>
          <h6>If Yes- Are you currently using CPAP?</h6>
        </Grid>

        <Grid item xs={2} style={{ marginTop: "10px" }}>
          <div>
            <input
              type="radio"
              checked={cpap ? true : false}
              onClick={(e) => {
                console.log('checked value', e)
                setValue("cpap", true);
                setCpap(true);
              }}
              style={{ marginLeft: "20px" }}
            />{" "}
            Yes
            <input
              type="radio"
              checked={cpap ? false : true}
              onClick={(e) => {
                setValue("cpap", false);
                setCpap(false);
              }}
              style={{ marginLeft: "20px" }}
            />{" "}
            No
          </div>
        </Grid>
        <Grid item xs={0.5}></Grid>
        <Grid item xs={5} style={{ marginTop: "10px" }}>
          <h6>If you are NOT using CPAP Why? (checkbelow)</h6>
        </Grid>

        <Grid item xs={0.5}></Grid>
        <Grid item xs={0.5}></Grid>
        <Grid item xs={4.5} style={{ marginTop: "8px" }}>
          <h6>If Yes using CPAP How many days a week?</h6>
        </Grid>
        <Grid item xs={4} style={{ marginLeft: "-30px" }}>
          <Controller
            name="cpapDaysLimit"
            {...register("cpapDaysLimit")}
            control={control}
            render={({ field }) => (
              <TextField size="small" variant="standard" {...field} fullWidth />
            )}
          />
        </Grid>
        <Grid item xs={2}></Grid>
        <Grid item xs={0.5}></Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={2} >
          <Controller
            name="deviceCausing"
            {...register("deviceCausing")}
            control={control}
            render={({ field }) => (
              <TextField size="small" variant="standard" {...field} fullWidth />
            )}
          />
        </Grid>
        <Grid item xs={4.5} style={{ marginTop: "10px" }}>
          <h6>Device causing claustrophobia or panic attacks</h6>
        </Grid>
          <Grid item xs={2} style={{ marginLeft: "-30px" }}></Grid>
    <Grid item xs={2}></Grid>
        <Grid item xs={0.5}></Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={2} >
          <Controller
            name="deviceIrritated"
            {...register("deviceIrritated")}
            control={control}
            render={({ field }) => (
              <TextField size="small" variant="standard" {...field} fullWidth />
            )}
          />
        </Grid>
        <Grid item xs={6} style={{ marginTop: "10px" }}>
          <h6>
            The CPAP device irritated nasal passages causing sinus infections{" "}
          </h6>
        </Grid>
        <Grid item xs={2} style={{ marginLeft: "-30px" }}></Grid>
      
        <Grid item xs={0.5}></Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={2}>
          <Controller
            name="intestinalissues"
            {...register("intestinalissues")}
            control={control}
            render={({ field }) => (
              <TextField size="small" variant="standard" {...field} fullWidth />
            )}
          />
        </Grid>
            <Grid item xs={3} style={{ marginTop: "10px" }}>
          <h6>GI/ Stomach/Intestinal issues</h6>
        </Grid>
        <Grid item xs={2}>
         </Grid>
        <Grid item xs={0.2}></Grid>
     
       

        <Grid item xs={3}></Grid>
        <Grid item xs={0.5}></Grid>
        <Grid item xs={0.5}></Grid>
        <Grid item xs={0.5}></Grid>
        <Grid item xs={3} style={{ marginTop: "10px" }}>
          <h6>Other (state reason):</h6>
        </Grid>
        <Grid item xs={2.5} style={{ marginLeft: "-80px" }}>
          <Controller
            name="other"
            {...register("other")}
            control={control}
            render={({ field }) => (
              <TextField size="small" variant="standard" {...field} fullWidth />
            )}
          />
        </Grid>
        <Grid item xs={0.5}></Grid>
        <Grid item xs={2}>
          <Controller
            name="latexAllergy"
            {...register("latexAllergy")}
            control={control}
            render={({ field }) => (
              <TextField size="small" variant="standard" {...field} fullWidth />
            )}
          />
        </Grid>
        <Grid item xs={2.5} style={{ marginTop: "10px" }}>
          <h6>Latex allergy</h6>
        </Grid>
        {/* <Grid item xs={3}>
          <Controller
            name="latexAllergy"
            {...register("latexAllergy")}
            control={control}
            render={({ field }) => (
              <TextField size="small" variant="standard" {...field} fullWidth />
            )}
          />
        </Grid> */}
        {/* <Grid item xs={4} style={{ marginTop: "10px" }}>
          <h6>Latex allergy</h6>
        </Grid> */}

        <Grid item xs={1}></Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={1} style={{ marginTop: "10px" }}>
          <h6 style={{ fontWeight: "bold" }}>➔</h6>
        </Grid>
        <Grid item xs={2} style={{ marginTop: "10px", marginLeft: "-60px" }}>
          <h6>Previous sleep study</h6>
        </Grid>
        <Grid item xs={3} style={{ marginTop: "10px" }}>
          <div>
            <input
              type="radio"
              checked={prevSleep ? true : false}
              onClick={(e) => {
                console.log('checked value', e)
                setValue("previousSleepStudy", true);
                setPrevSleep(true);
              }}
              style={{ marginLeft: "20px" }}
            />{" "}
            Yes
            <input
              type="radio"
              checked={prevSleep ? false : true}
              onClick={(e) => {
                setValue("previousSleepStudy", false);
                setPrevSleep(false);
              }}
              style={{ marginLeft: "20px" }}
            />{" "}
            No
          </div>
        </Grid>
        <Grid item xs={2} style={{ marginLeft: "-80px" }}>
          <Controller
            name="ahiScore"
            {...register("ahiScore")}
            control={control}
            render={({ field }) => (
              <TextField size="small" variant="standard" {...field} fullWidth />
            )}
          />
        </Grid>
        <Grid item xs={4} style={{ marginTop: "10px" }}>
          <h6> If yes, note AHI score</h6>
        </Grid>
        {/* <Grid item xs={3} style={{ marginLeft: "-80px" }}>
          <Controller
            name="ahiScore"
            {...register("ahiScore")}
            control={control}
            render={({ field }) => (
              <TextField size="small" variant="standard" {...field} fullWidth />
            )}
          />
        </Grid> */}

        <Grid item xs={12} style={{ marginTop: "10px", marginLeft: "100px" }}>
          <h6>
            (If you do not know & have a copy, please provide to the front desk)
          </h6>
        </Grid>

        <Grid item xs={1}></Grid>
        <Grid item xs={1} style={{ marginTop: "10px" }}>
          <h6 style={{ fontWeight: "bold" }}>➔</h6>
        </Grid>
        <Grid item xs={5} style={{ marginTop: "10px", marginLeft: "-60px" }}>
          <h6>Have you tried an Oral Sleep appliance in the past?</h6>
        </Grid>
        <Grid item xs={3} style={{ marginTop: "10px" }}>

          <div>
            <input
              type="radio"
              checked={oralSleepAppl ? true : false}
              onClick={(e) => {
                console.log('checked value', e)
                setValue("oralSleepAppliance", true);
                setOralSleepAppl(true);
              }}
              style={{ marginLeft: "20px" }}
            />{" "}
            Yes
            <input
              type="radio"
              checked={oralSleepAppl ? false : true}
              onClick={(e) => {
                setValue("oralSleepAppliance", false);
                setOralSleepAppl(false);
              }}
              style={{ marginLeft: "20px" }}
            />{" "}
            No
          </div>
        </Grid>
        <Grid item xs={2}></Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={2} style={{ marginTop: "10px" }}>
          <h6>● Yes what year?</h6>
        </Grid>
        <Grid item xs={2} style={{ marginLeft: "-40px" }}>
          <Controller
            name="oralSleepYear"
            {...register("oralSleepYear")}
            control={control}
            render={({ field }) => (
              <TextField size="small" variant="standard" {...field} fullWidth />
            )}
          />
        </Grid>

        <Grid item xs={6}></Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={11} style={{ marginTop: "10px" }}>
          <h4> OTHER ATTEMPTED THERAPIES:</h4>
        </Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={11} style={{ marginTop: "10px" }}>
          <h6>
            {" "}
            Please comment about other therapy attempts and how each impacted
            your snoring and apnea and sleep quality
          </h6>
        </Grid>
        <Grid item xs={1}></Grid>

        <Grid item xs={10} style={{ marginTop: "30px"}}>
        <Controller
            name="comments"
            {...register("comments")}
            control={control}
            render={({ field }) => (
              <TextField size="small" variant="standard" multiline rows={2} placeholder="Comment..." {...field} fullWidth />
            )}
          />
          </Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={10} style={{ marginTop: "30px" }}>
          <h3 style={{ fontWeight: "bold", textAlign: "center" }}>
            EPWORTH SLEEPINESS SCALE - TO BE COMPLETED BY THE PATIENT
          </h3>
        </Grid>
        <Grid item xs={1} ></Grid>
        <Grid item xs={1} ></Grid>
        {/* <Grid item xs={1}></Grid> */}
        <Grid item xs={11} style={{ marginTop: "10px" }}>
          <h6>
            How likely are you to doze off or fall asleep in the following
            situations (in contrast to just feeling tired)? Even if you haven’t
            been
          </h6>
        </Grid>

        <Grid item xs={1}></Grid>
        <Grid item xs={11}>
          <h6>
            in some of these situations recently, try to work out how they may
            affect you. Choose the most appropriate number for each
          </h6>
        </Grid>

        <Grid item xs={1}></Grid>
        <Grid item xs={10}>
          <h6>situation:</h6>
        </Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={1.5}></Grid>
        <Grid item xs={2} style={{ marginTop: "10px" }}>
          <h6 style={{ fontWeight: "bold" }}>0 - NEVER doze</h6>
        </Grid>
        <Grid item xs={2} style={{ marginTop: "10px" }}>
          <h6 style={{ fontWeight: "bold" }}>1 - SLIGHT chance</h6>
        </Grid>
        <Grid item xs={2.5} style={{ marginTop: "10px" }}>
          <h6 style={{ fontWeight: "bold" }}>2 - MODERATE chance</h6>
        </Grid>
        <Grid item xs={2} style={{ marginTop: "10px" }}>
          <h6 style={{ fontWeight: "bold" }}>3 - HIGH chance</h6>
        </Grid>
        <Grid item xs={2}></Grid>

        <Grid item xs={1}></Grid>
        <Grid item xs={6} style={{ marginTop: "10px" }}>
          <h6>Sitting and reading</h6>
        </Grid>
        <Grid item xs={4} style={{ marginTop: "10px" }}>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
          >
            <div>
              <input
                type="radio"
                value="0"
                name="sittingAndReading"
                checked={sitReading === "0"}
                onClick={() => {
                  setValue("sittingAndReading", "0");
                  setSitReading("0");
                }}
                style={{
                  marginLeft: "30px",
                  transform: "scale(1.0)",
                  marginRight: "5px",
                }}
              />
              {""}
              <label style={{ fontSize: "25px", fontWeight: "normal" }}>0</label>
              <input
                type="radio"
                value="1"
                name="sittingAndReading"
                checked={sitReading === "1"}
                onClick={() => {
                  setValue("sittingAndReading", "1");
                  setSitReading("1");
                }}
                style={{
                  marginLeft: "30px",
                  transform: "scale(1.0)",
                  marginRight: "5px",
                }}
              />
              {""}
              <label style={{ fontSize: "25px", fontWeight: "normal" }}>1</label>
              <input
                type="radio"
                value="2"
                name="sittingAndReading"
                checked={sitReading === "2"}
                onClick={() => {
                  setValue("sittingAndReading", "2");
                  setSitReading("2");
                }}
                style={{
                  marginLeft: "30px",
                  transform: "scale(1.0)",
                  marginRight: "5px",
                }}
              />
              {""}
              <label style={{ fontSize: "25px", fontWeight: "normal" }}>2</label>
              <input
                type="radio"
                value="3"
                name="sittingAndReading"
                checked={sitReading === "3"}
                onClick={() => {
                  setValue("sittingAndReading", "3");
                  setSitReading("3");
                }}
                style={{
                  marginLeft: "30px",
                  transform: "scale(1.0)",
                  marginRight: "5px",
                }}
              />
              {""}
              <label style={{ fontSize: "25px", fontWeight: "normal" }}>3</label>
            </div>
          </RadioGroup>

        </Grid>
        <Grid item xs={1}></Grid>

        <Grid item xs={1}></Grid>
        <Grid item xs={6} style={{ marginTop: "10px" }}>
          <h6>Watching TV</h6>
        </Grid>
        <Grid item xs={4} style={{ marginTop: "10px" }}>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
          >
            <div>
              <input
                type="radio"
                value="0"
                name="watchingTV"
                {...register("watchingTV")}
                style={{
                  marginLeft: "30px",
                  transform: "scale(1.0)",
                  marginRight: "5px",
                }}
              />
              {""}
              <label style={{ fontSize: "25px", fontWeight: "normal" }}>
                0
              </label>
              <input
                type="radio"
                value="1"
                name="watchingTV"
                {...register("watchingTV")}
                style={{
                  marginLeft: "30px",
                  transform: "scale(1.0)",
                  marginRight: "5px",
                }}
              />
              {""}
              <label style={{ fontSize: "25px", fontWeight: "normal" }}>
                1
              </label>
              <input
                type="radio"
                value="2"
                name="watchingTV"
                {...register("watchingTV")}
                style={{
                  marginLeft: "30px",
                  transform: "scale(1.0)",
                  marginRight: "5px",
                }}
              />
              {""}
              <label style={{ fontSize: "25px", fontWeight: "normal" }}>
                2
              </label>
              <input
                type="radio"
                value="3"
                name="watchingTV"
                {...register("watchingTV")}
                style={{
                  marginLeft: "30px",
                  transform: "scale(1.0)",
                  marginRight: "5px",
                }}
              />
              {""}
              <label style={{ fontSize: "25px", fontWeight: "normal" }}>
                3
              </label>
            </div>
          </RadioGroup>
        </Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={6} style={{ marginTop: "10px" }}>
          <h6>Sitting, inactive in a public place (i.e. theatre, meeting)</h6>
        </Grid>
        <Grid item xs={4} style={{ marginTop: "10px" }}>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
          >
            <div>
              <input
                type="radio"
                value="0"
                name="sittingInactivePlace"
                {...register("sittingInactivePlace")}
                style={{
                  marginLeft: "30px",
                  transform: "scale(1.0)",
                  marginRight: "5px",
                }}
              />
              {""}
              <label style={{ fontSize: "25px", fontWeight: "normal" }}>
                0
              </label>
              <input
                type="radio"
                value="1"
                name="sittingInactivePlace"
                {...register("sittingInactivePlace")}
                style={{
                  marginLeft: "30px",
                  transform: "scale(1.0)",
                  marginRight: "5px",
                }}
              />
              {""}
              <label style={{ fontSize: "25px", fontWeight: "normal" }}>
                1
              </label>
              <input
                type="radio"
                value="2"
                name="sittingInactivePlace"
                {...register("sittingInactivePlace")}
                style={{
                  marginLeft: "30px",
                  transform: "scale(1.0)",
                  marginRight: "5px",
                }}
              />
              {""}
              <label style={{ fontSize: "25px", fontWeight: "normal" }}>
                2
              </label>
              <input
                type="radio"
                value="3"
                name="sittingInactivePlace"
                {...register("sittingInactivePlace")}
                style={{
                  marginLeft: "30px",
                  transform: "scale(1.0)",
                  marginRight: "5px",
                }}
              />
              {""}
              <label style={{ fontSize: "25px", fontWeight: "normal" }}>
                3
              </label>
            </div>
          </RadioGroup>
        </Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={6} style={{ marginTop: "10px" }}>
          <h6>As a passenger in a car for an hour, without break</h6>
        </Grid>

        <Grid item xs={4} style={{ marginTop: "10px" }}>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
          >
            <div>
              <input
                type="radio"
                value="0"
                name="passengerCar"
                {...register("passengerCar")}
                style={{
                  marginLeft: "30px",
                  transform: "scale(1.0)",
                  marginRight: "5px",
                }}
              />
              {""}
              <label style={{ fontSize: "25px", fontWeight: "normal" }}>
                0
              </label>
              <input
                type="radio"
                value="1"
                name="passengerCar"
                {...register("passengerCar")}
                style={{
                  marginLeft: "30px",
                  transform: "scale(1.0)",
                  marginRight: "5px",
                }}
              />
              {""}
              <label style={{ fontSize: "25px", fontWeight: "normal" }}>
                1
              </label>
              <input
                type="radio"
                value="2"
                name="passengerCar"
                {...register("passengerCar")}
                style={{
                  marginLeft: "30px",
                  transform: "scale(1.0)",
                  marginRight: "5px",
                }}
              />
              {""}
              <label style={{ fontSize: "25px", fontWeight: "normal" }}>
                2
              </label>
              <input
                type="radio"
                value="3"
                name="passengerCar"
                {...register("passengerCar")}
                style={{
                  marginLeft: "30px",
                  transform: "scale(1.0)",
                  marginRight: "5px",
                }}
              />
              {""}
              <label style={{ fontSize: "25px", fontWeight: "normal" }}>
                3
              </label>
            </div>
          </RadioGroup>
        </Grid>

        <Grid item xs={1}></Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={6} style={{ marginTop: "10px" }}>
          <h6>Laying to rest in the afternoon, when circumstances permit</h6>
        </Grid>

        <Grid item xs={4} style={{ marginTop: "10px" }}>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
          >
            <div>
              <input
                type="radio"
                value="0"
                name="layingToRest"
                {...register("layingToRest")}
                style={{
                  marginLeft: "30px",
                  transform: "scale(1.0)",
                  marginRight: "5px",
                }}
              />
              {""}
              <label style={{ fontSize: "25px", fontWeight: "normal" }}>
                0
              </label>
              <input
                type="radio"
                value="1"
                name="layingToRest"
                {...register("layingToRest")}
                style={{
                  marginLeft: "30px",
                  transform: "scale(1.0)",
                  marginRight: "5px",
                }}
              />
              {""}
              <label style={{ fontSize: "25px", fontWeight: "normal" }}>
                1
              </label>
              <input
                type="radio"
                value="2"
                name="layingToRest"
                {...register("layingToRest")}
                style={{
                  marginLeft: "30px",
                  transform: "scale(1.0)",
                  marginRight: "5px",
                }}
              />
              {""}
              <label style={{ fontSize: "25px", fontWeight: "normal" }}>
                2
              </label>
              <input
                type="radio"
                value="3"
                name="layingToRest"
                {...register("layingToRest")}
                style={{
                  marginLeft: "30px",
                  transform: "scale(1.0)",
                  marginRight: "5px",
                }}
              />
              {""}
              <label style={{ fontSize: "25px", fontWeight: "normal" }}>
                3
              </label>
            </div>
          </RadioGroup>
        </Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={6} style={{ marginTop: "10px" }}>
          <h6>Sitting and talking to someone</h6>
        </Grid>
        <Grid item xs={4} style={{ marginTop: "10px" }}>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
          >
            <div>
              <input
                type="radio"
                value="0"
                name="sittingAndTalking"
                {...register("sittingAndTalking")}
                style={{
                  marginLeft: "30px",
                  transform: "scale(1.0)",
                  marginRight: "5px",
                }}
              />
              {""}
              <label style={{ fontSize: "25px", fontWeight: "normal" }}>
                0
              </label>
              <input
                type="radio"
                value="1"
                name="sittingAndTalking"
                {...register("sittingAndTalking")}
                style={{
                  marginLeft: "30px",
                  transform: "scale(1.0)",
                  marginRight: "5px",
                }}
              />
              {""}
              <label style={{ fontSize: "25px", fontWeight: "normal" }}>
                1
              </label>
              <input
                type="radio"
                value="2"
                name="sittingAndTalking"
                {...register("sittingAndTalking")}
                style={{
                  marginLeft: "30px",
                  transform: "scale(1.0)",
                  marginRight: "5px",
                }}
              />
              {""}
              <label style={{ fontSize: "25px", fontWeight: "normal" }}>
                2
              </label>
              <input
                type="radio"
                value="3"
                name="sittingAndTalking"
                {...register("sittingAndTalking")}
                style={{
                  marginLeft: "30px",
                  transform: "scale(1.0)",
                  marginRight: "5px",
                }}
              />
              {""}
              <label style={{ fontSize: "25px", fontWeight: "normal" }}>
                3
              </label>
            </div>
          </RadioGroup>
        </Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={6} style={{ marginTop: "10px" }}>
          <h6>Sitting quietly after lunch when NO Alcohol has been consumed</h6>
        </Grid>
        <Grid item xs={4} style={{ marginTop: "10px" }}>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
          >
            <div>
              <input
                type="radio"
                value="0"
                name="sittingQuietly"
                {...register("sittingQuietly")}
                style={{
                  marginLeft: "30px",
                  transform: "scale(1.0)",
                  marginRight: "5px",
                }}
              />
              {""}
              <label style={{ fontSize: "25px", fontWeight: "normal" }}>
                0
              </label>
              <input
                type="radio"
                value="1"
                name="sittingQuietly"
                {...register("sittingQuietly")}
                style={{
                  marginLeft: "30px",
                  transform: "scale(1.0)",
                  marginRight: "5px",
                }}
              />
              {""}
              <label style={{ fontSize: "25px", fontWeight: "normal" }}>
                1
              </label>
              <input
                type="radio"
                value="2"
                name="sittingQuietly"
                {...register("sittingQuietly")}
                style={{
                  marginLeft: "30px",
                  transform: "scale(1.0)",
                  marginRight: "5px",
                }}
              />
              {""}
              <label style={{ fontSize: "25px", fontWeight: "normal" }}>
                2
              </label>
              <input
                type="radio"
                value="3"
                name="sittingQuietly"
                {...register("sittingQuietly")}
                style={{
                  marginLeft: "30px",
                  transform: "scale(1.0)",
                  marginRight: "5px",
                }}
              />
              {""}
              <label style={{ fontSize: "25px", fontWeight: "normal" }}>
                3
              </label>
            </div>
          </RadioGroup>
        </Grid>
        <Grid item xs={1}></Grid>
        {/* <Grid item xs={1}></Grid>
        <Grid item xs={6} style={{ marginTop: "10px" }}>
          <h6>has been consumed</h6>
        </Grid>
        <Grid item xs={4} style={{ marginTop: "10px" }}>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
          >
            <div>
              <input
                type="radio"
                value="0"
                name="consumed"
                {...register("consumed")}
                style={{
                  marginLeft: "30px",
                  transform: "scale(1.0)",
                  marginRight: "5px",
                }}
              />
              {""}
              <label style={{ fontSize: "25px", fontWeight: "normal" }}>
                0
              </label>
              <input
                type="radio"
                value="1"
                name="consumed"
                {...register("consumed")}
                style={{
                  marginLeft: "30px",
                  transform: "scale(1.0)",
                  marginRight: "5px",
                }}
              />
              {""}
              <label style={{ fontSize: "25px", fontWeight: "normal" }}>
                1
              </label>
              <input
                type="radio"
                value="2"
                name="consumed"
                {...register("consumed")}
                style={{
                  marginLeft: "30px",
                  transform: "scale(1.0)",
                  marginRight: "5px",
                }}
              />
              {""}
              <label style={{ fontSize: "25px", fontWeight: "normal" }}>
                2
              </label>
              <input
                type="radio"
                value="3"
                name="consumed"
                {...register("consumed")}
                style={{
                  marginLeft: "30px",
                  transform: "scale(1.0)",
                  marginRight: "5px",
                }}
              />
              {""}
              <label style={{ fontSize: "25px", fontWeight: "normal" }}>
                3
              </label>
            </div>
          </RadioGroup>
        </Grid> */}
        {/* <Grid item xs={1}></Grid> */}
        <Grid item xs={1}></Grid>
        <Grid item xs={6} style={{ marginTop: "10px" }}>
          <h6>In a car, stationary for a few minutes in traffic</h6>
        </Grid>

        <Grid item xs={4} style={{ marginTop: "10px" }}>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
          >
            <div>
              <input
                type="radio"
                value="0"
                name="carStationary"
                {...register("carStationary")}
                style={{
                  marginLeft: "30px",
                  transform: "scale(1.0)",
                  marginRight: "5px",
                }}
              />
              {""}
              <label style={{ fontSize: "25px", fontWeight: "normal" }}>
                0
              </label>
              <input
                type="radio"
                value="1"
                name="carStationary"
                {...register("carStationary")}
                style={{
                  marginLeft: "30px",
                  transform: "scale(1.0)",
                  marginRight: "5px",
                }}
              />
              {""}
              <label style={{ fontSize: "25px", fontWeight: "normal" }}>
                1
              </label>
              <input
                type="radio"
                value="2"
                name="carStationary"
                {...register("carStationary")}
                style={{
                  marginLeft: "30px",
                  transform: "scale(1.0)",
                  marginRight: "5px",
                }}
              />
              {""}
              <label style={{ fontSize: "25px", fontWeight: "normal" }}>
                2
              </label>
              <input
                type="radio"
                value="3"
                name="carStationary"
                {...register("carStationary")}
                style={{
                  marginLeft: "30px",
                  transform: "scale(1.0)",
                  marginRight: "5px",
                }}
              />
              {""}
              <label style={{ fontSize: "25px", fontWeight: "normal" }}>
                3
              </label>
            </div>
          </RadioGroup>
        </Grid>
        <Grid item xs={1}></Grid>

        <Grid item xs={1}></Grid>
        <Grid item xs={1.5} style={{ marginTop: "20px" }}>
          <h6 style={{ fontWeight: "bold" }}>Total (0-24)</h6>
        </Grid>
        <Grid item xs={2}>
       <TextField size="small" variant="standard" value={Total}  fullWidth />
        </Grid>
        <Grid item xs={7.5}></Grid>
        <Grid item xs={2}></Grid>
        <Grid item xs={2} style={{ marginTop: "30px" }}>
          <h6 style={{ fontWeight: "bold" }}>Patient Signature:</h6>
        </Grid>
        <Grid item xs={3} style={{ marginLeft: "-30px", marginTop: '15px' }}>
          <Controller
            name="patientSignature"
            {...register("patientSignature")}
            control={control}
            render={({ field }) => (
              <TextField size="small" variant="standard" {...field} fullWidth />
            )}
          />
        </Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={1} style={{ marginTop: "30px" }}>
          <h6 style={{ fontWeight: "bold" }}>Date:</h6>
        </Grid>
        <Grid item xs={3} style={{ marginLeft: "-40px",marginTop: '15px' }}>
          <Controller

            name="sleepinessDate"
            {...register("sleepinessDate")}
            control={control}
            render={({ field }) => (
              <TextField
                variant="standard"
                style={{
                  width: "75%",
                }}
                size="small"
                fullWidth
                {...field}
                name="dob"
                type="date"
                value={datee}
                onChange={e => {
                  setValue('sleepinessDate', e.target.value)
                  setDatee(e.target.value)
                }}
              />
            )}
          />
        </Grid>
          <Grid item xs={12} lg={12} sm={12}>
            <Button 
             className="Button"
              onClick={handleSubmit(onSubmit)}
              variant="gradient"
              disabled={submitting}
              style={{
                marginTop: "0px",
                marginBottom: "10px",
                float: "right",
                marginLeft: "auto",
                width: "80px",
                height: "35px",
                backgroundColor: "#A574FD",
                color: "white",
                fontSize: "14px",
              }}
            >
              Save
            </Button>
          </Grid>
      
      </Grid>
    </Container>
  );
};

export default OSAPAGE;
