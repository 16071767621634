import { useState, useEffect, useRef } from "react";
import React from "react";
import axios from "axios";
import { Controller, useForm } from "react-hook-form";
import 'react-confirm-alert/src/react-confirm-alert.css'
import CircularProgress from '@mui/material/CircularProgress'
import Swal from 'sweetalert2';
import {
    Box,
    Button,
    Grid,
    Typography,
    IconButton,
    TextField,
    InputLabel,
} from "@mui/material";
import Paper from '@mui/material/Paper'
import ReactSelect from 'react-select'
import DeleteIcon from '@mui/icons-material/Delete'
import { confirmAlert } from 'react-confirm-alert' // Import
import Container from '@mui/material/Container'
import { DataGrid } from "@mui/x-data-grid";
import { useAlert } from "react-alert";
import Headroom from "react-headroom";
import DialogTitle from '@mui/material/DialogTitle';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import Modal from '@mui/material/Modal';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import HandshakeIcon from '@mui/icons-material/Handshake';
import Moment from 'moment'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; 
const Template = props => {
    document.title = "Template | LuxBilling";
    const fields = [
        "id",
        "patientId",
        "practiceId",
        "providerId",
        "inActive",
        "createdDate",
        "updatedDate",
        "createdBy",
        "updatedBy",
        "name",
        "text",
        "istemplate",
        "userId",
        "therapistId",
        "supervisorId"
    ];
    const defValues = {
        id: 0,
        patientId: 0,
        practiceId: 0,
        providerId: 0,
        inActive: false,
        createdDate: '',
        updatedDate: '',
        createdBy: '',
        updatedBy: '',
        name: '',
        text: '',
        istemplate: false,
        userId: 0,
        therapistId: 0,
        supervisorId: 0
    };
    const alert = useAlert();
    const [submitting, setSubmitting] = useState(false);
    const token = localStorage.getItem('Token')
    const auth = `Bearer ${token}`
    const headers = {
        Authorization: auth
    }
    const modules = {
        toolbar: [
          ['bold', 'italic', 'underline'], // Formatting options
          [{ list: 'ordered' }, { list: 'bullet' }], // Lists
          [{ align: [] }], // Alignment
          ['link', 'image'], // Links and Images
        ],
      };
      
      const formats = [
        'bold',
        'italic',
        'underline',
        'list',
        'bullet',
        'align',
        'link',
        'image',
      ];
      
    const {
        register,
        control,
        handleSubmit,
        getValues,
        reset,
        setValue,
        watch,
        formState: { errors }
    } = useForm({
        mode: "onBlur",
        reValidateMode: "onChange",
        context: undefined,
        criteriaMode: "firstError",
        shouldFocusError: true,
        shouldUnregister: false,
        shouldUseNativeValidation: false,
        delayError: undefined,
        // resolver: yupResolver(validationSchema),
        defaultValues: defValues
    });

    const [refresh, setRefresh] = useState(false)
    const [ApiCall, setApiCall] = useState(false)
    const [rows, setRows] = useState(null)
    const [isLoading, setLoading] = useState(false)
    const [OpenModal, setOpenModal] = React.useState(false);
    const [rowId, setRowID] = React.useState(0);
    const HandleOpen = () => {
        setOpenModal(true)
    };
    const HandleCloseModal = () => {
        setOpenModal(false)
        fields.forEach((field) => setValue(field, ''));
        reset();
        setRowID(0);

    };

    useEffect(() => {
        setLoading(true)
        axios
            .get(`${process.env.REACT_APP_API_URL}/Template/getTemplateById`, {
                headers
            })
            .then(response => {
                setRows(response)
                setLoading(false)
            })
            .catch(error => { });
    }, [ApiCall]);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        // padding: '14px',
        transform: 'translate(-50%, -50%)',
        height: 370,
        width: '50%',
        bgcolor: 'white',
        border: '2px solid black',
        boxShadow: 24,
        borderRadius: '8px',
        // overflowY: 'auto'
    }

    const handleSubmit1 = (data) => {
        let postData = {
            ...data,
            id: rowId,
        };

        axios
            .post(`${process.env.REACT_APP_API_URL}/Template/AddTemplate`, postData, { headers })
            .then((response) => {
                setSubmitting && setSubmitting(false);
                setRefresh(true)
                setApiCall(!ApiCall)
                HandleCloseModal();

                Swal.fire({
                    title: 'Success!',
                    text: 'Template Added Successfully!',
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 2000,
                    timerProgressBar: true,
                });
            })
            .catch((error) => {
                setSubmitting && setSubmitting(false);
                console.error('Error submitting data:', error);

                Swal.fire({
                    title: 'Error!',
                    text: 'Something went wrong. Please try again.',
                    icon: 'error',
                    showConfirmButton: true,
                });
            });
    };
    const handleEditorChange = (content) => {
        const sanitizedContent = content.replace(/<\/?[^>]+(>|$)/g, "");
        setValue('text', sanitizedContent);
      };

    return (
        <div className="page-content">
            <Container >
                <Modal
                    open={OpenModal}
                    onClose={HandleCloseModal}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <Headroom>
                            <Grid container style={{ backgroundColor: '#356F60', position: 'sticky' }}>
                                <Grid item xs={12}>
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'space-between',
                                            height: '3rem',
                                            padding: '8px',
                                        }}
                                    >
                                        <DialogTitle style={{ flex: 1 }}>
                                            <h1
                                                style={{
                                                    fontWeight: 'bold',
                                                    margin: 0,
                                                    color: '#fff',
                                                    textDecoration: 'underline',
                                                    fontSize: '1.2rem',
                                                }}
                                            >
                                                Add Template
                                            </h1>
                                        </DialogTitle>
                                        <IconButton
                                            onClick={HandleCloseModal}
                                            style={{
                                                color: 'red',
                                                backgroundColor: 'rgba(255, 77, 77, 0.1)',
                                                borderRadius: '50%',
                                                transition: 'all 0.3s ease',
                                            }}
                                        >
                                            <CancelOutlinedIcon fontSize="medium" />
                                        </IconButton>
                                    </div>
                                </Grid>
                            </Grid>
                        </Headroom>
                        <form onSubmit={handleSubmit(handleSubmit1)}>
                            <Grid
                                container
                                spacing={2}
                                style={{
                                    padding: '20px',
                                    maxHeight: 290,
                                    overflowY: 'auto',
                                    color: '#000',
                                }}
                            >
                                <Grid item xs={12} >
                                    <InputLabel>Name</InputLabel>
                                    <Controller
                                        name="name"
                                        control={control}
                                        rules={{ required: 'Full Name is required' }}
                                        render={({ field }) => (
                                            <TextField
                                                size="small"
                                                placeholder="Name"
                                                {...field}
                                                fullWidth
                                                error={!!errors.name}
                                                helperText={errors.name?.message}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <InputLabel>Text</InputLabel>
                                    <Controller
                                        name="text"
                                        control={control}
                                        rules={{ required: 'Text is required' }}
                                        render={({ field }) => (
                                            <>
                                                <ReactQuill
                                                    // {...field}
                                                    theme="snow"
                                                    modules={modules}
                                                    formats={formats}
                                                    onChange={handleEditorChange}
                                                    style={{
                                                        backgroundColor: 'white',
                                                        border: errors.text ? '1px solid red' : '1px solid #ced4da',
                                                        borderRadius: '5px',
                                                        minHeight: '120px',
                                                        maxHeight: '250px',
                                                        overflowY: 'auto',
                                                    }}
                                                />
                                                {errors.text && (
                                                    <p
                                                        style={{
                                                            color: 'red',
                                                            fontSize: '12px',
                                                            marginTop: '4px',
                                                            marginLeft: '2px',
                                                        }}
                                                    >
                                                        {errors.text.message}
                                                    </p>
                                                )}
                                            </>
                                        )}
                                    />
                                </Grid>

                                <Grid item xs={6} style={{ marginTop: '10px' }}>
                                    <Button
                                        className="Button"
                                        fullWidth
                                        type="submit"
                                        style={{
                                            borderRadius: '10px',
                                            color: 'white',
                                            height: '40px',
                                            backgroundColor: '#28a745',
                                            fontSize: '14px',
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        Save
                                    </Button>
                                </Grid>
                                <Grid item xs={6} style={{ marginTop: '10px' }}>
                                    <Button
                                        className="Button"
                                        onClick={HandleCloseModal}
                                        fullWidth
                                        style={{
                                            borderRadius: '10px',
                                            color: 'white',
                                            height: '40px',
                                            backgroundColor: '#dc3545',
                                            fontSize: '14px',
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        Cancel
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </Box>
                </Modal>

                <Grid item xs={12} >
                    <Button
                        className="Button"
                        variant="contained"
                        onClick={HandleOpen}
                        style={{
                            width: '140px',
                            height: '35px',
                            marginTop: '25px',
                            fontSize: '14px',
                            color: 'white',
                            margin: '10px'
                        }}
                    >
                        <AddOutlinedIcon
                            fontSize='medium'
                            style={{ color: 'white', paddingBottom: '1px' }}
                        ></AddOutlinedIcon>
                        Add new
                    </Button>
                </Grid>
                <Grid item xs={12} >
                    <Paper
                        elevation={4}
                        style={{
                            padding: '20px',
                            backgroundColor: '#f5f5f5',
                            borderRadius: '8px',
                            marginBottom: '16px',
                            width: '100%',
                            margin: '10px auto',
                            border: '2px solid #326C1D',
                        }}
                    >

                        {isLoading ? (
                            <div style={{ margin: '5rem', textAlign: 'center' }}>
                                <CircularProgress />
                            </div>
                        ) : rows && rows.length > 0 ? (
                            rows.map((s, index) => (
                                <>
                                    <Grid
                                        container
                                        key={s.id}
                                        style={{
                                            padding: '20px',
                                            backgroundColor: '#f5f5f5',
                                            borderRadius: '8px',
                                            marginBottom: '16px',
                                            width: '100%',
                                            margin: '10px auto',
                                            border: '2px solid #326C1D',
                                        }}
                                    >
                                        <Grid item xs={12}>
                                            <DeleteIcon
                                                style={{
                                                    // width: '20px',
                                                    // height: '35px',
                                                    // marginTop: '25px',
                                                    fontSize: '22px',
                                                    color: 'red',
                                                    // margin: '10px',
                                                    float: 'right',
                                                    cursor: 'pointer'
                                                }}
                                                onClick={cellValues => {
                                                    confirmAlert({
                                                        message: 'Do you want to delete the record.',
                                                        closeOnEscape: true,
                                                        closeOnClickOutside: true,
                                                        overlayClassName: 'overlay-custom-class-name',
                                                        buttons: [
                                                            {
                                                                label: 'Yes',
                                                                onClick: () => {
                                                                    const postData = {
                                                                        inActive: true,
                                                                        id: s.id,
                                                                    }
                                                                    axios
                                                                        .post(`${process.env.REACT_APP_API_URL}/Template/AddTemplate`, postData, {
                                                                            headers
                                                                        })
                                                                        .then(response => {
                                                                            setRefresh(!refresh)
                                                                            setApiCall(!ApiCall)

                                                                        })
                                                                        .catch(error => {
                                                                            console.error('There was an error!', error)
                                                                        })
                                                                }
                                                            },
                                                            {
                                                                label: 'No'
                                                            }
                                                        ]
                                                    })
                                                }}
                                            ></DeleteIcon>
                                        </Grid>
                                        <Grid item xs={10}>
                                            <Typography variant="h5" style={{ padding: '10px' }}>
                                                {s.name}
                                            </Typography>
                                        </Grid>

                                        <Grid item xs={2}>
                                            <Button
                                                color="primary"
                                                variant="contained"
                                                className="Button"
                                                style={{
                                                    width: '160px',
                                                    height: '35px',
                                                    marginTop: '25px',
                                                    fontSize: '14px',
                                                    color: 'white',
                                                    margin: '10px',
                                                    float: 'right',
                                                }}
                                                onClick={() => {
                                                    // Append an empty text to the comma-separated string
                                                    s.text = s.text ? `${s.text},` : '';
                                                    setRefresh(!refresh); // Force re-render
                                                }}
                                            >
                                                New Template
                                            </Button>
                                        </Grid>

                                        {s.text.split(',').map((text, textIndex) => (
                                            <>
                                                <Grid item xs={0.5}>
                                                    <Box
                                                        style={{
                                                            width: '28px',
                                                            height: '28px',
                                                            backgroundColor: '#E9E9EE',
                                                            borderRadius: '50%',
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                        }}
                                                    >
                                                        <Typography>{textIndex + 1}</Typography>
                                                    </Box>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={10.5}
                                                    margin={'-5px 10px 0px 10px'}
                                                    key={`${s.id}-${textIndex}`}
                                                >
                                                    <TextField
                                                        variant="standard"
                                                        required
                                                        inputProps={{
                                                            style: {
                                                                borderBottom: '1px solid #DCDBDD',
                                                                borderTop: 'none',
                                                                borderLeft: 'none',
                                                                borderRight: 'none',
                                                            },
                                                        }}
                                                        defaultValue={text.trim()}
                                                        onChange={(e) => {
                                                            const textArray = s.text.split(',').map((t, idx) =>
                                                                idx === textIndex ? e.target.value : t
                                                            );
                                                            s.text = textArray.join(','); // Update the text field
                                                            console.log('Updated text:', s.text);
                                                        }}
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid item xs={0.5}>
                                                    <DeleteIcon
                                                        style={{
                                                            float: 'right',
                                                            marginTop: '5px',
                                                            color: 'red',
                                                            cursor: 'pointer',
                                                        }}
                                                        onClick={() => {
                                                            confirmAlert({
                                                                message: 'Do you want to delete the record?',
                                                                closeOnEscape: true,
                                                                closeOnClickOutside: true,
                                                                overlayClassName: 'overlay-custom-class-name',
                                                                buttons: [
                                                                    {
                                                                        label: 'Yes',
                                                                        onClick: () => {
                                                                            const textArray = s.text
                                                                                .split(',')
                                                                                .filter((_, idx) => idx !== textIndex); // Exclude the selected text
                                                                            s.text = textArray.join(','); // Update the `text` property
                                                                            console.log('Updated text after deletion:', s.text)
                                                                            const postData = {
                                                                                id: s.id,
                                                                                text: s.text,
                                                                                name: s.name
                                                                            };
                                                                            axios
                                                                                .post(
                                                                                    `${process.env.REACT_APP_API_URL}/Template/AddTemplate`,
                                                                                    postData,
                                                                                    { headers }
                                                                                )
                                                                                .then((response) => {
                                                                                    setRefresh(!refresh);
                                                                                    setApiCall(!ApiCall)
                                                                                })
                                                                                .catch((error) => {
                                                                                    console.error('There was an error!', error);
                                                                                });
                                                                        },
                                                                    },
                                                                    {
                                                                        label: 'No',
                                                                    },
                                                                ],
                                                            });
                                                        }}
                                                    />
                                                </Grid>
                                            </>
                                        ))}

                                        {/* Save Button */}
                                        <Grid item xs={12} style={{ marginTop: '10px', textAlign: 'right' }}>
                                            <Button
                                                color="primary"
                                                variant="contained"
                                                className="Button"

                                                style={{
                                                    fontSize: '14px',
                                                    color: 'white',
                                                }}
                                                onClick={() => {
                                                    // Prepare data to save
                                                    const postData = {
                                                        id: s.id,
                                                        text: s.text,
                                                        name: s.name
                                                    };
                                                    axios
                                                        .post(
                                                            `${process.env.REACT_APP_API_URL}/Template/AddTemplate`,
                                                            postData,
                                                            { headers }
                                                        )
                                                        .then((response) => {
                                                            Swal.fire({
                                                                title: 'Success!',
                                                                text: 'Template Added Successfully!',
                                                                icon: 'success',
                                                                showConfirmButton: false,
                                                                timer: 2000,
                                                                timerProgressBar: true,
                                                            });
                                                            setApiCall(!ApiCall)
                                                        })
                                                        .catch((error) => {
                                                            console.error('Save failed:', error);
                                                            alert('Error saving data.');
                                                        });
                                                }}
                                            >
                                                Save
                                            </Button>
                                        </Grid>


                                    </Grid>
                                </>
                            ))
                        ) : (
                            <div style={{ margin: '5rem', textAlign: 'center' }}>
                                <Typography variant="h6">No Records Found</Typography>
                            </div>
                        )}
                    </Paper>
                </Grid>

            </Container>
        </div>

    );
};
export default Template;
