import { useState, useEffect } from 'react'
import Container from '@mui/material/Container'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import TextareaAutosize from '@mui/base/TextareaAutosize'
import {
  Autocomplete, Tooltip,
} from "@mui/material";
import React from 'react'
import Button from '@mui/material/Button'
import axios from 'axios'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import { Controller, useForm } from 'react-hook-form'
// import 'cleave.js/dist/addons/cleave-phone.us'
import InputMask from 'react-input-mask'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import Modal from '@mui/material/Modal';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { useNavigate } from "react-router-dom";
import ReactSelect from 'react-select'
// import { useRouter, withRouter } from 'next/router'
import Avatar from '@mui/material/Avatar'
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined'
import AddIcon from '@mui/icons-material/Add';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { useAlert } from 'react-alert'
import Moment from 'moment'
import Box from '@mui/material/Box'
import DeleteIcon from '@mui/icons-material/Delete'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import Documents from './Documents'
import OSAPAGE from './Osapage'
import VOBPAGE1 from './Vobpage1'
import Prior_auth from './Prior_auth'
import PatientRegistration from './PatientRegistration'
import Demographics from './Demographics'
import "./Button.css"
//import { confirmAlert } from 'react-confirm-alert'
import Headroom from "react-headroom";
import Card from '@mui/material/Card';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CircularProgress from "@mui/material/CircularProgress";
import DialogTitle from '@mui/material/DialogTitle';
import Letter from './Letter'
import Checkbox from '@mui/material/Checkbox';
import { ArrowDropDown, ArrowDropDownCircle, FamilyRestroomRounded, ImportExport } from '@mui/icons-material'
import Popover from '@mui/material/Popover';
import Disclaimer from './Disclaimer'
import { values } from 'lodash'
import DentistUse from './DentistUse'
// import { co } from '@fullcalendar/core/internal-common'




// const validationSchema = Yup.object({
//   patientName: Yup.string().required("Please Select Patient Name"),
// });
const AuthorizationRegistration = (props) => {
  document.title = "Authorization Registration | LuxBilling";
console.log(props,'ik Authorizationy')
  const token = localStorage.getItem('Token')
  const auth = `Bearer ${token}`
  const headers = {
    Authorization: auth
  }


  const alert = useAlert();
  const dashID = props.dashID
  // console.log('dashID', dashID)
  const [columnID, setcolumnID] = useState(props.callingFrom == "dashboard" ? props.dashID : props.columnID)
  const patientName = props.callingFrom == 'dashboard' ? props.Dashboard : props.patientName;
  // console.log('patientName___2', patientName)
  const [historyid, sethistoryid] = useState(props.columnID)
  const [questionnaireid, setquestionnaireid] = useState(props.columnID)
  const [processnoteid, setprocessnoteid] = useState(props.columnID)
  const [authRequestid, setauthRequestid] = useState(props.columnID)
  const [approveid, setapproveid] = useState(props.columnID)
  const [history, sethistory] = useState(false)
  const [dentalExam, setdentalExam] = useState(false)

  const [additionalinfo, setAdditionalinfo] = useState(false)

  const [idcard, setIdcard] = useState(false)
  const [sleeptest, setsleeptest] = useState(false)
  const [authRequest, setauthRequest] = useState(false)
  console.log('authRequest', authRequest)
  const [processnote, setprocessnote] = useState(false)
  const [newdisclaimer, setnewdisclaimer] = useState(false)
  const [newdentist, setnewdentist] = useState(false)

  
  const [lomn, setLomn] = useState(false)
  const [questionnaire, setquestionnaire] = useState(false)
  const [approve, setapprove] = useState(false)
  const [type, settype] = useState('')
  const pName = props.pName
  const fields = [
    "id",
    "dos",
    "pos",
    "patientId",
    "patientInfo",
    "healthHistory",
    "questionnaire",
    "progressNotes",
    "authRequest",
    "approved",
    "patientName",
    "lomn",
    "sleepTest",
    "disclaimer",
    "idCard",
    "additionalInfo",
    "inactive",
    "practiceId",
    "approval",
    "submission",
    "dentalExam"
   
  ];
  const defValues = {
    id: 0,
    dos: "",
    patientId: "",
    pos:"",
    patientInfo: "",
    practiceId:"",
    healthHistory: "",
    dentalExam:"",
    questionnaire: "",
    progressNotes: "",
    authRequest: "",
    approved: "",
    lomn: "",
    sleepTest:"",
    patientName: '',
    disclaimer:"",
    idCard:"",
    additionalInfo:"",
    patientName: patientName,
    inactive:false,
    submission:"",
    approval:"",

  };
  const navigate = useNavigate();

  // console.log('column',AutpattientID)
  // console.log('column', columnID)
  const [filteredOptions, setFilteredOptions] = useState([]);
  //   const [columnID, setColumnID] = React.useState("");
  const [refresh, setRefresh] = useState(false);
  const [usertype, setUsertype] = useState(localStorage.getItem('User_Type'))
  const [patientData, setPatientData] = React.useState([])
  const [patient, setPatient] = React.useState(false)
  const [patientSelected, setPatientSelected] = React.useState(false)
  const [patientID, setPatientID] = React.useState(props.patientId)
  console.log('patientIdid',patientID )
  const [disable, setdisable] = React.useState(true)
  const [openDetails, setOpenDetails] = React.useState(false)
  const [searchText, setSearchText] = useState('')
  const [patientNamee, setPatientNamee] = useState(patientName)
  const [isLoading, setIsLoading] = useState(false); 
  console.log('searchText', patientNamee)
  const [selectedPatient, setSelectedPatient] = useState('');
  const [selectedValueId, setselectedValueId] = useState(false);
  const [patientInfo, setpatientInfo] = useState(false);

  
  console.log('selectedPatient', selectedPatient)
  const [submitting, setSubmitting] = useState(false);
  const PatientStyle = {
    cursor: 'pointer',
    hover: {
      background: '#3b3b3b !important',
      color: 'rgba(5, 152, 236, 0.637) !important'
    },
    color: 'green',
    selection: {
      background: '#D7624E',
      color: 'white'
    }
  }

  const {
    register,
    control,
    handleSubmit,
    setValue,
    reset,
    form,
    getValues,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: false,
    shouldUseNativeValidation: false,
    delayError: undefined,
    // resolver: yupResolver(validationSchema),
    defaultValues: defValues,
  });

  // API's Calling
  useEffect(() => {
    
    if (patientSelected) {
      setselectedValueId(true);
    } else {
      setselectedValueId(false); // Set to false when patientSelected is false
    }
  }, [patientName]);
  const FormCall = props.FormCall
  console.log('This is Awesom.....', FormCall)
  const onChangeHandle = async value => {
    // this default api does not support searching but if you use google maps or some other use the value and post to get back you reslut and then set it using setOptions
    setIsLoading(true);
    console.log(value)
    const sendData = {
      id: 0,
      lastName: value,
      firstName: '',
      ssn: '',
      status: '',
      cellPhone: '',
      address: '',
      dob: '',
      patientId:'',
    }
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/Patients/searchPatient`,
        sendData,
        { headers },
        setPatientSelected(false)
      )
      .then(response => {
        setPatientData(response)
        // setResId(response.id)
        console.log(response,'(response.id)')
        setPatient(true)
        setIsLoading(false);
      })
      .catch(error => {
        console.error('There was an error!', error)
        setIsLoading(false);
      })

  }
  useEffect(() => {
    if (props.patientId > 0 || props.AutpattientID > 0) {
      setdisable(false)
    }
    
  }, [])
  useEffect(() => {
    if (columnID > 0) {

      axios
        .get(
          `${process.env.REACT_APP_API_URL}/AutherizationRequest/AuthoorizationById?Id=${columnID}`,
          { headers }
        )
        .then(response => {
          console.log('response.datas patientName: ', response.patientName)
          fields.forEach(field => {
            setValue(field, response[field])
          })
          setPatientID(response.patientId)
          setauthRequest(response.authRequest)
          setquestionnaire(response.questionnaire)
          setapprove(response.approved)
          sethistory(response.healthHistory)
          // sethistory(response.dentalExam)
          // setselectedValueId(response.patientInfo)
          setIdcard(response.idCard)
          setAdditionalinfo(response.additionalInfo)
          setsleeptest(response.sleepTest)
          setprocessnote(response.progressNotes)
          setnewdisclaimer(response.disclaimer)
          setnewdentist(response.dentalExam)
          setLomn(response.lomn)
          setdisable(false)
        })

        .catch(error => {
          console.error('There was an error!', error)
        })
    }
    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, [columnID])
  const [PatientNameError, setPatientNameError] = React.useState(false)
  const [FirstClick, setFirstClick] = React.useState(false)

  const onSubmit = (data) => {
    // setSubmitting(true);
    setdisable(false)
    setFirstClick(true)
    const postData = data;
    if(authRequest == true){
      postData.submission = true;
      postData.approval = true;
    }
    if (columnID <= 0) {
      if(FormCall == 'demographic'){
        setPatientNameError(false)
      }else if(!patientSelected){
      setPatientNameError(true)
      return;
    }
  }
     postData.patientId = patientID;
     postData.patientInfo = selectedValueId
    console.log("Data: ", postData);
    postData.practiceId=localStorage.getItem('default_paractice')
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/AutherizationRequest/AddAuthorization?patientId=${patientID}`,
        postData,
        { headers }
      )

      .then((response) => {
        if (response.id) {
          setdisable(true)
          // applyRefresh()
          alert.success("Record saved successfully.", {
            type: "success",
           
            onClose: () => {
              setdisable(false)
              setFirstClick(true)
              if(autoClosePage == false)
              {
                console.log("tesssst",autoClosePage)
                setdisable(true)
                props.CloseModal()
                // setdisable(true)
              }
              
             
             
            },
          });
        }
        else if (props.callingFrom == "Apporved") {
          props.CloseModal()
        }
        
        else if (props.callingFrom == "dashboard") {
          props.CloseModal()
        }
        
      })
      .catch((error) => {
        setdisable(true);
        setFirstClick(false)
        alert.success(`Error ${error.message}`, {
          type: "error",
        });
      });
  };
  // Styling
  const handleOptionChange = (event, value) => {
    setSelectedPatient(value);
    console.log("Selected ID:", value.id);
    setPatientID(value.id);
  };
  const handleInputChange = (event, newValue) => {
    const trimmedValue = newValue.trim();
    if (trimmedValue.length > 0) {
      onChangeHandle(trimmedValue);
    }
  };

  const [openDocument, setOpenDocument] = React.useState(false);
  const handleCloseDocument = () => {
    setOpenDocument(false)};
  const handleOpen = () => {
    setautoClosePage(true)
    setOpenDocument(true)};
  const handleClose = () => {
    console.log('test___________')
    setautoClosePage(false)
    handleSubmit(onSubmit)();
    setOpenDocument(false)};

  const [open1, setOpen1] = React.useState(false);
  const handleCloseOSA = () => {
    setOpen1(false)};
    const handleOpen1 = () => {
      setautoClosePage(true)
      setOpen1(true)};
    const handleClose1 = () => {
      setautoClosePage(false)
      handleSubmit(onSubmit)();
      setOpen1(false)};
  const [autoClosePage, setautoClosePage] = React.useState(true);
  
  const [open2, setOpen2] = React.useState(false);
  const handleOpen2 = () => {
    setautoClosePage(true)
    setOpen2(true)
  };
  const handleClose22 = () => {
    setautoClosePage(false)
    setOpen2(false)
  };
  const handleClose2 = () => {
    setautoClosePage(false)
    handleSubmit(onSubmit)();
    setOpen2(false)
  };

  const [open3, setOpen3] = React.useState(false);
  const handleCloseVOB = () => {
    setOpen3(false)
  };

  const handleOpen3 = () => {
    setautoClosePage(true)
    setOpen3(true)};
  const handleClose3 = () => {
    console.log('test___________12')
    setautoClosePage(false)
    handleSubmit(onSubmit)();
    setOpen3(false)
  };
  const [open4, setOpen4] = React.useState(false);
  const handleOpen4 = () => setOpen4(true);
  const handleClose4 = () => setOpen4(false);
  const [openpatientreg, setOpenpatientreg] = React.useState(false);
  const handleOpenpatientreg = () => setOpenpatientreg(true);
  const handleClosepatientreg = () => setOpenpatientreg(false);
  const [openpatientdem, setOpenpatientdem] = React.useState(false);
  const handleOpenpatientdem = () => setOpenpatientdem(true);
  const handleClosepatientdem = () => setOpenpatientdem(false);
  const [disclaimer, setDisclaimer] = React.useState(false)
  const opendisclaimer = () => {
    setautoClosePage(true)
    setDisclaimer(true)};
  const closedisclaimer = () => {
    setautoClosePage(false)
    handleSubmit(onSubmit)();
    setDisclaimer(false)};
    const closedisclaimer1= () => {
      
      setDisclaimer(false)};

      const [dentist, setDentist] = React.useState(false)
      const opendentist = () => {
        setautoClosePage(true)
        setDentist(true)};
        const closedentist1 = () => {
          setautoClosePage(false)
          setDentist(false)};
        const closedentist = () => {
          setautoClosePage(false)
          handleSubmit(onSubmit)();
          setDentist(false)};
  
  const checkstyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 700,
    height: 500,
    bgcolor: "background.paper",
    border: "2px solid #377562",
    boxShadow: 24,
    
  };
  const checkstyle1 = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 1000,
    height: 500,
    bgcolor: "background.paper",
    border: "2px solid #356F60",
    boxShadow: 24,
    // overflow: "auto",
    // overflow: "auto",
     /* Other styles */
     scrollbarWidth: 'thin', // For Firefox
     scrollbarColor: '#356F60 transparent', // thumb color and track color
     '&::-webkit-scrollbar': {
       width: 8, /* Width of vertical scrollbar */
       height: 8, /* Height of horizontal scrollbar */
     },
     '&::-webkit-scrollbar-thumb': {
       backgroundColor: '#356F60', /* Color of the thumb */
       borderRadius: 10,
     },
     '&::-webkit-scrollbar-track': {
       backgroundColor: 'transparent', /* Color of the track */
     },
     /* Adjust the width for the horizontal scrollbar */
     '&::-webkit-scrollbar-thumb:horizontal': {
       width: 4, /* Width of horizontal scrollbar */
     },
    // zIndex: "10",
  };
  const styleDetails = {
    width: '90%',
    height: '95%',
    bgcolor: '#F0FFFF',
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 30,
    right: 0,
    margin: 'auto',
    marginTop: '10px',
    paddingBottom: '10px',
    padding: '20px',
    overflow: 'auto'
  }
  const styleDetailspatient = {
    width: '90%',
    // height: '70%',
    height: 600,
    bgcolor: 'white',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    margin: 'auto',
    marginTop: '10px',
    paddingBottom: '10px',
    padding: '20px',
    overflow: 'auto',
    scrollbarWidth: 'thin', // For Firefox
    scrollbarColor: '#356F60 transparent', // thumb color and track color
    '&::-webkit-scrollbar': {
      width: 8, /* Width of vertical scrollbar */
      height: 8, /* Height of horizontal scrollbar */
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#356F60', /* Color of the thumb */
      borderRadius: 10,
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'transparent', /* Color of the track */
    },
    /* Adjust the width for the horizontal scrollbar */
    '&::-webkit-scrollbar-thumb:horizontal': {
      width: 4, /* Width of horizontal scrollbar */
    },
   // zIndex: "10",
  }
  ////Question
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClosepopOver = () => {
    setAnchorEl(null);
  };
  /////Lomn
  const [anchorEl1, setAnchorEl1] = useState(null);
  const handleClick1 = (event) => {
    setAnchorEl1(event.currentTarget);
  };
  const handleClosepopOver1 = () => {
    setAnchorEl1(null);
  };
/////Aut Req
  const [anchorEl2, setAnchorEl2] = useState(null);
  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleClosepopOver2 = () => {
    setAnchorEl2(null);
  };
  //// sleep study
  const [anchorEl3, setAnchorEl3] = useState(null);
  const handleClick3 = (event) => {
    setAnchorEl3(event.currentTarget);
  };
  const handleClosepopOver3 = () => {
    setAnchorEl3(null);
  };

  /////Apporoved
  const [anchorEl4, setAnchorEl4] = useState(null);
  const handleClick4 = (event) => {
    setAnchorEl4(event.currentTarget);
  };
  const handleClosepopOver4 = () => {
    setAnchorEl4(null);
  };
  /////Sleeptest
  const [anchorEl5, setAnchorEl5] = useState(null);
  const handleClick5 = (event) => {
    setAnchorEl5(event.currentTarget);
  };
  const handleClosepopOver5 = () => {
    setAnchorEl5(null);
  };
   /////process note
   const [anchorEl6, setAnchorEl6] = useState(null);
   const handleClick6 = (event) => {
     setAnchorEl6(event.currentTarget);
   };
   const handleClosepopOver6 = () => {
     setAnchorEl6(null);
   };
   ///
   const [anchorEl7, setAnchorEl7] = useState(null);
   const handleClick7 = (event) => {
     setAnchorEl7(event.currentTarget);
   };
   const handleClosepopOver7 = () => {
     setAnchorEl7(null);
   };
     //idcard
     const [anchorEl8, setAnchorEl8] = useState(null);
   const handleClick8 = (event) => {
     setAnchorEl8(event.currentTarget);
   };
   const handleClosepopOver8 = () => {
     setAnchorEl8(null);
   };
  //additionalinfo
  const [anchorEl9, setAnchorEl9] = useState(null);
  const handleClick9 = (event) => {
    setAnchorEl9(event.currentTarget);
  };
  const handleClosepopOver9 = () => {
    setAnchorEl9(null);
  };

  //dentist
  const [anchorEl10, setAnchorEl10] = useState(null);
  const handleClick10 = (event) => {
    setAnchorEl10(event.currentTarget);
  };
  const handleClosepopOver10 = () => {
    setAnchorEl10(null);
  };
////Aut Question
  const openpop = Boolean(anchorEl);
  const idpop = openpop ? 'simple-popover' : undefined;
   ////Aut Lomn
  const openpop1 = Boolean(anchorEl1);
  const idpop1 = openpop1 ? 'simple-popover' : undefined;
  ////Aut Req
  const openpop2 = Boolean(anchorEl2);
  const idpop2 = openpop2 ? 'simple-popover' : undefined;
  ////STUdy Sleep
  const openpop3 = Boolean(anchorEl3);
  const idpop3 = openpop3 ? 'simple-popover' : undefined;
  ////Apporved
  const openpop4 = Boolean(anchorEl4);
  const idpop4 = openpop4 ? 'simple-popover' : undefined;
   ////sleeptest
   const openpop5 = Boolean(anchorEl5);
   const idpop5 = openpop5 ? 'simple-popover' : undefined;
    ////process note
  const openpop6 = Boolean(anchorEl6);
  const idpop6 = openpop6 ? 'simple-popover' : undefined;

  const openpop7 = Boolean(anchorEl7);
  const idpop7 = openpop7 ? 'simple-popover' : undefined;
  //idcard
  const openpop8 = Boolean(anchorEl8);
  const idpop8 = openpop8 ? 'simple-popover' : undefined;

  //additionalinfo
  const openpop9 = Boolean(anchorEl9);
  const idpop9 = openpop9 ? 'simple-popover' : undefined;

  //dentist
  const openpop10 = Boolean(anchorEl10);
  const idpop10 = openpop10 ? 'simple-popover' : undefined;

function handleChange() {

  
}
  return (
    <>
      <Container>
        <Modal
          open={openpatientreg}
          onClose={handleClosepatientreg}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={styleDetailspatient}>
            <Grid container columnSpacing={1}>
              <Grid item xs={6} style={{ fontWeight: 500 }}>
                <Typography style={{ fontWeight: "bold", color: "black", }}>
                  Patient Registration
                </Typography>
              </Grid>
              <Grid item xs={6} style={{ fontWeight: 500 }}>
            <Button
              onClick={handleClosepatientreg}
              disabled={submitting}
              style={{
                marginTop: '0px',
                marginBottom: '10px',
                float: 'right',
                marginLeft: '10px',
                width: '90px',
                height: '35px',
                // backgroundColor: '#A574FD',
                color: 'white',
                fontSize: '14px'
              }}
            >
              <CancelOutlinedIcon
                fontSize="medium"
                style={{ color: "red", paddingRight: "5px" }}
              ></CancelOutlinedIcon>
            </Button>
              </Grid>
              <Grid item xs={12} style={{ fontWeight: 500, marginTop: '-90px' ,}}>
              {/* yaha columnID pass ki patientID ki jga */}

              <PatientRegistration callingFrom={"Authorization"} pid={patientID} handleClosepatientreg={handleClosepatientreg}></PatientRegistration>
              </Grid>
            
            </Grid>
          </Box>
        </Modal>
        <Modal
          open={openpatientdem}
          onClose={handleClosepatientdem}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={styleDetails}>
            <Grid container columnSpacing={1}>
              <Grid item xs={6} style={{ fontWeight: 500 }}>
                <Typography style={{ fontWeight: "bold", color: "black", marginBottom: '10px', }}>
                  Demographics
                </Typography>
              </Grid>
              <Grid item xs={6} style={{ fontWeight: 500 }}>
              <Button
              onClick={handleClosepatientdem}
              disabled={submitting}
              style={{
                marginTop: '0px',
                marginBottom: '10px',
                float: 'right',
                marginLeft: '10px',
                width: '90px',
                height: '35px',
                // backgroundColor: '#A574FD',
                color: 'white',
                fontSize: '14px'
              }}
            >
              <CancelOutlinedIcon
                fontSize="medium"
                style={{ color: "red", paddingRight: "5px" }}
              ></CancelOutlinedIcon>
            </Button>
                </Grid>
              <Demographics  callingFrom={"Patient"} handleClosepatientdem={handleClosepatientdem} ></Demographics>
            </Grid>
          </Box>
        </Modal>
        <Modal
        id="hello-world"
          open={openDocument}
          onClose={handleCloseDocument}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          visible={true} title={'Title'} footer={'Footer'}
        >
          <Box sx={checkstyle}>

      
              <Grid container spacing={1} >
              <Grid item xs={11} style={{ fontWeight: 500, marginTop: "10px",  }}>
                      <Typography style={{ fontWeight: 'bold', marginLeft:"5px",fontSize: '18px', color: 'white',backgroundColor: '#377562', }}>
                      Add patient document
                      </Typography>
                    </Grid>
                  <Grid item xs={1}>
                    <Button
                      onClick={handleCloseDocument}
                      variant="gradient"
                      // disabled={submitting}
                      style={{
                        marginTop: "10px",
                        float: "right",
                        marginBottom: "5px",
                        // width: "80px",
                        height: "35px",
                        backgroundColor: "#fff",
                        color: "black",
                        fontSize: "14px",
                        cursor: 'pointer'
                      }}

                    // startIcon={<img src={SaveIcon} style={{marginTop:'-3px',marginRight:'-6px',}}/>}
                    >
                      <CancelOutlinedIcon
                        fontSize="medium"
                        style={{
                          color: "red",
                          
                          paddingBottom: "2px",
                        }}
                      ></CancelOutlinedIcon>
                  
                    </Button>
                  </Grid>
                  
                </Grid>
                   <Grid item xs={12}>
              <Documents callingFrom={"Patient"}
              patientID={patientID}
                  updateHealthHistory={(id, authId) => {
                    console.log('setting', id)
                    props.setAuthId(authId)
                    
                    console.log("tesssst222")
                  if (type == 'healthHistory') {
                      setValue('healthHistory', true)
                      setcolumnID(authId)
                      setValue('id',authId)
                    }
                  else if (type == 'questionnaire') {
                      setValue('questionnaire', true)
                      setcolumnID(authId)
                      setValue('id',authId)

                    }
                    else if (type == 'lomn') {
                      setValue('lomn', true)
                      setcolumnID(authId)
                      setValue('id',authId)

                    }
                    else if (type == 'authRequest') {
                      setValue('authRequest', true)
                      setcolumnID(authId)
                      setValue('id',authId)

                    }
                    else if (type == 'progressNotes') {
                      setValue('progressNotes', true)
                      setcolumnID(authId)
                      setValue('id',authId)

                    }
                    else if (type == 'sleepTest') {
                      setValue('sleepTest', true)
                      setcolumnID(authId)
                      setValue('id',authId)

                    }
                    else if (type == 'disclaimer') {
                      setValue('disclaimer', true)
                      setcolumnID(authId)
                      setValue('id',authId)

                    }
                    else if (type == 'idCard') {
                      setValue('idCard', true)
                      setcolumnID(authId)
                      setValue('id',authId)

                    }
                    else if (type == 'additionalInfo') {
                      setValue('additionalInfo', true)
                      setcolumnID(authId)
                      setValue('id',authId)

                    } 
                    else if (type == 'dentist') {
                      setValue('dentist', true)
                      setcolumnID(authId)
                      setValue('id',authId)

                    } 
                    
                   
                    
                  }}
                columnID={columnID} type={type} handleClose222={handleClose} 
                 AutpattientID={props.callingFrom === "dashboard" ? props.AutpattientID : patientID}
                  patientName={props.callingFrom === "dashboard" ? props.Dashboard : patientNamee} ></Documents>

                 </Grid>


          </Box>
        </Modal>
        <Modal
        id="hello-world"
          open={open2}
          onClose={handleClose22}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          visible={true} title={'Title'} footer={'Footer'}
        >
          <Box sx={checkstyle1}>
          <Headroom>
            <Grid
                  container
                  style={{ backgroundColor: '#356F60', position: 'sticky' }}
                >
                  <Grid item xs={12}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        height: '3rem'
                      }}
                    >
                      <DialogTitle
                        style={{
                          flex: 1,
                          textAlign: 'center',
                        }}
                      >
                        <h1
                          style={{
                            fontWeight: 'bold',
                            textAlign: 'center',
                            color: '#fff',
                            textDecoration: 'underline',
                            fontSize:'2rem'

                          }}
                        >
                          LETTER OF MEDICAL NECESSITY{" "}

                        </h1>
                      </DialogTitle>
                      <CancelOutlinedIcon
                        onClick={handleClose22}
                        fontSize="large"
                        color="red"
                        style={{
                          color: 'red',
                          paddingRight: '5px',
                          cursor: 'pointer',
                          // marginLeft: 'auto',
                          // marginRight: '1.5rem'
                        }}
                      ></CancelOutlinedIcon>
                    </div>
                  </Grid>
                </Grid>
              </Headroom>


            <Grid item xs={12}>
              <Card bordered={false} style={{ overflow: 'auto',
              /* Other styles */
     scrollbarWidth: 'thin', // For Firefox
     scrollbarColor: '#356F60 transparent', // thumb color and track color
     '&::-webkit-scrollbar': {
       width: 8, /* Width of vertical scrollbar */
       height: 8, /* Height of horizontal scrollbar */
     },
     '&::-webkit-scrollbar-thumb': {
       backgroundColor: '#356F60', /* Color of the thumb */
       borderRadius: 10,
     },
     '&::-webkit-scrollbar-track': {
       backgroundColor: 'transparent', /* Color of the track */
     },
     /* Adjust the width for the horizontal scrollbar */
     '&::-webkit-scrollbar-thumb:horizontal': {
       width: 4, /* Width of horizontal scrollbar */
     },
    // zIndex: "10",
              height: '70vh' }}>
                <Letter callingFrom={"Patient"} columnID={columnID} AutpattientID={props.callingFrom === "dashboard" ? props.AutpattientID : patientID} patientName={props.callingFrom === "dashboard" ? props.Dashboard : patientNamee} handleClose2={handleClose2}
                  updateHealthHistory={(id, authId) => {
                    // setautoClosePage(true)
                    console.log('setting', id)
                    setValue('LOMN', true)
                    setcolumnID(authId)
                    // setquestionnaireid(authId)
                  }}
                ></Letter>

              </Card>
              <ToastContainer
                position="bottom-center"
                autoClose={1500}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
              />
            </Grid>
          </Box>
        </Modal>
        <Modal
          open={open1}
          onClose={handleCloseOSA}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          visible={true} title={'Title'} footer={'Footer'}
        >
          <Box sx={checkstyle1}>

          <Headroom>
            <Grid
                  container
                  style={{ backgroundColor: '#356F60', position: 'sticky' }}
                >
                  <Grid item xs={12}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        height: '3rem'
                      }}
                    >
                      <DialogTitle
                        style={{
                          flex: 1,
                          textAlign: 'center',
                        }}
                      >

                        <h1
                          style={{
                            fontWeight: 'bold',
                            textAlign: 'center',
                            color: '#fff',
                            textDecoration: 'underline',
                            fontSize:'2rem'

                          }}
                        >
                          Snoring and obstructive sleep apnea (OSA) Screener{" "}

                        </h1>
                      </DialogTitle>
                      <CancelOutlinedIcon
                        onClick={handleCloseOSA}
                        fontSize="large"
                        color="red"
                        style={{
                          color: 'red',
                          paddingRight: '5px',
                          cursor: 'pointer',
                          // marginLeft: 'auto',
                          // marginRight: '1.5rem'
                        }}
                      ></CancelOutlinedIcon>
                    </div>
                  </Grid>
                </Grid>
              </Headroom>


            <Grid item xs={12}>
              <Card bordered={false} style={{ overflow: 'auto',
              /* Other styles */
     scrollbarWidth: 'thin', // For Firefox
     scrollbarColor: '#356F60 transparent', // thumb color and track color
     '&::-webkit-scrollbar': {
       width: 8, /* Width of vertical scrollbar */
       height: 8, /* Height of horizontal scrollbar */
     },
     '&::-webkit-scrollbar-thumb': {
       backgroundColor: '#356F60', /* Color of the thumb */
       borderRadius: 10,
     },
     '&::-webkit-scrollbar-track': {
       backgroundColor: 'transparent', /* Color of the track */
     },
     /* Adjust the width for the horizontal scrollbar */
     '&::-webkit-scrollbar-thumb:horizontal': {
       width: 4, /* Width of horizontal scrollbar */
     },
    // zIndex: "10",
              height: '70vh' }}>
                <OSAPAGE callingFrom={"Patient"} columnID={columnID} AutpattientID={props.callingFrom === "dashboard" ? props.AutpattientID : patientID} patientName={props.callingFrom === "dashboard" ? props.Dashboard : patientNamee} handleClose1={handleClose1}
                  updateHealthHistory={(id, authId) => {
                    setautoClosePage(false)
                    console.log('setting', id)
                    setValue('questionnaire', true)
                    setcolumnID(authId)
                    setValue('id',authId)
                    // setquestionnaireid(authId)
                  }}
                ></OSAPAGE>

              </Card>
              <ToastContainer
                position="bottom-center"
                autoClose={1500}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
              />
            </Grid>


          </Box>
        </Modal>
        <Modal
          open={open3}
          onClose={handleCloseVOB}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          visible={true} title={'Title'} footer={'Footer'}
        >
          <Box sx={checkstyle1}>

            <Headroom>
              <Grid
                container
                style={{ backgroundColor: '#356F60', position: 'sticky' }}
              >
                <Grid item xs={12}>
                <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        height: '3rem'
                      }}
                    >
                    <DialogTitle
                      style={{
                        flex: 1,
                        textAlign: 'center',
                      }}
                    >
                      <h1
                        style={{
                          fontWeight: 'bold',
                          textAlign: 'center',
                          color: '#fff',
                          fontSize:'2rem'

                        }}
                      >
                        VOB/ Prior-auth Request
                      </h1>
                    </DialogTitle>
                    <CancelOutlinedIcon
                      onClick={handleCloseVOB}
                      fontSize="large"
                      color="red"
                      style={{
                        color: 'red',
                        paddingRight: '5px',
                        cursor: 'pointer',
                        // marginLeft: 'auto',
                        marginRight: '1.5rem'
                      }}
                    ></CancelOutlinedIcon>
                  </div>
                </Grid>
              </Grid>
            </Headroom>


            <Grid item xs={12}>
              <Card bordered={false} style={{ overflow: 'auto',
              /* Other styles */
     scrollbarWidth: 'thin', // For Firefox
     scrollbarColor: '#356F60 transparent', // thumb color and track color
     '&::-webkit-scrollbar': {
       width: 8, /* Width of vertical scrollbar */
       height: 8, /* Height of horizontal scrollbar */
     },
     '&::-webkit-scrollbar-thumb': {
       backgroundColor: '#356F60', /* Color of the thumb */
       borderRadius: 10,
     },
     '&::-webkit-scrollbar-track': {
       backgroundColor: 'transparent', /* Color of the track */
     },
     /* Adjust the width for the horizontal scrollbar */
     '&::-webkit-scrollbar-thumb:horizontal': {
       width: 4, /* Width of horizontal scrollbar */
     },
    // zIndex: "10",
              height: '70vh' }}>
                <VOBPAGE1 callingFrom={"Patient"} columnID={columnID}  AutpattientID={props.callingFrom === "dashboard" ? props.AutpattientID : patientID} patientName={props.callingFrom === "dashboard" ? props.Dashboard : patientNamee} handleClose3={handleClose3}
                  updateHealthHistory={(id, authId) => {
                    // setautoClosePage(false)
                    console.log('setting', id)
                    setValue('authRequest', true)
                    setcolumnID(authId)
                    setValue('id',authId)
                    // setauthRequestid(authId)
                  }}
                ></VOBPAGE1>

              </Card>
              <ToastContainer
                position="bottom-center"
                autoClose={1500}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
              />
            </Grid>


          </Box>
        </Modal>
        <Modal
          open={open4}
          onClose={handleClose4}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          visible={true} title={'Title'} footer={'Footer'}
        >
          <Box sx={checkstyle1}>

          {/* <Headroom>
              <Grid
                  container
                  style={{ backgroundColor: '#356F60', position: 'sticky' }}
                >
                  <Grid item xs={12}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        height: '3rem'
                      }}
                    >
                      <DialogTitle
                        style={{
                          flex: 1,
                          textAlign: 'center',
                        }}
                      >
                        <h1
                          style={{
                            fontWeight: 'bold',
                            textAlign: 'center',
                            color: '#fff',
                            textDecoration: 'underline',
                            fontSize:'2rem'

                          }}
                        >
                        Prior-auth & VOB

                        </h1>
                      </DialogTitle>
                      <CancelOutlinedIcon
                        onClick={handleClose4}
                        fontSize="large"
                        color="red"
                        style={{
                          color: 'red',
                          paddingRight: '5px',
                          cursor: 'pointer',
                          // marginLeft: 'auto',
                          // marginRight: '1.5rem'
                        }}
                      ></CancelOutlinedIcon>
                    </div>
                  </Grid>
                </Grid>
              </Headroom> */}


            <Grid item xs={12}>
              
              <Card bordered={false} style={{ overflow: 'auto',
             /* Other styles */
     scrollbarWidth: 'thin', // For Firefox
     scrollbarColor: '#356F60 transparent', // thumb color and track color
     '&::-webkit-scrollbar': {
       width: 8, /* Width of vertical scrollbar */
       height: 8, /* Height of horizontal scrollbar */
     },
     '&::-webkit-scrollbar-thumb': {
       backgroundColor: '#356F60', /* Color of the thumb */
       borderRadius: 10,
     },
     '&::-webkit-scrollbar-track': {
       backgroundColor: 'transparent', /* Color of the track */
     },
     /* Adjust the width for the horizontal scrollbar */
     '&::-webkit-scrollbar-thumb:horizontal': {
       width: 4, /* Width of horizontal scrollbar */
     },
    // zIndex: "10",
               height: '80vh' }}>
                <Prior_auth patientNamee={patientNamee} patientID={patientID}  searchTex={patientName} callingFrom={"Patient"} columnID={columnID} handleClose4={handleClose4} AutpattientID={props.callingFrom === "dashboard" ? props.AutpattientID : patientID} patientName={props.callingFrom === "dashboard" ? props.Dashboard : patientNamee}
                  updateHealthHistory={(id, authId) => {
                    // setautoClosePage(false)
                    console.log('setting', id)
                    setValue('approved', true)
                    setcolumnID(authId)
                    setValue('id',authId)
                    // setapproveid(authId)
                  }}
                  style={{ height: '40vh' }}

                ></Prior_auth>
                </Card>
                <ToastContainer
                position="bottom-center"
                autoClose={1500}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
              />
            </Grid>
            </Box>
        </Modal>


        <Modal
          open={disclaimer}
          onClose={closedisclaimer1}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          visible={true} title={'Title'} footer={'Footer'}
        >
          <Box sx={checkstyle1}>

             {/* <Headroom>
              <Grid
                container
                style={{ backgroundColor: '#356F60', position: 'sticky' }}
              >
                <Grid item xs={12}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      height: '3rem'
                    }}
                  >
                    <DialogTitle
                      style={{
                        flex: 1,
                        textAlign: 'center',
                      }}
                    >
                      <h1
                        style={{
                          fontWeight: 'bold',
                          textAlign: 'center',
                          color: '#fff',
                          textDecoration: 'underline',
                          fontSize:'2rem'

                        }}
                      >
                       Patient Responsibility Disclaimer

                      </h1>
                    </DialogTitle>
                    <CancelOutlinedIcon
                      onClick={closedisclaimer1}
                      fontSize="large"
                      color="red"
                      style={{
                        color: 'red',
                        paddingRight: '5px',
                        cursor: 'pointer',
                        // marginLeft: 'auto',
                        // marginRight: '1.5rem'
                      }}
                    ></CancelOutlinedIcon>
                  </div>
                </Grid>
              </Grid>
            </Headroom>  */}


            <Grid item xs={12}>

              <Card bordered={false} style={{ overflow: 'auto',
              /* Other styles */
     scrollbarWidth: 'thin', // For Firefox
     scrollbarColor: '#356F60 transparent', // thumb color and track color
     '&::-webkit-scrollbar': {
       width: 8, /* Width of vertical scrollbar */
       height: 8, /* Height of horizontal scrollbar */
     },
     '&::-webkit-scrollbar-thumb': {
       backgroundColor: '#356F60', /* Color of the thumb */
       borderRadius: 10,
     },
     '&::-webkit-scrollbar-track': {
       backgroundColor: 'transparent', /* Color of the track */
     },
     /* Adjust the width for the horizontal scrollbar */
     '&::-webkit-scrollbar-thumb:horizontal': {
       width: 4, /* Width of horizontal scrollbar */
     },
    // zIndex: "10",
              height: '80vh' }}> 
                <Disclaimer callingFrom={"Patient"} columnID={columnID} AutpattientID={props.callingFrom === "dashboard" ? props.AutpattientID : patientID} patientName={props.callingFrom === "dashboard" ? props.Dashboard : patientNamee} closedisclaimer={closedisclaimer1}
                  updateHealthHistory={(id, authId) => {
                    // setautoClosePage(false)
                    console.log('setting', id)
                    setValue('Disclaimer', true)
                    setcolumnID(authId)
                    setValue('id',authId)
                    // setquestionnaireid(authId)
                  }} style={{height: '40vh'}}/>
              </Card>
              <ToastContainer
                position="bottom-center"
                autoClose={1500}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
              />
            </Grid>
          </Box>
        </Modal>

        <Modal
          open={dentist}
          onClose={closedentist1}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          visible={true} title={'Title'} footer={'Footer'}
        >
          <Box sx={checkstyle1}>

            <Headroom>
              <Grid
                container
                style={{ backgroundColor: '#356F60', position: 'sticky' }}
              >
                <Grid item xs={12}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      height: '3rem'
                    }}
                  >
                    <DialogTitle
                      style={{
                        flex: 1,
                        textAlign: 'center',
                      }}
                    >
                      <h1
                        style={{
                          fontWeight: 'bold',
                          textAlign: 'center',
                          color: '#fff',
                          textDecoration: 'underline',
                          fontSize:'2rem'

                        }}
                      >
                       DENTIST USE ONLY - Oral examination
                      </h1>
                    </DialogTitle>
                    <CancelOutlinedIcon
                      onClick={closedentist1}
                      fontSize="large"
                      color="red"
                      style={{
                        color: 'red',
                        paddingRight: '5px',
                        cursor: 'pointer',
                        // marginLeft: 'auto',
                        // marginRight: '1.5rem'
                      }}
                    ></CancelOutlinedIcon>
                  </div>
                </Grid>
              </Grid>
            </Headroom>


            <Grid item xs={12}>

              <Card bordered={false} style={{ overflow: 'auto', height: '80vh' }}> 
                <DentistUse callingFrom={"Patient"} columnID={columnID} AutpattientID={props.callingFrom === "dashboard" ? props.AutpattientID : patientID} patientName={props.callingFrom === "dashboard" ? props.Dashboard : patientNamee} closedentist={closedentist}
                  updateHealthHistory={(id, authId) => {
                    // setautoClosePage(false)
                    console.log('setting', id)
                    setValue('dentalExam', true)
                    setcolumnID(authId)
                    setValue('id',authId)
                    // setquestionnaireid(authId)
                  }} style={{height: '40vh'}}/>
              </Card>
              <ToastContainer
                position="bottom-center"
                autoClose={1500}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
              />
            </Grid>
          </Box>
        </Modal>


        <Grid container columnSpacing={1} >

          <Grid item xs={12}></Grid>

          <Grid container spacing={2} >
            <Grid item xs={1.5} style={{ marginTop: '10px' }}>
              <Typography style={{ fontWeight: "bold", color: "black", alignItems: 'center' }}>
                Patient
              </Typography>


            </Grid>
            <Grid item xs={4} style={{ marginLeft: '-15px', marginTop: '5px' }} >

              <ClickAwayListener onClickAway={() => setPatient(false)}>
                <TextField
                autoComplete='off'
                  // isClearable
                  {...register('patientName')}
                  size='small'
                  fullWidth
                  placeholder='Patient'
                  InputProps={{
                    readOnly: FormCall === "demographic" || columnID > 0, 
                  }}
                  onKeyUp={ev => {
                    console.log('ee:', ev.target.value)
                    // dont fire API if the user delete or not entered anything
                    if (ev.target.value !== null && ev.target.value !== '' && ev.target.value.length > 0) {
                      onChangeHandle(ev.target.value)
                      setSearchText(ev.target.value)
                     
                    } else {
                      setPatientData([])
                      setselectedValueId(false)
                      setPatientSelected(false)
                      setPatient(false)
                      setSearchText('')
                      setdisable(false)
                    }
                  }}
                  // error={errors.patientName}
                  // helperText={errors.patientName ? errors.patientName.message : ''}
                />
              </ClickAwayListener>
              {isLoading && (
                <CircularProgress size={20} style={{ marginLeft: '-40px', marginTop: '10px' }} />
                  )}
              {patient ? (
                <div
                  className='col-xs-6'
                  style={{
                    padding: '14px 16px',
                    fontSize: '0.875rem',
                    color: '#67748e',
                    borderRadius: '5px',
                    background: 'white',
                    textAlign: 'justify',
                    textJustify: 'inter-word',
                    position: 'absolute',
                    zIndex: '99',
                    width: '26%',
                    border: '1px solid #6cb5f9'
                  }}
                >
           {patient ? 
                  (
                    patientData.length > 0 ?
                     (
                      patientData.map((s, index) => {
                        var pat = `${patientData[index].lastName}, ${patientData[index].firstName}`
             
                        const searchTextLower = searchText.toLowerCase();
                        const fullNameLower = pat.toLowerCase();
                        const i = fullNameLower.indexOf(searchTextLower);
                  
                        if (i === -1) {
                          return null;
                        }
                  
                        const p1 = pat.substring(0, i);
                        const p = pat.substring(i, i + searchText.length);
                        const p2 = pat.substring(i + searchText.length);

                        return (
                          <>
                            <p
                             style={PatientStyle}
                             onClick={ev => {
                              const selectedValue = `${patientData[index].lastName}, ${patientData[index].firstName}`;
                              console.log('Selected Value:', selectedValue);
                              setselectedValueId(true)
                              // setSelectedPatient(selectedValue)
                              setValue('patientName', `${patientData[index].lastName}, ${patientData[index].firstName}`)
                              setValue('patientId', patientData[index].id)
                              setPatientID(patientData[index].id)
                              setdisable(false)
                              setPatientData([])
                              setPatientSelected(true)
                              setPatient(false)
                              setPatientNameError(false)
                              setPatientNamee(`${patientData[index].lastName}, ${patientData[index].firstName}`)
                            }}
                            onBlur={ev => {
                              setPatientData([])
                              setPatient(false)
                            }}
                            onChange={ev => {
                              setPatientData([])
                              setPatientSelected(false)
                              setPatient(false)
                              setPatientNamee('')
                              setdisable(true)
                            }}
                          >
                            <span>{p1}</span>
                            <span style={{ backgroundColor: 'yellow' }}>{p}</span>
                            <span>{p2}</span>
                          </p>
                          </>
                        );
                      })
                    ) : (
                      <Typography>No Record found</Typography>
                    )
                  ) : null}
                </div>
              ) : (
                ''
              )}
                {PatientNameError && (< p style={{color:'red'}}>Please Select Patient</p>)}

            </Grid>
            <Grid item xs={0.5} style={{ marginTop: '10px' }}>
              {patientID > 0 ? (
                    <Tooltip title="View">
                <Button
                  style={{
                    backgroundColor: disable ? 'gery' : '',
                    color: 'white',

                  }}
                  size='small'
                  disabled={disable == true ? true : false}
                  onClick={handleOpenpatientreg}>
                  <RemoveRedEyeIcon
                    fontSize="medium"
                    style={{ color: "#356F60", }}
                  ></RemoveRedEyeIcon>
                  
                </Button>
                </Tooltip>
              ) : (
                <>
                    <Tooltip title="Add New">
                  <Button
                    className="Button"
                    style={{
                      backgroundColor: '#E87426',
                      color: 'white',

                    }}
                    size='small'
                    // disabled={disable == true?true:false }
                    onClick={handleOpenpatientdem}>

                    <AddIcon
                      fontSize="medium"
                      style={{ color: "#356F60", paddingRight: "5px" }}
                      ></AddIcon>

                  </Button>
                  </Tooltip>
                </>
              )}
            </Grid>
            {/* <Grid item xs={3} style={{ marginTop: '13px',marginLeft:'30px' }}>
              {patientID > 0 ? (
                 <Typography style={{ fontWeight: "bold", color: "black" }}>
                 View
               </Typography>
              ) : (
                <>
                   <Typography style={{ fontWeight: "bold", color: "black", marginLeft:'0.7rem' }}>
                 Add New
               </Typography>
                </>
              )}
            </Grid> */}
            <Grid item xs={6}>
            </Grid>
            <Grid item xs={1.5}></Grid>
            <Grid item xs={10.5}>
              {/* {disable == true ? (<>
                <Typography style={{ color: "red" }}>
                  Please Select Patient Name
                </Typography>
              </>) : ('')} */}
            </Grid>
            <Grid item xs={0.5}></Grid>
            <Grid item xs={10.5}>
              <Grid container columnSpacing={1}>
              <input
                  name="patientInfo"
                  size="small"
                  disabled={disable == true ? true : false}
                  type="checkbox"
                  {...register("patientInfo")}
                  checked={selectedValueId}
                  onChange={e => {
                    setValue('patientInfo', e.target.checked)
                    setselectedValueId('patientInfo',e.target.checked)
                  }}
                  id="patientInfo"
                  style={{
                    marginLeft: "10px",
                    marginRight: "10px",
                    marginTop: "-10px",
                  }}
                />
              {/* <input
  name="patientInfo"
  size="small"
  disabled={disable === true ? true : false}
  type="checkbox"
  {...register("patientInfo")}
  checked={selectedValueId ? true : false}
  id="patientInfo"
  style={{
    marginLeft: "10px",
    marginRight: "10px",
    marginTop: "-10px",
  }}
/> */}

                 <label  for="patientInfo" style={{ fontWeight: "bold", color: "black" }}>Patient Info</label>
                 
                {/* <Typography style={{ fontWeight: "bold", color: "black" }}>
                  Patient Info
                </Typography> */}
              </Grid>
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={0.5}></Grid>
            <Grid item xs={8.5}>
              <Grid container columnSpacing={1}>
            
              <input
                  name="idCard"
                  size="small"
                  type="checkbox"
                  disabled={disable == true ? true : false}
                  {...register("idCard")}
                  onChange={e => {
                    setValue('idCard', e.target.checked)
                    setIdcard('idCard',e.target.checked)
                  }}
                  id="idCard"
                  style={{
                    marginLeft: "10px",
                    marginRight: "10px",
                    marginTop: "-10px",
                  }}
                   
                />
                <label  for="idCard" style={{ fontWeight: "bold", color: "red",fontSize:'15px' }}>*</label>

                <label  for="idCard" style={{ fontWeight: "bold", color: "black" }}>Patient Medical ID card (front & back)</label>
                {/* <Typography style={{ fontWeight: "bold", color: "black" }} 
              
                >
               
                </Typography> */}
                
              </Grid>

            </Grid>
            <Grid item xs={3} style={{ marginTop: '-5px' }}>
              <Grid style={{ marginLeft: '1rem' }}>
                  <ArrowDropDown
                    variant="outlined"
                    onClick={handleClick8}
                    aria-describedby={idpop8}
                    style={{ cursor: 'pointer' }}
                  >
                  </ArrowDropDown>
                  <Popover
                    id={idpop8}
                    open={openpop8}
                    anchorEl={anchorEl8}
                    onClose={handleClosepopOver8}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                  >
                    <div style={{ display: 'flex', flexDirection: 'column', padding: '0.5rem', background: '#356f60',}}>
                    {idcard == true ? (
                          <Tooltip title="View">
                            <Button
                              style={{
                                backgroundColor: disable ? 'gery' : '',
                                color: 'white',

                              }}
                              size='small'
                              disabled={disable == true ? true : false}
                              onClick={(e) => {
                                settype('idCard')
                                handleOpen()
                              }}>
                             
                              <Typography
                                fontSize="medium"
                                style={{ color: "#fff", }}
                              >View Yours</Typography>

                            </Button>
                          </Tooltip>
                        ) : (
                          <>
                            <Tooltip title="Add New">
                              <Button

                                style={{
                                  backgroundColor: disable ? 'gery' : '',
                                  color: 'white',

                                }}

                                size='small'
                                disabled={disable == true ? true : false}
                                onClick={(e) => {
                                  settype('idCard')
                                  handleOpen()
                                }}
                              >
                                <Typography
                                  fontSize="medium"
                                  style={{ color: "#fff", }}
                                > Upload Yours
                                </Typography>
                              </Button>
                            </Tooltip>
                          </>
                        )}
                       
                    </div>
                  </Popover>
                </Grid>


            </Grid>
            
            <Grid item xs={0.5}></Grid>
            <Grid item xs={8.5}>
              <Grid container columnSpacing={1}>
                <input
                  name="questionnaire"
                  size="small"
                  type="checkbox"
                  disabled={disable == true ? true : false}
                  {...register("questionnaire")}
                  onChange={e => {
                    setValue('questionnaire', e.target.checked)
                    setquestionnaire('questionnaire',e.target.checked)
                  }}
                  id="questionnaire"
                  style={{
                    marginLeft: "10px",
                    marginRight: "10px",
                    marginTop: "-10px",
                  }}
                />
                <label  for="questionnaire" style={{ fontWeight: "bold", color: "red",fontSize:'15px' }}>*</label>
                <label  for="questionnaire" style={{ fontWeight: "bold", color: "black", }}>The patient questionnaire/ screener must be completed in its entirety</label>
                {/* <Typography style={{ fontWeight: "bold", color: "black" }}>
                *The patient questionnaire/ screener must be completed in its entirety
                </Typography> */}
              </Grid>

            </Grid>
            <Grid item xs={3} style={{ marginTop: '-5px' }}>
{/*              
              {questionnaire == true ? (
                  <Tooltip title="View">
                <Button
                  style={{
                    backgroundColor: disable ? 'gery' : '',
                    color: 'white',

                  }}
                  size='small'
                  disabled={disable == true ? true : false}
                  onClick={handleOpen1}>
                  <RemoveRedEyeIcon
                    fontSize="medium"
                    style={{ color: "#356F60", }}
                  ></RemoveRedEyeIcon>

                </Button>
                </Tooltip>
              ) : (
                <>
                <Tooltip title="Add New">
                  <Button
                    style={{
                      backgroundColor: disable ? 'gery' : '#E87426',
                      color: 'white',

                    }}
                    size='small'
                    disabled={disable == true ? true : false}
                    onClick={handleOpen1}>
                    <AddIcon
                      fontSize="medium"
                      style={{ color: "#356F60", paddingRight: "5px" }}
                    ></AddIcon>

                  </Button>
                  </Tooltip>

                </>
              )} */}
              <Grid style={{ marginLeft: '1rem' }}>
                  <ArrowDropDown
                    variant="outlined"
                    onClick={handleClick}
                    aria-describedby={idpop}
                    style={{ cursor: 'pointer' }}
                  >
                  </ArrowDropDown>
                  <Popover
                    id={idpop}
                    open={openpop}
                    anchorEl={anchorEl}
                    onClose={handleClosepopOver}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                  >
                    <div style={{ display: 'flex', flexDirection: 'column', padding: '0.5rem', background: '#356f60',}}>
                      {questionnaire == true ? (
                        <Tooltip title="View">
                          <Button
                            style={{
                              backgroundColor: disable ? 'gery' : '',
                              color: 'white',


                            }}
                            size='small'
                            disabled={disable == true ? true : false}
                            onClick={handleOpen1}>
                            <Typography
                              fontSize="medium"
                              style={{ color: "#fff", }}
                            >Use Ours</Typography>

                          </Button>
                        </Tooltip>
                      ) : (
                        <>
                          <Tooltip title="Add New">
                            <Button
                              style={{
                                backgroundColor: disable ? 'gery' : '',
                                color: 'white',

                              }}
                              size='small'
                              disabled={disable == true ? true : false}
                              onClick={handleOpen1}>
                              <Typography
                                fontSize="medium"
                                style={{ color: "#fff",}}
                              >Use Ours</Typography>

                            </Button>
                          </Tooltip>
                        </>
                      )}
                        {questionnaire == true ? (
                          <Tooltip title="View">
                            <Button
                              style={{
                                backgroundColor: disable ? 'gery' : '',
                                color: 'white',

                              }}
                              size='small'
                              disabled={disable == true ? true : false}
                              onClick={(e) => {
                                settype('questionnaire')
                                handleOpen()
                              }}>
                             
                              <Typography
                                fontSize="medium"
                                style={{ color: "#fff", }}
                              >View Yours</Typography>

                            </Button>
                          </Tooltip>
                        ) : (
                          <>
                            <Tooltip title="Add New">
                              <Button

                                style={{
                                  backgroundColor: disable ? 'gery' : '',
                                  color: 'white',

                                }}

                                size='small'
                                disabled={disable == true ? true : false}
                                onClick={(e) => {
                                  settype('questionnaire')
                                  handleOpen()
                                }}
                              >
                                <Typography
                                  fontSize="medium"
                                  style={{ color: "#fff", }}
                                > Upload Yours
                                </Typography>
                              </Button>
                            </Tooltip>
                          </>
                        )}
                    </div>
                  </Popover>
                </Grid>
            </Grid>
           
            <Grid item xs={0.5}></Grid>
            <Grid item xs={8.5}>
              <Grid container columnSpacing={1}>
                <input
                  name="dentalExam"
                  size="small"
                  type="checkbox"
                  disabled={disable == true ? true : false}
                  {...register("dentalExam")}
                  id="dentalExam"
                  style={{
                    marginLeft: "10px",
                    marginRight: "10px",
                    marginTop: "-10px",
                  }}

                />
                  <label  for="dentalExam" style={{ fontWeight: "bold", color: "black" }}> Dental exam questionnaire</label>
             
              </Grid>

            </Grid>
            <Grid item xs={3} style={{ marginTop: '-5px' }}>
  
              <Grid style={{ marginLeft: '1rem' }}>
                  <ArrowDropDown
                    variant="outlined"
                    onClick={handleClick10}
                    aria-describedby={idpop10}
                    style={{ cursor: 'pointer' }}
                  >
                  </ArrowDropDown>
                  <Popover
                    id={idpop10}
                    open={openpop10}
                    anchorEl={anchorEl10}
                    onClose={handleClosepopOver10}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                  >
                    <div style={{ display: 'flex', flexDirection: 'column', padding: '0.5rem', background: '#356f60',}}>
                    {newdentist == true ? (
                        <Tooltip title="View">
                          <Button
                            style={{
                              backgroundColor: disable ? 'gery' : '',
                              color: 'white',


                            }}
                            size='small'
                            disabled={disable == true ? true : false}
                            onClick={opendentist}>
                            <Typography
                              fontSize="medium"
                              style={{ color: "#fff", }}
                            >View Ours</Typography>

                          </Button>
                        </Tooltip>
                      ) : (
                        <>
                          <Tooltip title="Add New">
                            <Button
                              style={{
                                backgroundColor: disable ? 'gery' : '',
                                color: 'white',

                              }}
                              size='small'
                              disabled={disable == true ? true : false}
                              onClick={opendentist}>
                              <Typography
                                fontSize="medium"
                                style={{ color: "#fff",}}
                              >Use Ours</Typography>

                            </Button>
                          </Tooltip>
                        </>
                      )}
                        {newdentist == true ? (
                          <Tooltip title="View">
                            <Button
                              style={{
                                backgroundColor: disable ? 'gery' : '',
                                color: 'white',

                              }}
                              size='small'
                              disabled={disable == true ? true : false}
                              onClick={(e) => {
                                settype('dentist')
                                handleOpen()
                              }}>
                             
                              <Typography
                                fontSize="medium"
                                style={{ color: "#fff", }}
                              >View Yours</Typography>

                            </Button>
                          </Tooltip>
                        ) : (
                          <>
                            <Tooltip title="Add New">
                              <Button

                                style={{
                                  backgroundColor: disable ? 'gery' : '',
                                  color: 'white',

                                }}

                                size='small'
                                disabled={disable == true ? true : false}
                                onClick={(e) => {
                                  settype('dentist')
                                  handleOpen()
                                }}
                              >
                                <Typography
                                  fontSize="medium"
                                  style={{ color: "#fff", }}
                                > Upload Yours
                                </Typography>
                              </Button>
                            </Tooltip>
                          </>
                        )}
                       
                    </div>
                  </Popover>
                </Grid>


            </Grid>
           




            <Grid item xs={0.5}></Grid>
            <Grid item xs={8.5}>
              <Grid container columnSpacing={1}>
                <input
                  name="disclaimer"
                  size="small"
                  type="checkbox"
                  disabled={disable == true ? true : false}
                  {...register("disclaimer")}
                  id="disclaimer"
                  style={{
                    marginLeft: "10px",
                    marginRight: "10px",
                    marginTop: "-10px",
                  }}

                />
                  <label  for="disclaimer" style={{ fontWeight: "bold", color: "black" }}>  Financial agreement (recommended for your records only)</label>
             
              </Grid>

            </Grid>
            <Grid item xs={3} style={{ marginTop: '-5px' }}>

              
              <Grid style={{ marginLeft: '1rem' }}>
                  <ArrowDropDown
                    variant="outlined"
                    onClick={handleClick7}
                    aria-describedby={idpop7}
                    style={{ cursor: 'pointer' }}
                  >
                  </ArrowDropDown>
                  <Popover
                    id={idpop7}
                    open={openpop7}
                    anchorEl={anchorEl7}
                    onClose={handleClosepopOver7}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                  >
                    <div style={{ display: 'flex', flexDirection: 'column', padding: '0.5rem', background: '#356f60',}}>
                    {newdisclaimer == true ? (
                        <Tooltip title="View">
                          <Button
                            style={{
                              backgroundColor: disable ? 'gery' : '',
                              color: 'white',


                            }}
                            size='small'
                            disabled={disable == true ? true : false}
                            onClick={opendisclaimer}>
                            <Typography
                              fontSize="medium"
                              style={{ color: "#fff", }}
                            >View Ours</Typography>

                          </Button>
                        </Tooltip>
                      ) : (
                        <>
                          <Tooltip title="Add New">
                            <Button
                              style={{
                                backgroundColor: disable ? 'gery' : '',
                                color: 'white',

                              }}
                              size='small'
                              disabled={disable == true ? true : false}
                              onClick={opendisclaimer}>
                              <Typography
                                fontSize="medium"
                                style={{ color: "#fff",}}
                              >Use Ours</Typography>

                            </Button>
                          </Tooltip>
                        </>
                      )}
                        {newdisclaimer == true ? (
                          <Tooltip title="View">
                            <Button
                              style={{
                                backgroundColor: disable ? 'gery' : '',
                                color: 'white',

                              }}
                              size='small'
                              disabled={disable == true ? true : false}
                              onClick={(e) => {
                                settype('disclaimer')
                                handleOpen()
                              }}>
                             
                              <Typography
                                fontSize="medium"
                                style={{ color: "#fff", }}
                              >View Yours</Typography>

                            </Button>
                          </Tooltip>
                        ) : (
                          <>
                            <Tooltip title="Add New">
                              <Button

                                style={{
                                  backgroundColor: disable ? 'gery' : '',
                                  color: 'white',

                                }}

                                size='small'
                                disabled={disable == true ? true : false}
                                onClick={(e) => {
                                  settype('disclaimer')
                                  handleOpen()
                                }}
                              >
                                <Typography
                                  fontSize="medium"
                                  style={{ color: "#fff", }}
                                > Upload Yours
                                </Typography>
                              </Button>
                            </Tooltip>
                          </>
                        )}
                       
                    </div>
                  </Popover>
                </Grid>


            </Grid>



            <Grid item xs={0.5}></Grid>
            <Grid item xs={8.5}>
              <Grid container columnSpacing={1}>
                <input
                  name="healthHistory"
                  size="small"
                  type="checkbox"
                  disabled={disable == true ? true : false}
                  {...register("healthHistory")}
                  onChange={e => {
                    setValue('healthHistory', e.target.checked)
                    sethistory('healthHistory',e.target.checked)
                  }}
                  id="healthHistory"
                  style={{
                    marginLeft: "10px",
                    marginRight: "10px",
                    marginTop:"-10px",
                  }}

                />
                <label  for="healthHistory" style={{ fontWeight: "bold", color: "red",fontSize:'15px' }}>*</label>
                <label  for="healthHistory" style={{ fontWeight: "bold", color: "black", }}>Face-to-face referring provider notes before the Sleep test</label>
               
              </Grid>

            </Grid>
            <Grid item xs={3} style={{ marginTop: '-5px' }}>

              {/* {history == true ? (
                <Tooltip title="View">
                <Button
                  style={{
                    backgroundColor: disable ? 'gery' : '',
                    color: 'white',

                  }}
                  size='small'
                  disabled={disable == true ? true : false}
                  onClick={(e) => {
                    settype('healthHistory')
                    handleOpen()
                  }}>
                  <RemoveRedEyeIcon
                    fontSize="medium"
                    style={{ color: "#356F60", }}
                  ></RemoveRedEyeIcon>
                  View Yours
                </Button>
                </Tooltip>
              ) : (
                <>
                <Tooltip title="Add New">
                  <Button

                    style={{
                      backgroundColor: disable ? 'gery' : '#E87426',
                      color: 'white',

                    }}

                    size='small'
                    disabled={disable == true ? true : false}
                    onClick={(e) => {
                      settype('healthHistory')
                      handleOpen()
                    }}
                  >
                    <AddIcon
                      fontSize="medium"
                      style={{ color: "#356F60", paddingRight: "5px" }}
                    ></AddIcon>
                    Add Yours
                  </Button>
                  </Tooltip>
                </>
              )} */}
              <Grid style={{ marginLeft: '1rem' }}>
                  <ArrowDropDown
                    variant="outlined"
                    onClick={handleClick3}
                    aria-describedby={idpop3}
                    style={{ cursor: 'pointer' }}
                  >
                  </ArrowDropDown>
                  <Popover
                    id={idpop3}
                    open={openpop3}
                    anchorEl={anchorEl3}
                    onClose={handleClosepopOver3}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                  >
                    <div style={{ display: 'flex', flexDirection: 'column', padding: '0.5rem', background: '#356f60',}}>
                    {history == true ? (
                          <Tooltip title="View">
                            <Button
                              style={{
                                backgroundColor: disable ? 'gery' : '',
                                color: 'white',

                              }}
                              size='small'
                              disabled={disable == true ? true : false}
                              onClick={(e) => {
                                settype('healthHistory')
                                handleOpen()
                              }}>
                             
                              <Typography
                                fontSize="medium"
                                style={{ color: "#fff", }}
                              >View Yours</Typography>

                            </Button>
                          </Tooltip>
                        ) : (
                          <>
                            <Tooltip title="Add New">
                              <Button

                                style={{
                                  backgroundColor: disable ? 'gery' : '',
                                  color: 'white',

                                }}

                                size='small'
                                disabled={disable == true ? true : false}
                                onClick={(e) => {
                                  settype('healthHistory')
                                  handleOpen()
                                }}
                              >
                                <Typography
                                  fontSize="medium"
                                  style={{ color: "#fff", }}
                                > Upload Yours
                                </Typography>
                              </Button>
                            </Tooltip>
                          </>
                        )}
                       
                    </div>
                  </Popover>
                </Grid>


            </Grid>
           
            <Grid item xs={0.5}></Grid>
            <Grid item xs={9.5} style={{ marginTop: '-20px'}}>
              <Grid container columnSpacing={1}>
              <label  for="healthHistory" style={{ fontWeight: "bold", color: "black",marginLeft:'8px' }}> (Medicare and Medicare Advantage plans must be IN-Person)</label>
                
              </Grid>

            </Grid>
            <Grid item xs={2}></Grid>
            <Grid item xs={0.5}></Grid>
            <Grid item xs={8.5}>
              <Grid container columnSpacing={1}>
                <input
                  name="sleepTest"
                  size="small"
                  type="checkbox"
                  disabled={disable == true ? true : false}
                  {...register("sleepTest")}
                  onChange={e => {
                    setValue('sleepTest', e.target.checked)
                    setsleeptest('sleepTest',e.target.checked)
                  }}
                  id="sleepTest"
                  style={{
                    marginLeft: "10px",
                    marginRight: "10px",
                    marginTop:"-10px"
                  }}

                />
                  <label  for="sleepTest" style={{ fontWeight: "bold", color: "red",fontSize:'15px' }}>*</label>
                <label  for="sleepTest" style={{ fontWeight: "bold", color: "black", }}>The baseline sleep test needs to be signed</label>
              
              </Grid>

            </Grid>
            <Grid item xs={3} style={{ marginTop: '-5px' }}>

              
              <Grid style={{ marginLeft: '1rem' }}>
                  <ArrowDropDown
                    variant="outlined"
                    onClick={handleClick5}
                    aria-describedby={idpop5}
                    style={{ cursor: 'pointer' }}
                  >
                  </ArrowDropDown>
                  <Popover
                    id={idpop5}
                    open={openpop5}
                    anchorEl={anchorEl5}
                    onClose={handleClosepopOver5}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                  >
                    <div style={{ display: 'flex', flexDirection: 'column', padding: '0.5rem', background: '#356f60',}}>
                    {sleeptest == true ? (
                          <Tooltip title="View">
                            <Button
                              style={{
                                backgroundColor: disable ? 'gery' : '',
                                color: 'white',

                              }}
                              size='small'
                              disabled={disable == true ? true : false}
                              onClick={(e) => {
                                settype('sleepTest')
                                handleOpen()
                              }}>
                             
                              <Typography
                                fontSize="medium"
                                style={{ color: "#fff", }}
                              >View Yours</Typography>

                            </Button>
                          </Tooltip>
                        ) : (
                          <>
                            <Tooltip title="Add New">
                              <Button

                                style={{
                                  backgroundColor: disable ? 'gery' : '',
                                  color: 'white',

                                }}

                                size='small'
                                disabled={disable == true ? true : false}
                                onClick={(e) => {
                                  settype('sleepTest')
                                  handleOpen()
                                }}
                              >
                                <Typography
                                  fontSize="medium"
                                  style={{ color: "#fff", }}
                                > Upload Yours
                                </Typography>
                              </Button>
                            </Tooltip>
                          </>
                        )}
                       
                    </div>
                  </Popover>
                </Grid>


            </Grid>
            

            <Grid item xs={0.5}></Grid>
            <Grid item xs={10.5} style={{ marginTop: '-20px' }}>
              <Grid container columnSpacing={1}>
              <label  for="sleepTest" style={{ fontWeight: "bold", color: "black", marginLeft:'8px' }}>
              ● AHI must be between 5-29 (under 5 carriers will not approve as this is not considered valid sleep apnea)
              </label>
              
              </Grid>

            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={0.5}></Grid>
            <Grid item xs={8.5}>
              <Grid container columnSpacing={1}>
                <input
                  name="lomn"
                  size="small"
                  disabled={disable == true ? true : false}
                  type="checkbox"
                  {...register("lomn")}
                  onChange={e => {
                    setValue('lomn', e.target.checked)
                    setLomn('lomn',e.target.checked)
                  }}
                  id="LOMN"
                  style={{
                    marginLeft: "10px",
                    marginRight: "10px",
                    marginTop:"-10px"
                  }}
                />
                 <label  for="LOMN" style={{ fontWeight: "bold", color: "red",fontSize:'15px' }}>*</label>
                <label  for="LOMN" style={{ fontWeight: "bold", color: "black", }}>LOMN (Letter of Medical Necessity)</label>

                
              </Grid>

            </Grid>
            <Grid item xs={3} style={{ marginTop: '-5px' }}>
                <Grid style={{ marginLeft: '1rem' }}>
                  <ArrowDropDown
                    variant="outlined"
                    onClick={handleClick1}
                    aria-describedby={idpop1}
                    style={{ cursor: 'pointer' }}
                  >
                  </ArrowDropDown>
                  <Popover
                    id={idpop1}
                    open={openpop1}
                    anchorEl={anchorEl1}
                    onClose={handleClosepopOver1}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                  >
                    <div style={{ display: 'flex', flexDirection: 'column', padding: '0.5rem', background: '#356f60',}}>
                      {/* {lomn == true ? (
                        <Tooltip title="View">
                          <Button
                            style={{
                              backgroundColor: disable ? 'gery' : '',
                              color: 'white',


                            }}
                            size='small'
                            disabled={disable == true ? true : false}
                            onClick={handleOpen2}>
                            <Typography
                              fontSize="medium"
                              style={{ color: "#fff", }}
                            >View Ours</Typography>

                          </Button>
                        </Tooltip>
                      ) : (
                        <>
                          <Tooltip title="Add New">
                            <Button
                              style={{
                                backgroundColor: disable ? 'gery' : '',
                                color: 'white',

                              }}
                              size='small'
                              disabled={disable == true ? true : false}
                              onClick={handleOpen2}>
                              <Typography
                                fontSize="medium"
                                style={{ color: "#fff",}}
                              >Upload Ours</Typography>

                            </Button>
                          </Tooltip>
                        </>
                      )} */}
                        {lomn == true ? (
                          <Tooltip title="View">
                            <Button
                              style={{
                                backgroundColor: disable ? 'gery' : '',
                                color: 'white',

                              }}
                              size='small'
                              disabled={disable == true ? true : false}
                              onClick={(e) => {
                                settype('lomn')
                                handleOpen()
                              }}>
                             
                              <Typography
                                fontSize="medium"
                                style={{ color: "#fff", }}
                              >View Yours</Typography>

                            </Button>
                          </Tooltip>
                        ) : (
                          <>
                            <Tooltip title="Add New">
                              <Button

                                style={{
                                  backgroundColor: disable ? 'gery' : '',
                                  color: 'white',

                                }}

                                size='small'
                                disabled={disable == true ? true : false}
                                onClick={(e) => {
                                  settype('lomn')
                                  handleOpen()
                                }}
                              >
                                <Typography
                                  fontSize="medium"
                                  style={{ color: "#fff", }}
                                > Upload Yours
                                </Typography>
                              </Button>
                            </Tooltip>
                          </>
                        )}
                    </div>
                  </Popover>
                </Grid>


            </Grid>
            
            <Grid item xs={0.5}></Grid>
            <Grid item xs={10.5} style={{ marginTop: '-20px' }}>
              <Grid container columnSpacing={1}>
              <label  for="LOMN" style={{ fontWeight: "bold", color: "black",marginLeft:'8px' }}> ● This may come from the sleep test facility Dr. or the patient's Primary care Dr.</label>
               
              </Grid>

            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={0.5}></Grid>
            <Grid item xs={8.5}>
              <Grid container columnSpacing={1}>
                <input
                  name="progressNotes"
                  size="small"
                  type="checkbox"
                  disabled={disable == true ? true : false}
                  {...register("progressNotes")}
                  id="progressNotes"
                  style={{
                    marginLeft: "10px",
                    marginRight: "10px",
                    marginTop:"-10px"
                  }}

                />
                 <label  for="progressNotes" style={{ fontWeight: "bold", color: "black", }}>Progress notes</label>
                
              </Grid>

            </Grid>
            <Grid item xs={3} style={{ marginTop: '-5px' }}>

              
              <Grid style={{ marginLeft: '1rem' }}>
                  <ArrowDropDown
                    variant="outlined"
                    onClick={handleClick6}
                    aria-describedby={idpop6}
                    style={{ cursor: 'pointer' }}
                  >
                  </ArrowDropDown>
                  <Popover
                    id={idpop6}
                    open={openpop6}
                    anchorEl={anchorEl6}
                    onClose={handleClosepopOver6}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                  >
                    <div style={{ display: 'flex', flexDirection: 'column', padding: '0.5rem', background: '#356f60',}}>
                    {processnote == true ? (
                          <Tooltip title="View">
                            <Button
                              style={{
                                backgroundColor: disable ? 'gery' : '',
                                color: 'white',

                              }}
                              size='small'
                              disabled={disable == true ? true : false}
                              onClick={(e) => {
                                settype('progressNotes')
                                handleOpen()
                              }}>
                             
                              <Typography
                                fontSize="medium"
                                style={{ color: "#fff", }}
                              >View Yours</Typography>

                            </Button>
                          </Tooltip>
                        ) : (
                          <>
                            <Tooltip title="Add New">
                              <Button

                                style={{
                                  backgroundColor: disable ? 'gery' : '',
                                  color: 'white',

                                }}

                                size='small'
                                disabled={disable == true ? true : false}
                                onClick={(e) => {
                                  settype('progressNotes')
                                  handleOpen()
                                }}
                              >
                                <Typography
                                  fontSize="medium"
                                  style={{ color: "#fff", }}
                                > Upload Yours
                                </Typography>
                              </Button>
                            </Tooltip>
                          </>
                        )}
                       
                    </div>
                  </Popover>
                </Grid>


            </Grid>
           
            <Grid item xs={0.5}></Grid>
            <Grid item xs={10.5} style={{ marginTop: '-20px' }}>
              <Grid container columnSpacing={1}>
              <label  for="progressNotes" style={{ fontWeight: "bold", color: "black",marginLeft:'8px' }}> ● Initial consult/ SOAP note with the patient.</label>
               
              </Grid>

            </Grid>
            <Grid item xs={1}></Grid>
          
            {/* {questionnaire == true && history == true && lomn == true ? (
         
            ) : (<></>)} */}
              
          

            {/* <Grid item xs={0.5}></Grid>

            <Grid item xs={7.5}>
              <Grid container columnSpacing={1}>
                <input
                  name="approved"
                  size="small"
                  disabled={usertype == 'Front Office' || disable == true ? true : false}
                  // disabled={disable == true?true:false }
                  type="checkbox"
                  {...register("approved")}
                  id="approved"
                  style={{
                    marginLeft: "10px",
                    marginRight: "10px",
                  }}
                />

                <Typography style={{ fontWeight: "bold", color: "black" }}>
                  Approved
                </Typography>
              </Grid>

            </Grid>
            <Grid item xs={3} style={{ marginTop: '-5px' }}>
             
               
<Grid style={{ marginLeft: '1rem' }}>
                  <ArrowDropDown
                    variant="outlined"
                    onClick={handleClick4}
                    aria-describedby={idpop4}
                    style={{ cursor: 'pointer' }}
                  >
                  </ArrowDropDown>
                  <Popover
                    id={idpop4}
                    open={openpop4}
                    anchorEl={anchorEl4}
                    onClose={handleClosepopOver4}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                  >
                    <div style={{ display: 'flex', flexDirection: 'column', padding: '0.5rem', background: '#356f60',}}>
                      {approve == true ? (
                        <Tooltip title="View">
                          <Button
                            style={{
                              backgroundColor: disable ? 'gery' : '',
                              color: 'white',


                            }}
                            size='small'
                            disabled={disable == true ? true : false}
                            onClick={handleOpen4}>
                            <Typography
                              fontSize="medium"
                              style={{ color: "#fff", }}
                            >View Ours</Typography>

                          </Button>
                        </Tooltip>
                      ) : (
                        <>
                          <Tooltip title="Add New">
                            <Button
                              style={{
                                backgroundColor: disable ? 'gery' : '',
                                color: 'white',

                              }}
                              size='small'
                              disabled={disable == true ? true : false}
                              onClick={handleOpen4}>
                              <Typography
                                fontSize="medium"
                                style={{ color: "#fff",}}
                              >Upload Ours</Typography>

                            </Button>
                          </Tooltip>
                        </>
                      )}
                       
                    </div>
                  </Popover>
                </Grid>

            </Grid>
            <Grid item xs={1}></Grid> */}
             <Grid item xs={0.5}></Grid>
            <Grid item xs={8.5}>
              <Grid container columnSpacing={1}>
                <input
                  name="additionalInfo"
                  size="small"
                  type="checkbox"
                  disabled={disable == true ? true : false}
                  {...register("additionalInfo")}
                  id="additionalInfo"
                  style={{
                    marginLeft: "10px",
                    marginRight: "10px",
                    marginTop:"-10px"
                  }}

                />
                <label  for="additionalInfo" style={{ fontWeight: "bold", color: "black", }}> Any additional information you have or think would be helpful to obtain </label>
             
              </Grid>

            </Grid>
            <Grid item xs={3} style={{ marginTop: '-5px' }}>
              <Grid style={{ marginLeft: '1rem' }}>
                  <ArrowDropDown
                    variant="outlined"
                    onClick={handleClick9}
                    aria-describedby={idpop9}
                    style={{ cursor: 'pointer' }}
                  >
                  </ArrowDropDown>
                  <Popover
                    id={idpop9}
                    open={openpop9}
                    anchorEl={anchorEl9}
                    onClose={handleClosepopOver9}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                  >
                    <div style={{ display: 'flex', flexDirection: 'column', padding: '0.5rem', background: '#356f60',}}>
                    {additionalinfo == true ? (
                          <Tooltip title="View">
                            <Button
                              style={{
                                backgroundColor: disable ? 'gery' : '',
                                color: 'white',

                              }}
                              size='small'
                              disabled={disable == true ? true : false}
                              onClick={(e) => {
                                settype('additionalInfo')
                                handleOpen()
                              }}>
                             
                              <Typography
                                fontSize="medium"
                                style={{ color: "#fff", }}
                              >View Yours</Typography>

                            </Button>
                          </Tooltip>
                        ) : (
                          <>
                            <Tooltip title="Add New">
                              <Button

                                style={{
                                  backgroundColor: disable ? 'gery' : '',
                                  color: 'white',

                                }}

                                size='small'
                                disabled={disable == true ? true : false}
                                onClick={(e) => {
                                  settype('additionalInfo')
                                  handleOpen()
                                }}
                              >
                                <Typography
                                  fontSize="medium"
                                  style={{ color: "#fff", }}
                                > Upload Yours
                                </Typography>
                              </Button>
                            </Tooltip>
                          </>
                        )}
                       
                    </div>
                  </Popover>
                </Grid>


            </Grid>
         
            <Grid item xs={0.5}></Grid>
            <Grid item xs={9.5} style={{ marginTop: '-20px' }}>

              <Grid container columnSpacing={1}>
                <label  for="additionalInfo" style={{ fontWeight: "bold", color: "black",marginLeft:'8px' }}> authorization? </label>
              
              </Grid>

            </Grid>
            <Grid item xs={2}></Grid>
            <Grid item xs={0.5}></Grid>
          <Grid item xs={8.5}>
              <Grid container columnSpacing={1}>
                <input
                  name="authRequest"
                  size="small"
                  type="checkbox"
                  disabled={disable == true ? true : false}
                  {...register("authRequest")}
                  id="authRequest"
                  style={{
                    marginLeft: "10px",
                    marginRight: "10px",
                    marginTop:"-10px"
                  }}
                />
                {/* && getValues('questionnaire') == 'on'&& getValues('healthHistory') == 'on' &&  getValues('sleepTest') == 'on' &&   getValues('lomn')== 'on'  */}
                {getValues('idCard') == true && getValues('questionnaire') == true && getValues('healthHistory') == true && getValues('sleepTest') == true && getValues('lomn') == true ?(<>
                  
                  <label  for="authRequest" style={{ fontWeight: "bold", color: "green" }}> VOB/ Prior-auth Request form </label>
                  {/* <Typography style={{ fontWeight: "bold", color: "green" }}>
                  VOB/ Prior-auth Request form
                </Typography> */}
                
                </>)
                :(
                <>
                  <label  for="authRequest" style={{ fontWeight: "bold", color: "red" }}> VOB/ Prior-auth Request form </label>

                {/* <Typography style={{ fontWeight: "bold", color: "red" }}>
                  VOB/ Prior-auth Request form
                </Typography> */}
                </>)}
                
              </Grid>

            </Grid>
            <Grid item xs={3} style={{ marginTop: '-5px' }}>
                {authRequest == true ? (
                 <Tooltip title="View">
                <Button
                  style={{
                    // && questionnaire && history && sleeptest && lomn
                    // backgroundColor: idcard && questionnaire  ? 'green' : 'red',
                    backgroundColor:  getValues('idCard')== true  && getValues('questionnaire') == true && getValues('healthHistory') == true && getValues('sleepTest') == true && getValues('lomn') == true ? 'green' : 'red',
                    color: 'white',

                  }}
                  size='small'
                  // && questionnaire == true && history == true && sleeptest ==true  && lomn ==true 
                  disabled={getValues('idCard')== true && getValues('questionnaire')  == true && getValues('healthHistory') == true && getValues('sleepTest') == true && getValues('lomn') == true ? false : true}
                  onClick={handleOpen3}>
                  <RemoveRedEyeIcon
                    fontSize="medium"
                    style={{ color: "white", }}
                  ></RemoveRedEyeIcon>

                </Button>
                </Tooltip>
              ) : (
                <>
                 <Tooltip title="Add New">
                  <Button
                  // && questionnaire && history && sleeptest && lomn
                    style={{
                      backgroundColor: getValues('idCard')== true  && getValues('questionnaire') == true && getValues('healthHistory') == true && getValues('sleepTest') == true && getValues('lomn') == true ? 'green' : 'red',
                      color: 'white',

                    }}
                    size='small'
                    // && questionnaire == true && history == true && sleeptest ==true && lomn ==true
                    disabled={ getValues('idCard')== true  && getValues('questionnaire') == true && getValues('healthHistory') == true && getValues('sleepTest') == true && getValues('lomn') == true ? false : true}
                    onClick={handleOpen3}>
                    <AddIcon
                      fontSize="medium"
                      style={{ color: "white", paddingRight: "5px" }}
                    ></AddIcon>

                  </Button>
                  </Tooltip>
                </>
                )}
                {/* <Grid style={{ marginLeft: '1rem' }}>
                  <ArrowDropDown
                    variant="outlined"
                    onClick={handleClick2}
                    aria-describedby={idpop2}
                    style={{ cursor: 'pointer' }}
                  >
                  </ArrowDropDown>
                  <Popover
                    id={idpop2}
                    open={openpop2}
                    anchorEl={anchorEl2}
                    onClose={handleClosepopOver2}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                  >
                    <div style={{ display: 'flex', flexDirection: 'column', padding: '0.5rem', background: '#356f60',}}>
                      {authRequest == true ? (
                        <Tooltip title="View">
                          <Button
                            style={{
                              backgroundColor: disable ? 'gery' : '',
                              color: 'white',


                            }}
                            size='small'
                            disabled={disable == true ? true : false}
                            onClick={handleOpen3}>
                            <Typography
                              fontSize="medium"
                              style={{ color: "#fff", }}
                            >View Ours</Typography>

                          </Button>
                        </Tooltip>
                      ) : (
                        <>
                          <Tooltip title="Add New">
                            <Button
                              style={{
                                backgroundColor: disable ? 'gery' : '',
                                color: 'white',

                              }}
                              size='small'
                              disabled={disable == true ? true : false}
                              onClick={handleOpen3}>
                              <Typography
                                fontSize="medium"
                                style={{ color: "#fff",}}
                              >Upload Ours</Typography>

                            </Button>
                          </Tooltip>
                        </>
                      )}
                        {authRequest == true ? (
                          <Tooltip title="View">
                            <Button
                              style={{
                                backgroundColor: disable ? 'gery' : '',
                                color: 'white',

                              }}
                              size='small'
                              disabled={disable == true ? true : false}
                              onClick={(e) => {
                                settype('authRequest')
                                handleOpen()
                              }}>
                             
                              <Typography
                                fontSize="medium"
                                style={{ color: "#fff", }}
                              >View Yours</Typography>

                            </Button>
                          </Tooltip>
                        ) : (
                          <>
                            <Tooltip title="Add New">
                              <Button

                                style={{
                                  backgroundColor: disable ? 'gery' : '',
                                  color: 'white',

                                }}

                                size='small'
                                disabled={disable == true ? true : false}
                                onClick={(e) => {
                                  settype('authRequest')
                                  handleOpen()
                                }}
                              >
                                <Typography
                                  fontSize="medium"
                                  style={{ color: "#fff", }}
                                > Upload Yours
                                </Typography>
                              </Button>
                            </Tooltip>
                          </>
                        )}
                    </div>
                  </Popover>
                </Grid> */}

              
            </Grid>
             
              <Grid item xs={0.5}> <Typography style={{ fontWeight: "bold", color: "green" }}>
           
                </Typography></Grid>
            <Grid item xs={9.5} style={{ marginTop: '-20px' }}>
              <Grid container columnSpacing={1}>
              <label  for="authRequest" style={{ fontWeight: "bold", color: "black",marginLeft:"8px" }}> ● Name, Date of Birth & address MUST be correct or the carrier may reject any submitted requests. </label>
               
              </Grid>

            </Grid>
            <Grid item xs={2}></Grid>
            <Grid item xs={12} lg={12} sm={12} >
            <Button
              className="Button"
              onClick={handleSubmit(onSubmit)}
              disabled={FirstClick}
              // disabled={submitting}
              variant="gradient"
              style={{
                // marginTop: "-15px",
                marginBottom: "50px",
                marginRight: "20px",
                float: "right",
                // marginLeft: "850px",
                width: "80px",
                height: "35px",
                // backgroundColor: "#E87426",
                // backgroundColor: "linear-gradient(180deg, rgb(209, 52, 80) 0%, rgb(209, 52, 80) 0%, rgb(240, 130, 49) 100%",
                color: "white",
                fontSize: "14px",
              }}
            >

              <SaveOutlinedIcon
                fontSize="medium"
                style={{ color: "white", paddingRight: "5px" }}
              ></SaveOutlinedIcon>
              Save
            </Button>
            {/* <Button
              className="Button"
              onClick={handleOpen4}
              disabled={disable == true ? true : false }
            
              variant="gradient"
              style={{
           
                marginBottom: "50px",
                marginRight: "20px",
                float: "right",
                width: "100px",
                height: "35px",
                color: "white",
                fontSize: "14px",
              }}
            >
              Approve
            </Button> */}
            
          </Grid>

          </Grid>
        </Grid>

        {/* <Grid container>
          <Grid item xs={12} lg={12} sm={12}>
            <Button
              className="Button"
              onClick={handleSubmit(onSubmit)}
              disabled={disable == true ? true : false}
              variant="gradient"
              style={{
                // marginTop: "10px",
                marginBottom: "20px",
                marginRight: "20px",
                float: "right",
                // marginLeft: "850px",
                width: "80px",
                height: "35px",
                // backgroundColor: "#E87426",
                // backgroundColor: "linear-gradient(180deg, rgb(209, 52, 80) 0%, rgb(209, 52, 80) 0%, rgb(240, 130, 49) 100%",
                color: "white",
                fontSize: "14px",
              }}
            >

              <SaveOutlinedIcon
                fontSize="medium"
                style={{ color: "#356F60", paddingRight: "5px" }}
              ></SaveOutlinedIcon>
              Save
            </Button>
          </Grid>

        </Grid> */}
      </Container>
    </>
  )
}

export default AuthorizationRegistration
