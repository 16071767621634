import React, { useState } from "react";
import {
  Box,
  TextField,
  Typography,
  Button,
  Container,
  Grid,
  CircularProgress,
  InputAdornment,
  IconButton
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import axios from "axios";
import md5 from "md5";
import { Visibility, VisibilityOff, CheckCircleOutline, CancelOutlined } from "@mui/icons-material";

const ResetPassword = () => {
  const navigate = useNavigate();

  const styles = {
    container: {
      minHeight: "100vh",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "20px",
      background: `url("/backGround.jpg") no-repeat center center/cover`,

    },
    innerContainer: {
      backgroundColor: "#ffffff",
      borderRadius: "16px",
      padding: "40px",
      boxShadow: "0 8px 20px rgba(0, 0, 0, 0.1)",
      maxWidth: "480px",
      width: "100%",
      textAlign: "center",
      position: "relative",
    },
    backButton: {
      position: "absolute",
      top: "16px",
      left: "16px",
      backgroundColor: "#f0f0f0",
      color: "#555",
      "&:hover": {
        backgroundColor: "#e0e0e0",
      },
    },
    formTitle: {
      color: "#333333",
      marginBottom: "24px",
      fontWeight: "bold",
      fontSize: "28px",
      letterSpacing: "0.5px",
    },
    
    nextButton: {
      background: "#4CAF50",
      color: "white",
      padding: "12px 20px",
      borderRadius: "8px",
      fontSize: "16px",
      fontWeight: "bold",
      marginTop: "20px",
      "&:hover": {
        background: "#388E3C",
      },
    },
    errorText: {
      color: "#f44336",
      fontSize: "14px",
      textAlign: "left",
      marginTop: "-10px",
      marginBottom: "10px",
    },
    textField: {
      borderRadius: '4px',
      marginBottom: '10px',
      '& .MuiOutlinedInput-root': {
        background: 'green', // Green background color for the input
        borderRadius: '4px',
        height: '40px', // Set height of the input
        '& fieldset': {
          borderColor: 'rgba(0, 0, 0, 0.23)', // Optional: Adjust border color
        },
        '&:hover fieldset': {
          borderColor: 'darkgreen', // Optional: Adjust hover border color
        },
        '&.Mui-focused fieldset': {
          borderColor: 'darkgreen', // Optional: Adjust focus border color
        },
      },
      '& .MuiInputBase-input': {
        fontSize: '14px', // Adjust font size
        // padding: '10px', // Add padding for better alignment
      },
    },
  };

  const [step, setStep] = useState(1);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [username, setUsername] = useState("");
  const [Otp, setOtp] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [loading, setLoading] = useState(false);

  const handleNext = async (username) => {
    if (!username) {
      Swal.fire({
        icon: "error",
        title: "Oops!",
        text: "Please enter a Username.",
      });
      return;
    }

    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/Users/GetResetRequest?userName=${username}`
      );

      Swal.fire({
        icon: "success",
        title: "Request Sent",
        text: "OTP sent to the registered email address.",
      });

      setStep(2);
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "An error occurred. Please try again later.",
      });
      console.error("Error during API call:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleOtp = async () => {
    if (!Otp) {
      Swal.fire({
        icon: "warning",
        title: "OTP is Required!",
        text: "OTP is Required!",
      });
      return;
    }
    setLoading(true);

    const parsedOtp = parseInt(Otp, 10);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/Users/ConfirmOtp?userName=${username}&otp=${parsedOtp}`
      );
      Swal.fire({
        icon: "success",
        title: "Verification Successful",
        text: "You have been successfully Verified!",
      });
      setStep(step + 1);
    } catch (error) {
      console.error("Error:", error);
      Swal.fire({
        icon: "error",
        title: "Verification Failed",
        text: "Invalid or expired OTP.",
      });
    } finally {
      setLoading(false);
    }
  };

  const validateFields = () => {
    let isValid = true;

    if (!password) {
      setPasswordError("Password is required.");
      isValid = false;
    } else {
      setPasswordError("");
    }

    if (!confirmPassword) {
      setConfirmPasswordError("Confirm Password is required.");
      isValid = false;
    } else if (password !== confirmPassword) {
      setConfirmPasswordError("Passwords do not match.");
      isValid = false;
    } else {
      setConfirmPasswordError("");
    }

    return isValid;
  };

  const handlePassword = async () => {
    if (!validateFields()) return;
    setLoading(true);
    const hashedPassword = md5(password).toString();
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/Users/Reset-Password?username=${username}&password=${hashedPassword}`
      );
      Swal.fire({
        icon: "success",
        title: "Password Reset Successful",
        text: "You can now log in with your new password.",
      });
      navigate("/login");
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "An error occurred while resetting your password. Please try again later.",
      });
      console.error("Error during API call:", error);
    } finally {
      setLoading(false);
    }
  };
  const [showPassword, setShowPassword] = React.useState(false); // Password visibility toggle
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
  return (
    <Container maxWidth={false} style={styles.container}>
      <Box style={styles.innerContainer}>
    
        <IconButton
          // onClick={() => setStep((prevStep) => Math.max(1, prevStep - 1))}
          onClick={() => {
            if (step === 1) {
              navigate('/login'); // Navigate to the login page
            } else {
              setStep((prevStep) => Math.max(1, prevStep - 1));
            }
          }}
          style={styles.backButton}
        >
          <ArrowBackIcon />
        </IconButton>
        {step === 1 && (
          <>
            <Typography style={styles.formTitle}>Enter Username</Typography>
            <TextField
              fullWidth
              label="Username"
              variant="outlined"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              style={styles.textField}
            />
            <Button
              onClick={() => handleNext(username)}
              variant="contained"
              fullWidth
              style={styles.nextButton}
              disabled={loading}
            >
              {loading ? (
                <CircularProgress size={24} style={{ color: "#fff" }} />
              ) : (
                "Next"
              )}
            </Button>
          </>
        )}
        {step === 2 && (
          <>
            <Typography style={styles.formTitle}>Verify Your OTP</Typography>
            <TextField
              fullWidth
              label="Username"
              variant="outlined"
              InputProps={{readOnly :true}}
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              style={styles.textField}
            />
            <TextField
              fullWidth
              label="OTP"
              variant="outlined"
              value={Otp}
              onChange={(e) => setOtp(e.target.value)}
              style={styles.textField}
            />
            <Button
              onClick={handleOtp}
              variant="contained"
              fullWidth
              style={styles.nextButton}
              disabled={loading}
            >
              {loading ? (
                <CircularProgress size={24} style={{ color: "#fff" }} />
              ) : (
                "Next"
              )}
            </Button>
          </>
        )}
        {step === 3 && (
          <>
            <Typography style={styles.formTitle}>Reset Your Password</Typography>
            <TextField
              fullWidth
              label="Password"
              type={showPassword ? 'text' : 'password'}

              variant="outlined"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              style={styles.textField}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onMouseDown={() => setShowPassword(true)}
                      onMouseUp={() => setShowPassword(false)}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {passwordError && (
              <Typography style={styles.errorText}>{passwordError}</Typography>
            )}
            <TextField
              fullWidth
              label="Confirm Password"
              variant="outlined"
              value={confirmPassword}
              type={showConfirmPassword ? 'text' : 'password'}
              onChange={(e) => setConfirmPassword(e.target.value)}
              style={styles.textField}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onMouseDown={() => setShowConfirmPassword(true)}
                      onMouseUp={() => setShowConfirmPassword(false)}
                    >
                      {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
            {confirmPasswordError && (
              <Typography style={styles.errorText}>
                {confirmPasswordError}
              </Typography>
            )}
            <Button
              onClick={handlePassword}
              variant="contained"
              fullWidth
              style={styles.nextButton}
              disabled={loading}
            >
              {loading ? (
                <CircularProgress size={24} style={{ color: "#fff" }} />
              ) : (
                "Save"
              )}
            </Button>
          </>
        )}
      </Box>
    </Container>
  );
};

export default ResetPassword;
