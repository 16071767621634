import { useCallback, useMemo, useState, useEffect } from "react";
import React from "react";
import * as Yup from "yup";
import axios from "axios";
// Radio Test
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
// radio end
import ReactSelect from "react-select";
import { useNavigate } from "react-router-dom";
import Moment from 'moment'
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAlert } from "react-alert";
import Select from 'react-select';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./Button.css"


import {
  Box,
  Button,
  Container,
  Link,
  SvgIcon,
  Typography,
  Grid,
  Snackbar,
  TextField,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import { PropaneSharp } from "@mui/icons-material";

const VOBPAGE1 = (props) => {
console.log(props,'VOBPAGE1 ki props')
  const location = useLocation();
  const [columnID, setcolumnID] = useState(props.columnID !== undefined ? props.columnID : 0)
  const [Formdid, setFormdid] = useState( props.callingFrom == 'Patientvob' ? props.columnID:  props.formDid !== undefined ? props.formDid : 0)
  // const Formdid = props.formDid;
  // const columnID = props.columnID
  // const [AutpattientID, setAutpattientID] = useState(props.callingFrom=="Pateint"?props.AutpattientID:props.PatientId)
  // const patientId = props.pid;
  const pname = props.PatientName
  const [patName, setPatName] = useState(props.callingFrom == 'dashboard' ? props.Dashboard : pname);
  // const [patientId, setpatientId] = useState(props.PatientId)
  const patientId = (props.callingFrom == "update" ? props.patientid : props.PatientId)
  const [AutpattientID, setAutpattientID] = useState(props.AutpattientID)
  console.log('test', AutpattientID)
  const [authID, setAuthID] = React.useState("");
  const [patientName, setpatientName] = useState(props.callingFrom == 'AuthRequest' ?props.AuthRequestpatientName:props.patientName);
  console.log('AuthRequestpatientName', props.AuthRequestpatientName)

  const [iD, setId] = useState()
  const [newiD, setnewId] = useState()

  const [doB, setDOB] = React.useState(Moment().format('YYYY-MM-DD'))
  const dOB = props.callingFrom == "Patients" ? props.DOB : doB;
  const [providerr, setProvider] = useState()
  const [addnotes, setAddNotes] = useState()
  const [aDDress, setAddress] = useState("")
  // const aDDress = props.callingFrom == "Patients" ? props.Address : addres;
  const [procode1, setProcode1] = useState()
  const [dianosticc1, setDiagnostic1] = useState()
  // const [chargee1, setCharge1] = useState()
  const [Charge1, setCharge1] = useState("");
  const [procode2, setProcode2] = useState()
  const [dianosticc2, setDiagnostic2] = useState()
  const [chargee2, setCharge2] = useState()
  const [procode3, setProcode3] = useState()
  const [dianosticc3, setDiagnostic3] = useState()
  const [chargee3, setCharge3] = useState()
  const [selectpatid, setSelectPatId] = useState('');
  const fields = [
    "id",
    "dob",
    "providerName",
    "patientName",
    "patientId",
    "address",
    "procedureCode1",
    "procedureCode2",
    "procedureCode3",
    "diagnosisCode1",
    "diagnosisCode2",
    "diagnosisCode3",
    "charge1",
    "charge2",
    "charge3",
    "practiceId",
    "inactive",
    "createdBy",
    "createdDate",
    "updatedBy",
    "updatedDate",
    "clientCreatedDate",
    "clientUpdatedDate",
    "additionalNotes",
    "provider_id",
    "cpap" , 
    "appliance",
    "balanceBill",
'claimProcesses',
"gap",
  ];

  const defValues = {
    id: 0,
    dob: "",
    providerName: "",
    patientName: "",
    patientId: "",
    address: "",
    // address: email,
    procedureCode1: "",
    procedureCode2: "",
    procedureCode3: "",
    diagnosisCode1: "",
    diagnosisCode2: "",
    diagnosisCode3: "",
    charge1: "",
    charge2: "",
    charge3: "",
    practiceId: "",
    inactive: "",
    createdBy: "",
    createdDate: "",
    updatedBy: "",
    updatedDate: "",
    clientCreatedDate: "",
    clientUpdatedDate: "",
    additionalNotes: "",
    provider_id:"",
    cpap:"" ,
     appliance:"",
     balanceBill:"",
claimProcesses:"",
gap:""
  };

 // API Authanticatiion
 const token = localStorage.getItem('Token')
 const auth = `Bearer ${token}`
 const headers = {
   Authorization: auth
 }
 

  const today = new Date();

  const navigate = useNavigate();
  // const newId = location.state.id;
  const formid = props.formId;
  console.log('forrm Id, consola', formid)

  //     console.log('patient Id', patientId)

  const [submitting, setSubmitting] = useState(false);
  const [disable, setdisable] = React.useState(false)

  var startDate = new Date();
  startDate.setFullYear(startDate.getFullYear() - 150);
  // const validationSchema = Yup.object({
  //   firstName: Yup.string().required("First Name is required"),
  //   dob: Yup.date()
  //     .required("dob is required")
  //     .nullable()
  //     .transform((v) => (v instanceof Date && !isNaN(v) ? v : null))
  //     .max(today, "You can't be born in the future!")
  //     .min(startDate, "You can't be that much old!"),
  // });

  const {
    register,
    control,
    handleSubmit,
    setValue,
    reset,
    getValues,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: false,
    shouldUseNativeValidation: false,
    delayError: undefined,
    // resolver: yupResolver(validationSchema),
    defaultValues: defValues,
  });
  const [patientdob, setpatientdob] = React.useState(Moment().format('YYYY-MM-DD'),)
 
  const [patientiaddress, setpatientaddress] = useState("");
  const [patientcity, setpatientcity] = useState("");
  const [patientsate, setpatientsate] = useState("");
  const [patientzipcode, setpatientzipcode] = useState("");
  const [patientDetails, setPatientDetails] = useState({
    address: "",
    city: "",
    state: "",
    zipcode: "",
  });
 

console.log(patientiaddress,'patientiaddress')
 
useEffect(() => {
  axios
    .get(
      `${process.env.REACT_APP_API_URL}/Patients/SearchInsuranceName?patientId=${AutpattientID}`,
      {
        headers,
      }
    )
    .then((response) => {
      // console.log('testname',response[0].dob)
      var dateYMD = Moment(response.dob).format('YYYY-MM-DD')
      // setpatientdob(dateYMD)
      // setpatientdob(response.dob)
      setpatientdob(Moment(response.dob).format('YYYY-MM-DD'));
      setpracticeID(response.practiceId)
      // setpatientcity(response.city)
      // setpatientsate(response.state)
      // setpatientzipcode(response.zipCode)
      // setpatientaddress(response.address)
      setPatientDetails({
        address: response.address,
        city: response.city,
        state: response.state,
        zipcode: response.zipCode,
      });
     
      // setRefresh(!refresh)
    })

    .catch((error) => {
      console.error("There was an error!", error);
    });
}, [AutpattientID]);
  useEffect(() => {

    axios
      .get(
        `${process.env.REACT_APP_API_URL}/AutherizationRequest/PriorAuthRequestByFormId?formId=${Formdid}&authId=${columnID}`,
        { headers }
      )
      .then(response => {
        console.log('response.dataaaaa: ', response.address
        )
        fields.forEach(field => {
          setValue(field, response[field])
        })
        setId(response.patientId)
        // setFormDataId(response.formId)
        setnewId(response.id)
        setPatName(response.patientName)
        setProvider(response.providerName)
        // var dateYMD = Moment(response.dob).format('YYYY-MM-DD')
        // setpatientdob(dateYMD)
        // setpatientaddress(response.address)
        setProcode1(response.procedureCode1)
        setDiagnostic1(response.diagnosisCode1)
        setCharge1(response.charge1)
        setProcode2(response.diagnosisCode2)
        setDiagnostic2(response.diagnosisCode2)
        setCharge2(response.charge2)
        setProcode3(response.procedureCode3)
        setDiagnostic3(response.diagnosisCode3)
        setCharge3(response.charge3)
        setAddNotes(response.additionalNotes)
        setAuthID(response.authId)
      })

      .catch(error => {
        console.error('There was an error!', error)
      })

    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, [Formdid, columnID])
  const alert = useAlert();
  const [providerIdError, setProviderIdError] = useState(false);
  const [procedureCode1Error, setProcedureCode1Error] = useState(false);
  const [diagnosisCode1Error, setDiagnosisCode1Error] = useState(false);
  const [charge1Error, setCharge1Error] = useState(false);
  const onSubmit = (data) => {
    setSubmitting(true);
    setdisable(true)
    
    const postData = data;
    // postData.id=0
    { props.callingFrom == "Patient" || props.callingFrom == 'AuthRequest' ? postData.patientName = patientName : postData.patientName = pname }
    { props.callingFrom == "Patient" || props.callingFrom == 'AuthRequest' ? postData.patientId = AutpattientID : postData.patientId = patientId }
    { props.callingFrom == "Patient" || props.callingFrom == 'AuthRequest' ? postData.id = newiD : postData.id = newiD }


    // postData.patientId = AutpattientID; 
    postData.formId = Formdid;
    postData.authId = columnID;
    postData.dob = dOB;
    postData.charge1= Charge1
    postData.charge2= chargee2
    postData.charge3= chargee3

    let validationFailed = false;

    // Validate each field and set the error flags
    if (!data.provider_id) {
      setProviderIdError(true);
      validationFailed = true;
    }
    if (!data.procedureCode1) {
      setProcedureCode1Error(true);
      validationFailed = true;
    }
    if (!data.diagnosisCode1) {
      setDiagnosisCode1Error(true);
      validationFailed = true;
    }
    if (!data.charge1) {
      setCharge1Error(true);
      validationFailed = true;
    }
  
    // If any validation failed, stop submission
    if (validationFailed) {
      setSubmitting(false);
      setdisable(false);
      return;
    }


    // postData.address = aDDress;
    // postData.patientName= patientName
    console.log('postData', postData)
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/AutherizationRequest/AddPriorAuthRequest`,
        postData, { headers, }
      )
      .then((response) => {
        if (response.id) {
          console.log('addNew Forme', response)
          setSubmitting(false);
          toast.success('Record saved successfully', {
            position: "bottom-center",
            // autoClose: 2500,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "light",
            onClose: () => {
              if (props.callingFrom == 'Patient') {
                props.updateHealthHistory(response.id, response.authId, response.patientName)
               
                props.handleClose3()
                console.log('checkid', response.id)
                setdisable(false);

              }
              else if( props.callingFrom == 'AuthRequest')
              {
                props.updateHealthHistory(response.id, response.authId, response.patientName)
               
                props.handleClose3()
              }
              else if (props.callingFrom == "update") {
                // props.updateHealthHistory(response.id, response.authId, response.patientName)
                props.closevobpage()
              }
              else if (props.callingFrom == "dashboard") {
                props.updateHealthHistory(response.id, response.authId, response.patientName)
                props.closevobpage()

              }
              else if (props.callingFrom == "Patientvob") {
                props.updateHealthHistory(response.id, response.authId, response.patientName)
                props.handleClose3()
                console.log('checkid', response.id)
              }
              else {
                props.closevobpage1()
              }


            },
          });
        } else {
          setSubmitting(false);
          setdisable(false)

        }
      })
      .catch((error) => {
        setSubmitting(false);
        setdisable(false)
        alert.success(`Error ${error.message}`, {
          type: "error",
        });
      });
  };
  const UserType = localStorage.getItem('User_Type')
  
  // const practiceID=localStorage.getItem('default_paractice')
  const [providers, setProviders] = useState([]);
  const [practiceID, setpracticeID] =useState(props.AuthPracticeId)
  console.log('THis Is Testing',practiceID)
  useEffect(
    () => {
 if(practiceID){
      axios
        .get(
          `${process.env
            .REACT_APP_API_URL}/Providers/getPracticeProvider?parcticeid=${practiceID}`,
          { headers }
        )
        .then(response => {
          var prov = [];
          response.forEach(field => {
            prov.push({
              label: field.name,
              value: field.id
            });
          });
          setProviders(prov);
        })
        .catch(error => {});
      }
      // }
      // empty dependency array means this effect will only run once (like componentDidMount in classes)
    },[practiceID]);
  function handleCancel() {
    navigate("/dashboard");
  }
  
  
  const [procedureCode1ID, setProcedureCode1ID] = useState("");
  const [procedureCode1List, setProcedureCode1List] = useState([]);
  console.log('value',procedureCode1List)
  const [diagnosisID, setDiagnosisID] = useState("");
  const [diagnosisList, setDiagnosisList] = useState([]);
  
  useEffect(() => {
    // POST request using axios inside useEffect React hook
    axios
      .get(`${process.env.REACT_APP_API_URL}/Procedures/Procedures`, {
        headers
      })
      .then(response => {
        var prac = [];
        response.forEach(field => {
          prac.push({
            label: field.code,
            value: field.code,
            qasim:field.default_charges
           
          });
         
        });
        setProcedureCode1List(prac);
        
      })
      .catch(error => {});

  }, []);
  useEffect(() => {
    // POST request using axios inside useEffect React hook
    axios
      .get(`${process.env.REACT_APP_API_URL}/Diagnosis/Diagnosis`, {
        headers
      })
      .then(response => {
        var prac = [];
        response.forEach(field => {
          prac.push({
            label: field.code,
            value: field.code
          });
        });
        setDiagnosisList(prac);
        
      })
      .catch(error => {});

  }, []);
  return (
    <Grid container spacing={-2} sx={{ flexGrow: 1, rowGap: "24px" ,}}>
      <Grid xs={12}>
        {/* <Typography
          level="h1"
          style={{
            padding: "auto",
            textAlign: "center",
            fontSize: "40px",
            fontWeight: "bold",
            textDecoration: "underline",
          }}
        >
          VOB/ Prior-auth Request
        </Typography> */}
      </Grid>
      <Grid xs={12}>
        <Typography
          level="h2"
          style={{
            padding: "auto",
            textAlign: "center",
            fontSize: "20px",
            fontWeight: "bold",
          }}
        >
          Provider rendering services (name of Dr):
        </Typography>
      </Grid>
      <Grid item xs={4}></Grid>
      <Grid item xs={4}>
        <Controller
          name="provider_id"
          onChange={value => setProvider('provider_id', value)}
          {...register("provider_id")}
          control={control}
          render={({ field }) => (
            // <TextField
            //   variant="standard"
            //   placeholder="Type in here…"
            //   style={{
            //     width: "22em",
            //   }}
            //   size="small"
            //   fullWidth
            //   {...field}
            //   name="providerName"
            //   type="text"
            // />
            <ReactSelect
            {...field}
            default
            size="small"
            style={{
                   width: "22em",
               }}
            value={providers.filter(function(option) {
              return option.value === getValues("provider_id");
            })}
            onChange={value => {
              setValue("provider_id", value.value);
              setProviderIdError(false)
              // setProviders(value.value);
            }}
            options={providers}
          />
          )}
        />

        {/* <p style={{ color: 'red', fontSize: '14px' }}>{errors.emailAddress?.message}</p> */}
        {providerIdError && <p style={{ color: 'red' }}>Provider is required</p>}
      </Grid>

      <Grid item xs={4}></Grid>

      <Grid item xs={1}></Grid>
      <Grid xs={2}>
        <Typography style={{ fontSize: "18px" }}>Patient Name:</Typography>
      </Grid>
      <Grid xs={4}>
        <Controller
          name="patientName"
          // {...register("patientName")}

          control={control}
          render={({ field }) => (
            <TextField
              variant="standard"
              placeholder=" Input Name"
              style={{
                width: "22em",
              }}
              size="small"
              fullWidth
              {...field}
              name="patientName"
              type="text"
              value={props.callingFrom == 'Patient' || props.callingFrom == 'AuthRequest' ? patientName : patName}
              onChange={e => {
                setValue('patientName', e.target.value)
                setPatName(e.target.value)
                setpatientName(e.target.value)
              }}
              inputProps={{
                readOnly: true,  // Corrected prop name
              }}
            />

          )}
        />
      </Grid>
      <Grid xs={2}>
        <Typography style={{ fontSize: "18px" }}>DOB:</Typography>
      </Grid>
      <Grid xs={3}>
        <Controller
          name="dob"
          // {...register("dob")}
          control={control}
          render={({ field }) => (
            <TextField
              variant="standard"
              style={{
                width: "75%",
              }}
              size="small"
              fullWidth
              
              {...field}
              name="dob"
              type="date"
              value={patientdob}
              onChange={e => {
                setValue('dob', e.target.value)
                setpatientdob(e.target.value)
              }}
              inputProps={{
                readOnly: true,  // Corrected prop name
              }}
            />
          )}
        />
      </Grid>
      <Grid item xs={1}></Grid>
      <Grid xs={11}>
        <Typography style={{ fontSize: "18px" }}>Address:</Typography>
        <Controller
          name="address"
          // {...register("dob")}
          control={control}
          render={({ field }) => (
            <TextField
              variant="standard"
              style={{
                width: "75%",
              }}
              size="small"
              fullWidth
              {...field}
              name="address"
             
              // type="date"
              // value={patientiaddress}
              value={`${patientDetails.address}, ${patientDetails.city}, ${patientDetails.state}, ${patientDetails.zipcode}`}
              // onChange={e => {
              //   setValue('address', e.target.value)
              //   setpatientaddress(e.target.value)
              // }}
              inputProps={{
                readOnly: true,  // Corrected prop name
              }}
            />
          )}
        />

        {/* <Controller
          name="address"
          {...register("address")}
          control={control}
          render={({ field }) => (
            <TextField size="small" variant="standard" {...field} fullWidth />
          )}
        /> */}
      </Grid>

      {/* <Grid item xs={1}></Grid>
      <Grid xs={11}>
        <Typography style={{ fontSize: "18px" }}>#ID:</Typography>
        <Controller
          name="id"
          {...register("id")}
          onChange={value => setId('id', value)}
          control={control}
          render={({ field }) => (
            <TextField
              variant="standard"
              placeholder=" Input ID Code"
              style={{
                width: "30%",
              }}
              size="small"
              fullWidth
              {...field}
              name="id"
              type="text"
            />
          )}
        />
      </Grid> */}
      <Grid item xs={1}></Grid>
      <Grid item xs={11}>
        <Typography level="body 1">
          (Please send the insurance card as well in case we need to reference)
        </Typography>
      </Grid>
      <Grid xs={12}>
        <Typography
          level="h1"
          style={{
            padding: "auto",
            textAlign: "center",
            fontSize: "35px",
            fontWeight: "bold",
            textDecoration: "underline",
          }}
        >
          Codes you wish to have verified
        </Typography>
      </Grid>

      <Grid item xs={0.1} ></Grid>

      <Grid container >

        <Grid item xs={0.5} ></Grid>

        <Grid item xs={2}>
          <Typography style={{ fontSize: "16px", textDecoration: "underline", marginLeft: '1.5rem' }}>
            Procedure code:
          </Typography>
        </Grid>


        <Grid item xs={1}>
          {/* <Controller
            name='procedureCode1'
            margin='dense'
            {...register('procedureCode1')}
            control={control}
            render={({ field }) => (
              <Select
                size='small'
                {...field}
                styles={{ control: provided => ({ ...provided, width: '120px' }) }} // Set width here
                options={[
                  { value: '', label: '' },
                  { value: 'E0486', label: 'E0486' },
                  { value: '99203', label: '99203' },
                  { value: '99213', label: '99213' },
                  { value: '70355', label: '70355' },
                  { value: '99245', label: '99245' },
                  { value: '99215', label: '99215' },
                  { value: '99244', label: '99244' },
                  { value: 'K1207', label: 'K1207' },


                ]}
                value={{ label: getValues('procedureCode1') }}
                onChange={value => setValue('procedureCode1', value.label)}
              />
            )}
          /> */}
            <Controller
                    name="procedureCode1"
                    {...register("procedureCode1")}
                    control={control}
                    render={({ field }) =>
                      <ReactSelect
                        {...field}
                        fullWidth
                        value={procedureCode1List.filter(function(option) {
                          return (
                            option.value === getValues("procedureCode1")
                          );
                        })}
                        onChange={e => {
                          console.log('eeee',e)
                            setValue("procedureCode1", e.value);
                            setCharge1(e.qasim);
                            // console.log('eeee',e)
                            setProcedureCode1ID(e.value);
                            setProcedureCode1Error(false)
                           
                          }}
                          styles={{ control: provided => ({ ...provided, width: '120px' }) }}
                        options={procedureCode1List}
                      />
                     
                    }
                  />
   {procedureCode1Error && <p style={{ color: 'red' }}>Procedure required</p>}

        </Grid>

        <Grid item xs={2.5}>
          <Typography style={{ fontSize: "16px", textDecoration: "underline", marginLeft: '70px' }}>
            Diagnosis code:
          </Typography>
        </Grid>


        <Grid item xs={1}>
          
           <Controller
                    name="diagnosisCode1"
                    {...register("diagnosisCode1")}
                    control={control}
                    render={({ field }) =>
                      <ReactSelect
                        {...field}
                        fullWidth
                        value={diagnosisList.filter(function(option) {
                          return (
                            option.value === getValues("diagnosisCode1")
                          );
                        })}
                        onChange={e => {
                            setValue("diagnosisCode1", e.value);
                            setDiagnosisID(e.value);
                            setDiagnosisCode1Error(false)
                           
                          }}
                          styles={{ control: provided => ({ ...provided, width: '120px' }) }}
                        options={diagnosisList}
                      />
                     
                    }
                  />
                    {diagnosisCode1Error && <p style={{ color: 'red' }}>Diagnosis required</p>}
        </Grid>

        <Grid item xs={0.5} ></Grid>


        <Grid item xs={1.5}>
          <Typography style={{ fontSize: "16px", textDecoration: "underline", marginLeft: '1.5rem' }}>
            Charge $
          </Typography>
        </Grid>

        <Grid item xs={1}>
          <Controller
            name='charge1'
            margin='dense'
            {...register('charge1')}
            control={control}
            render={({ field }) => (
              <TextField
                size='small'
                {...field}
                 style={{width:'120px'}}
                value={Charge1}
               
              />
            )}
          />
          {charge1Error && <p style={{ color: 'red' }}>Charges required</p>}
        </Grid>




      </Grid>

      <Grid container >

        <Grid item xs={0.5} ></Grid>

        <Grid item xs={2}>
          <Typography style={{ fontSize: "16px", textDecoration: "underline", marginLeft: '1.5rem' }}>
            Procedure code:
          </Typography>
        </Grid>


        <Grid item xs={1}>
          
           <Controller
                    name="procedureCode2"
                    {...register("procedureCode2")}
                    control={control}
                    render={({ field }) =>
                      <ReactSelect
                        {...field}
                        fullWidth
                        value={procedureCode1List.filter(function(option) {
                          return (
                            option.value === getValues("procedureCode2")
                          );
                        })}
                        onChange={e => {
                            setValue("procedureCode2", e.value);
                             setCharge2(e.qasim)
                            setProcedureCode1ID(e.value);
                           
                          }}
                          styles={{ control: provided => ({ ...provided, width: '120px' }) }}
                        options={procedureCode1List}
                      />
                     
                    }
                  />
        </Grid>

        <Grid item xs={2.5}>
          <Typography style={{ fontSize: "16px", textDecoration: "underline", marginLeft: '70px' }}>
            Diagnosis code:
          </Typography>
        </Grid>


        <Grid item xs={1}>
          {/* <Controller
            name='diagnosisCode2'
            margin='dense'
            {...register('diagnosisCode2')}
            control={control}
            render={({ field }) => (
              <Select
                size='small'
                {...field}
                styles={{ control: provided => ({ ...provided, width: '120px' }) }} // Set width here
                options={[
                  { value: '', label: '' },
                  { value: 'G47.33', label: 'G47.33' },
                  { value: 'F45.8', label: 'F45.8' },
                  { value: 'M26.609', label: 'M26.609' },
                  
                ]}
                value={{ label: getValues('diagnosisCode2') }}
                onChange={value => setValue('diagnosisCode2', value.label)}
              />
            )}
          /> */}
          <Controller
                    name="diagnosisCode2"
                    {...register("diagnosisCode2")}
                    control={control}
                    render={({ field }) =>
                      <ReactSelect
                        {...field}
                        fullWidth
                        value={diagnosisList.filter(function(option) {
                          return (
                            option.value === getValues("diagnosisCode2")
                          );
                        })}
                        onChange={e => {
                            setValue("diagnosisCode2", e.value);
                            setDiagnosisID(e.value);
                           
                          }}
                          styles={{ control: provided => ({ ...provided, width: '120px' }) }}
                        options={diagnosisList}
                      />
                     
                    }
                  />
        </Grid>

        <Grid item xs={0.5} ></Grid>


        <Grid item xs={1.5}>
          <Typography style={{ fontSize: "16px", textDecoration: "underline", marginLeft: '1.5rem' }}>
            Charge $
          </Typography>
        </Grid>

        <Grid item xs={1}>
          {/* <Controller
            name='charge2'
            margin='dense'
            {...register('charge2')}
            control={control}
            render={({ field }) => (
              <Select
                size='small'
                {...field}
                styles={{ control: provided => ({ ...provided, width: '120px' }) }} // Set width here
                options={[
                  { value: '', label: '' },
                  { value: '$10', label: '$10' }
                ]}
                value={{ label: getValues('charge2') }}
                onChange={value => setValue('charge2', value.label)}
              />
            )}
          /> */}
          <Controller
            name='charge2'
            margin='dense'
            {...register('charge2')}
            control={control}
            render={({ field }) => (
              <TextField
                size='small'
                {...field}
                 style={{width:'120px'}}
                value={chargee2}
               
              />
            )}
          />
        </Grid>




      </Grid>

      <Grid container >

        <Grid item xs={0.5} ></Grid>

        <Grid item xs={2}>
          <Typography style={{ fontSize: "16px", textDecoration: "underline", marginLeft: '1.5rem' }}>
            Procedure code:
          </Typography>
        </Grid>


        <Grid item xs={1}>
          {/* <Controller
            name='procedureCode3'
            margin='dense'
            {...register('procedureCode3')}
            control={control}
            render={({ field }) => (
              <Select
                size='small'
                {...field}
                styles={{ control: provided => ({ ...provided, width: '120px' }) }} // Set width here
                options={[
                  { value: '', label: '' },
                  { value: 'E0486', label: 'E0486' },
                  { value: '99203', label: '99203' },
                  { value: '99213', label: '99213' },
                  { value: '70355', label: '70355' },
                  { value: '99245', label: '99245' },
                  { value: '99215', label: '99215' },
                  { value: '99244', label: '99244' },
                  { value: 'K1207', label: 'K1207' },
                ]}
                value={{ label: getValues('procedureCode3') }}
                onChange={value => setValue('procedureCode3', value.label)}
              />
            )}
          /> */}
           <Controller
                    name="procedureCode3"
                    {...register("procedureCode3")}
                    control={control}
                    render={({ field }) =>
                      <ReactSelect
                        {...field}
                        fullWidth
                        value={procedureCode1List.filter(function(option) {
                          return (
                            option.value === getValues("procedureCode3")
                          );
                        })}
                        onChange={e => {
                            setValue("procedureCode3", e.value);
                            setCharge3(e.qasim)
                            setProcedureCode1ID(e.value);
                           
                          }}
                          styles={{ control: provided => ({ ...provided, width: '120px' }) }}
                        options={procedureCode1List}
                      />
                     
                    }
                  />
        </Grid>

        <Grid item xs={2.5}>
          <Typography style={{ fontSize: "16px", textDecoration: "underline", marginLeft: '70px' }}>
            Diagnosis code:
          </Typography>
        </Grid>


        <Grid item xs={1}>
          {/* <Controller
            name='diagnosisCode3'
            margin='dense'
            {...register('diagnosisCode3')}
            control={control}
            render={({ field }) => (
              <Select
                size='small'
                {...field}
                styles={{ control: provided => ({ ...provided, width: '120px' }) }} // Set width here
                options={[
                  { value: '', label: '' },
                  { value: 'G47.33', label: 'G47.33' },
                  { value: 'F45.8', label: 'F45.8' },
                  { value: 'M26.609', label: 'M26.609' },
                ]}
                value={{ label: getValues('diagnosisCode3') }}
                onChange={value => setValue('diagnosisCode3', value.label)}
              />
            )}
          /> */}
           <Controller
                    name="diagnosisCode3"
                    {...register("diagnosisCode3")}
                    control={control}
                    render={({ field }) =>
                      <ReactSelect
                        {...field}
                        fullWidth
                        value={diagnosisList.filter(function(option) {
                          return (
                            option.value === getValues("diagnosisCode3")
                          );
                        })}
                        onChange={e => {
                            setValue("diagnosisCode3", e.value);
                            setDiagnosisID(e.value);
                           
                          }}
                          styles={{ control: provided => ({ ...provided, width: '120px' }) }}
                        options={diagnosisList}
                      />
                     
                    }
                  />
        </Grid>

        <Grid item xs={0.5} ></Grid>


        <Grid item xs={1.5}>
          <Typography style={{ fontSize: "16px", textDecoration: "underline", marginLeft: '1.5rem' }}>
            Charge $
          </Typography>
        </Grid>

        <Grid item xs={1}>

          {/* <Controller
            name='charge3'
            margin='dense'
            {...register('charge3')}
            control={control}
            render={({ field }) => (
              <Select
                size='small'
                {...field}
                styles={{ control: provided => ({ ...provided, width: '120px' }) }} // Set width here
                options={[
                  { value: '', label: '' },
                  { value: '$10', label: '$10' }
                ]}
                value={{ label: getValues('charge3') }}
                onChange={value => setValue('charge3', value.label)}
              />
            )}
          /> */}
           <Controller
            name='charge3'
            margin='dense'
            {...register('charge3')}
            control={control}
            render={({ field }) => (
              <TextField
                size='small'
                {...field}
                 style={{width:'120px'}}
                value={chargee3}
               
              />
            )}
          />

        </Grid>




      </Grid>
      <Grid item xs={2}></Grid>
      <Grid item xs={4}>
      <Typography>
         Has the patient used CPAP in the past?
        </Typography>

      </Grid>
      <Grid item xs={4} style={{marginTop:'-10px'}}>
      <Controller
         name="cpap"
         {...register("cpap")}
           control={control}
           render={({ field }) => (
           <TextField
            variant="standard"
            size='small'
            type="input"
            {...field}
             fullWidth
             />
            )}
          />


      </Grid>
      <Grid item xs={2}></Grid>
      <Grid item xs={2}></Grid>
      <Grid item xs={5}>
      <Typography>
         Has the patient has an oral appliance in the past?
        </Typography>

      </Grid>
      <Grid item xs={3} style={{marginTop:'-10px'}}>
      <Controller
         name='appliance'
         {...register("appliance")}
           control={control}
           render={({ field }) => (
           <TextField
            variant="standard"
            size='small'
            type="input"
            {...field}
             fullWidth
             />
            )}
          />


      </Grid>
      <Grid item xs={2}></Grid>

      <Grid item xs={2}></Grid>
      <Grid item xs={5}>
        <Typography>
          Would you like to obtain GAP (if available on plan)?
        </Typography>
      </Grid>
         <Grid item xs={3} style={{marginTop:'-10px'}}>
      <Controller
         name='gap'
         {...register("gap")}
           control={control}
           render={({ field }) => (
           <TextField
            variant="standard"
            size='small'
            type="input"
            {...field}
             fullWidth
             />
            )}
          />


      </Grid>
      <Grid item xs={2}></Grid>

      <Grid item xs={2}></Grid>
      <Grid item xs={0.5}>
      <Typography>
          Yes
        </Typography>
      </Grid>
      <Grid item xs={2} style={{marginTop:'-10px'}}>
      <Controller
         name="claimProcesses"
         {...register("claimProcesses")}
           control={control}
           render={({ field }) => (
           <TextField
            variant="standard"
            size='small'
            type="input"
            {...field}
             fullWidth
             />
            )}
          />

      </Grid>
      <Grid item xs={7}>
      <Typography>
        (May only balance bill if carrier allows once claim processes/most plans allow)
        </Typography>
      </Grid>
      <Grid item xs={0.5}></Grid>

      <Grid item xs={2}></Grid>
      <Grid item xs={0.5}>
      <Typography>
          No
        </Typography>
      </Grid>
      <Grid item xs={2} style={{marginTop:'-10px'}}>
      <Controller
         name="balanceBill"
         {...register("balanceBill")}
           control={control}
           render={({ field }) => (
           <TextField
            variant="standard"
            size='small'
            type="input"
            {...field}
             fullWidth
             />
            )}
          />

      </Grid>
      <Grid item xs={7}>
      <Typography>
        (May balance bill)
        </Typography>
      </Grid>
      <Grid item xs={0.5}></Grid>




      {UserType !== 'Front Office' ? (
      <>
    
      <Grid item xs={0.5}></Grid>
      <Grid item xs={11.5}>
        <Typography
          level="body 1"
          style={{
            fontSize: "18px",
            fontWeight: "bold",
            paddingTop: "12px",
          }}
        >
          *Please add any additional info (clinics, letters, questionnaires,s
          etc.) that may be useful for the approval process
        </Typography>
      </Grid>
      <Grid xs={12}>
        <Typography
          level="body 1"
          style={{
            textAlign: "center",
            fontWeight: "bold",
          }}
        >
          Additional notes:ss
        </Typography>
      </Grid>
      <Grid item xs={0.5}></Grid>
      <Grid xs={11.5}>
        <Controller
          name="additionalNotes"
          {...register("additionalNotes")}
          onChange={value => setAddNotes('additionalNotes', value)}
          control={control}
          render={({ field }) => (
            <TextareaAutosize
              aria-Typography="minimum height"
              minRows={2}
              style={{
                width: "100%",
                borderRadius: "8px",
                border: "2px solid #black",
                height: "100px", // set a fixed height here
                overflow: "scroll",
              }}
              size="small"
              fullWidth
              {...field}
              name="notes"
              type="text"
            />
          )}
          fullWidth
        />
      </Grid>
      </>
      ):''}
      <Grid item xs={0.5}></Grid>
    
      <Grid xs={12}>
        <Button
          className="Button"
          onClick={handleSubmit(onSubmit)}
          // onClick={save}
          variant="gradient"
          disabled={disable == true ? true : false}
          style={{
            marginTop: "0px",
            marginBottom: "60px",
            float: "right",
            marginLeft: "10px",
            width: "250px",
            height: "35px",
            backgroundColor: "#1273de",
            color: "white",
            fontSize: "14px",
          }}
        >
          Submit to LuxBilling
        </Button>
      </Grid>
    </Grid>
  );
};

export default VOBPAGE1;
