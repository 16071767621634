const SidebarData = [ 

    {
        label: "Dashboard",
        icon: "mdi mdi-view-dashboard",
        url: "/dashboard",
        issubMenubadge: true,
        bgcolor: "bg-primary",
        // badgeValue: "3"
    },
    {
        label: "Schedular",
        icon: "mdi mdi-calendar",
        url: "/Schedular",
        issubMenubadge: true,
        bgcolor: "bg-primary",
     
    },
    {
        label: "Forms/Templates",
        icon: "mdi mdi-file-document",
        url: "/fromstemp",
        issubMenubadge: true,
        bgcolor: "bg-primary",
        // badgeValue: "3"
    },
   
    {
        label: "Patient",
        icon: "mdi mdi-heart-pulse",
        url: "/Patient",
        issubMenubadge: true,
        bgcolor: "bg-primary",
    },
    {
        label: "Authorization",
        icon: "mdi mdi-lock",
        url: "/Authorization",
        issubMenubadge: true,
        bgcolor: "bg-primary",
    },
    {
        label: "To-Do List",
        icon: "mdi mdi-message-outline",
        url: "/Todolist",
        issubMenubadge: true,
        bgcolor: "bg-primary",
    },
    // {
    //     label: "Email",
    //     icon: "mdi mdi-email",
    //     url: "/Email",
    //     issubMenubadge: true,
    //     bgcolor: "bg-primary",
    // },
    {
        label: "Carriers",
        icon: "mdi mdi-shield-account-outline",
        url: "/Insuranceplan",
        issubMenubadge: true,
        bgcolor: "bg-primary",
    },
    
    {
        label: "Contacts",
        icon: "mdi mdi-contacts",
        url: "/Contants",
        issubMenubadge: true,
        bgcolor: "bg-primary",
    },
  
    {
        label: "Claim",
        icon: "mdi mdi-cash-usd",
        url: "/Claim",
        issubMenubadge: true,
        bgcolor: "bg-primary",
    },
    {
        label: "Setting",
        isMainMenu: true,
    },
   
    {
        label: "User",
        icon: "mdi mdi-account-circle-outline",
        url: "/User",
        issubMenubadge: true,
        bgcolor: "bg-primary",
    },
    {
        label: "Templates",
        icon: "mdi mdi-file-document",
        url: "/Templates",
        issubMenubadge: true,
        bgcolor: "bg-primary",
    },
    {
        label: "Practices",
        icon: "mdi mdi-briefcase-outline",
        url: "/Practices",
        issubMenubadge: true,
        bgcolor: "bg-primary",
    },
    {
        label: "Provider",
        icon: "mdi mdi-briefcase-account-outline",
        url: "/Provider",
        issubMenubadge: true,
        bgcolor: "bg-primary",
    },
   
    {
        label: "Diagnosis",
        icon: "mdi mdi-medical-bag",
        url: "/Diagnosis",
        issubMenubadge: true,
        bgcolor: "bg-primary",
    },
    {
        label: "Procedures",
        icon: "mdi mdi-hospital-building",
        url: "/Procedures",
        issubMenubadge: true,
        bgcolor: "bg-primary",
    },
   
    
    // {
    //     label: "OSAPAGE",
    //     icon: "mdi mdi-home-variant-outline",
    //     url: "/OSAPAGE",
    //     issubMenubadge: true,
    //     bgcolor: "bg-primary",
    // },
    // {
    //     label: "VOBPAGE",
    //     icon: "mdi mdi-home-variant-outline",
    //     url: "/VOBPAGE",
    //     issubMenubadge: true,
    //     bgcolor: "bg-primary",
    // },
    
    
]
export default SidebarData;